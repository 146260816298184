import actionTypes from "../constants";

export const addFilter = data => {
    return {
        type: actionTypes.ADD_FILTER,
        payload: data
    };
};

export const filteredArray = filterByData => {
    return {
        type: actionTypes.FILTER,
        payload: filterByData
    };
};

export const updateFilteredByItemsArray = item => {
    return {
        type: actionTypes.UPDATE_FILTER_ARRAY,
        payload: item
    };
};

export const clearFilterItems = () => {
    return {
        type: actionTypes.CLEAR_FILTER_ITEMS_ARRAY
    };
};
