import actionTypes from "../constants";

export const addNoOfPagesAndItemsPerPage = noOfPages => {
    return {
        type: actionTypes.ADD_NO_OF_PAGES,
        payload: noOfPages
    };
};

export const updateActiveRoute = activeKey => {
    return {
        type: actionTypes.CHANGE_PAGINATION_ROUTE,
        payload: activeKey
    };
};
