import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import StatusBar from "../../components/StatusBar/index";
import MapHistory from "../../components/MapHistory";
import DashboardAsset from "../../components/DashboardAsset";
import "./styles.scss";
import closeIcon from "../../assets/images/close.svg";
import {
    updateFilteredByItemsArray,
    clearFilterItems
} from "../../store/actions/addFilter.action";
import { DASHBOARD_FILTER_CONFIG, PAGE_NAME } from "../../utils/constants";
import DashboardTrainsTable from "../../components/DashboardTrainsTable";
import calculateOverallAssetStatusRequest from "../../store/actions/overallAssetStatus";

const ShowFilter = props => {
    return (
        <div className="active-filters-div">
            {props.showFilterArray &&
                props.showFilterArray.map(item => {
                    return (
                        <div className="chip" key={item.trainName}>
                            <div className="ellipses-div">{item.trainName}</div>
                            <img
                                className="close-image"
                                src={closeIcon}
                                alt="close"
                                onClick={() => {
                                    props.updateArray(item);
                                    props.calculateOverallAssetStatusRequest();
                                }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
ShowFilter.propTypes = {
    showFilterArray: propTypes.array,
    calculateOverallAssetStatusRequest: propTypes.func,
    updateArray: propTypes.func
};
ShowFilter.defaultProps = {
    showFilterArray: [],
    calculateOverallAssetStatusRequest() {},
    updateArray() {}
};

class DashBoard extends PureComponent {
    componentWillUnmount() {}

    clearFilters = e => {
        e.preventDefault();
        this.props.clearFilterItems();
        this.props.calculateOverallAssetStatusRequest();
    };

    render() {
        return (
            <Fragment>
                <div className="dasboard-parent-div">
                    <StatusBar
                        title="Status"
                        showTrainsStatus
                        overallCount={this.props.totalVehicleCount}
                        overallRedCount={this.props.overallRedTrains}
                        overallAmberCount={this.props.overallAmberTrains}
                    />
                    <div className="filter-main-div">
                        <ShowFilter
                            calculateOverallAssetStatusRequest={
                                this.props.calculateOverallAssetStatusRequest
                            }
                            updateArray={this.props.updateFilteredByItemsArray}
                            showFilterArray={this.props.filterByItems}
                        />
                        {this.props.filterByItems &&
                        this.props.filterByItems.length >= 1 ? (
                            <a
                                href=""
                                className="clear-filters-div"
                                onClick={e => {
                                    this.clearFilters(e);
                                }}
                            >
                                clear all filters
                            </a>
                        ) : null}
                    </div>
                    <div className="row dashboard-asset">
                        <div className="col-md-7 col-sm-12">
                            <div className="status-filter-div">
                                <DashboardAsset />
                                <DashboardTrainsTable />
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <MapHistory
                                allVehicle={this.props.vehicles}
                                filterConfig={DASHBOARD_FILTER_CONFIG}
                                appliedFilters={{
                                    vehiclesData: {
                                        value: this.props.filterByItems,
                                        key: "vehicles"
                                    }
                                }}
                                pageName={PAGE_NAME.dashboard}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

DashBoard.propTypes = {
    clearFilterItems: propTypes.func,
    updateFilteredByItemsArray: propTypes.func,
    calculateOverallAssetStatusRequest: propTypes.func,
    filterByItems: propTypes.array,
    totalVehicleCount: propTypes.number,
    overallRedTrains: propTypes.number,
    overallAmberTrains: propTypes.number,
    vehicles: propTypes.array
};
DashBoard.defaultProps = {
    clearFilterItems,
    calculateOverallAssetStatusRequest,
    updateFilteredByItemsArray,
    filterByItems: [],
    totalVehicleCount: 0,
    overallRedTrains: 0,
    overallAmberTrains: 0,
    vehicles: []
};

const mapStateToProps = state => {
    return {
        vehicles: state.vehicleModel.vehicles,
        arrayToFilter: state.filterArray.arrayToFilter,
        filterByItems: state.filterArray.filterByItems,
        totalVehicleCount: state.vehicleModel.totalVehicleCount,
        overallRedTrains: state.vehicleModel.overallTrainStatus.redTrains,
        overallAmberTrains: state.vehicleModel.overallTrainStatus.amberTrains
    };
};

const mapDispatchToProps = dispatch => {
    const parameter = {
        payload: ""
    };
    return {
        updateFilteredByItemsArray: item =>
            dispatch(updateFilteredByItemsArray(item)),
        clearFilterItems: () => dispatch(clearFilterItems()),
        calculateOverallAssetStatusRequest: () =>
            dispatch(calculateOverallAssetStatusRequest(parameter))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DashBoard)
);
