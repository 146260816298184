// eventProgress for events
export const eventProgressStatus = {
    closed: "Closed"
};

// overallStatus values
export const overallStatus = {
    danger: "Danger",
    red: "Red",
    warning: "Amber",
    none: "None"
};

// assetTypes
export const assetTypes = {
    bearing: "Bearing",
    wheel: "Wheel",
    sensor: "Sensor",
    tractionMotor: "Traction Motor",
    gearBox: "Gearbox"
};

// constants to compute warnings at train and carriage level
export const measurementTypes = {
    temperature: "Temperature",
    vibration: "Vibration",
    sensor: "Sensor"
};

// constants to compute events at each level
const eventLevelconstant = {
    trainId: "trainId",
    carriageId: "carriageId",
    wheelsetId: "wheelsetId",
    assetId: "assetId"
};

export default eventLevelconstant;
