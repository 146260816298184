import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import ForwardArrow from "../../../assets/images/forward.svg";
import TrainWagon from "./TrainWagon";
import { saveTrainName } from "../../../store/actions/filterData.action";
import {
    RightArrow,
    LeftArrow
} from "../../../components/TrainTopView/SliderIcon";
import "./style.scss";
import {
    addNoOfPagesAndItemsPerPage,
    updateActiveRoute
} from "../../../store/actions/pagination.action";
import { paginationConstants } from "../../../components/Pagination/paginationUtils";
import Pagination from "../../../components/Pagination";

const sliderConfig = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    arrows: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
};

const FleetTrain = props => {
    const [fleetArray, updateFleetArray] = useState([]);

    useEffect(() => {
        const finalArray = [];
        if (props.noOfPages && props.noOfPages !== 0) {
            const pageFirstKey =
                props.activeKey * props.itemsPerPage - (props.itemsPerPage - 1);
            const pageSecondKey = props.activeKey * props.itemsPerPage;

            for (let i = pageFirstKey; i <= pageSecondKey; i += 1) {
                if (props.trainsArray[i - 1]) {
                    finalArray.push(props.trainsArray[i - 1]);
                }
            }
            updateFleetArray(finalArray);
        }
    }, [props.activeKey, props.trainsArray, props.itemsPerPage]);
    if (props.trainsArray && props.trainsArray.length === 0) {
        return (
            <div className="no-trains-div">
                <div>No trains found matching the given filter criteria</div>
            </div>
        );
    }

    return (
        <div className="fleet-train-parent">
            <div className="top">
                {fleetArray &&
                    fleetArray.map(item => {
                        return (
                            <div>
                                <div key={item.key} className="fleet-train">
                                    <div className="train-details">
                                        <div className="train-name">
                                            {item.Designation}
                                        </div>
                                        <Link
                                            to={`/train/${item.Designation}`}
                                            onClick={() =>
                                                props.saveTrainName(item.label)
                                            }
                                        >
                                            Show Detail
                                            <img
                                                className="icon-forward"
                                                src={ForwardArrow}
                                                alt="forward"
                                            />
                                        </Link>
                                    </div>
                                    <div className="train train-slider-container">
                                        <Slider {...sliderConfig}>
                                            {item.carriages.map(
                                                (wagonItem, index) => {
                                                    return (
                                                        <TrainWagon
                                                            key={index + 1}
                                                            item={wagonItem}
                                                            maxLength={
                                                                item.carriages
                                                                    .length
                                                            }
                                                            link={`/train/${item.Designation}/vehicle/${wagonItem.VehicleNumber}`}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Slider>
                                        <ReactTooltip />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <Pagination
                itemsPerPage={paginationConstants.itemsPerPage}
                totalItems={props.trainsArray.length}
                displayLimit={paginationConstants.displayLimit}
                noOfPages={props.noOfPages}
                activeKey={props.activeKey}
                addNoOfPagesAndItemsPerPage={props.addNoOfPagesAndItemsPerPage}
                updateActiveRoute={props.updateActiveRoute}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        noOfPages: state.pagination.noOfPages,
        activeKey: state.pagination.activeKey,
        itemsPerPage: state.pagination.itemsPerPage
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addNoOfPagesAndItemsPerPage: value => {
            dispatch(addNoOfPagesAndItemsPerPage(value));
        },
        updateActiveRoute: value => dispatch(updateActiveRoute(value)),
        saveTrainName: value => dispatch(saveTrainName(value))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FleetTrain));
