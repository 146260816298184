import { takeEvery, all, call, put } from "redux-saga/effects";
import _, { get } from "lodash";
import { normalize, schema } from "normalizr";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { stopLoading } from "../actions/user.action";
import actionTypes from "../constants";
import {
    getMapDataSuccess,
    getModelledMapData
} from "../actions/getMapData.action";
import { getMapData, getTrendingList, getTrendingListMap } from "../../services/mapService";
import { getPointList } from "../../services/vehicleService";
import mapModel from "../../model/mapModel";
import findMaxDate from "../../utils/findDateAndTime";
import { getLastWeekDateRange } from "../../utils/helper";
import { errorMsg as ERROR_MSG } from "../../utils/constants";

function customGet(ObjectValue, currentTimeStamp) {
    if (
        ObjectValue &&
        ObjectValue.entities &&
        ObjectValue.entities.latLongData &&
        ObjectValue.entities.latLongData[currentTimeStamp]
    ) {
        return _.isNil(ObjectValue.entities.latLongData[currentTimeStamp])
            ? {}
            : ObjectValue.entities.latLongData[currentTimeStamp];
    }
    return {};
}

function findGpsMeasurementObj(gpsMeasurementPointObj, pointId, path) {
    return _.find(gpsMeasurementPointObj, obj => {
        const latLongReadingObj = _.get(obj, path, null);
        if (latLongReadingObj) {
            const timeReading = Object.values(latLongReadingObj);
            if (
                timeReading &&
                timeReading[0] &&
                timeReading[0].PointID === pointId
            ) {
                return true;
            }
        }
        return false;
    });
}

function mapGpsMeasurementPointWithTrain(
    trainGpsMeasurementPointArr,
    trainPointListArr
) {
    return trainPointListArr.map((trainPointListObj, pointListIndex) => {
        const gpsMeasurementPointObj =
            trainGpsMeasurementPointArr[pointListIndex] || {};
        const timeStampArr = _.get(gpsMeasurementPointObj, "0.result", 0);
        const measurementTimeLineObj = timeStampArr.reduce(
            (totalTimeLineReadingObj, currentTimeStamp) => {
                const newTotalTimeLineReadingObj = {
                    ...totalTimeLineReadingObj
                };
                newTotalTimeLineReadingObj[currentTimeStamp] = {
                    lat: {},
                    long: {}
                };
                const latObj = findGpsMeasurementObj(
                    gpsMeasurementPointObj,
                    trainPointListObj.Latitude,
                    "entities.latLongData"
                );
                const lngObj = findGpsMeasurementObj(
                    gpsMeasurementPointObj,
                    trainPointListObj.Longitude,
                    "entities.latLongData"
                );
                /*const dirObj = findGpsMeasurementObj(
                    gpsMeasurementPointObj,
                    trainPointListObj.Direction,
                    "entities.latLongData"
                );*/
                if (latObj) {
                    newTotalTimeLineReadingObj[
                        currentTimeStamp
                    ].lat = customGet(latObj, currentTimeStamp);
                }
                if (lngObj) {
                    newTotalTimeLineReadingObj[
                        currentTimeStamp
                    ].long = customGet(lngObj, currentTimeStamp);
                }
                /*if (dirObj) {
                    newTotalTimeLineReadingObj[
                        currentTimeStamp
                    ].dir = customGet(dirObj, currentTimeStamp);
                }*/
                return newTotalTimeLineReadingObj;
            },
            {}
        );
        return measurementTimeLineObj;
    });
}

const latLongSchema = new schema.Entity(
    "latLongData",
    {},
    {
        idAttribute: "ReadingTimeUTC"
    }
);

export const getNormalisedData = data => {
    return normalize(data, [latLongSchema]);
};

function* nestedCall(ids) {
    const lastWeekDateObj = getLastWeekDateRange();
    const { startDate } = lastWeekDateObj;
    const { endDate } = lastWeekDateObj;
    const data = yield all(
        ids.map(item => {
            return call(getTrendingListMap, item, startDate, endDate);
        })
    );
    return data;
}

function* fetchMapDataAsync() {
    try {
        const assetListData = yield getMapData();

        const filterAccordingToName = assetListData.filter(
            item => item.Name.includes("IMx") === true
        );

        const filterWithTrainId = filterAccordingToName.map(item => {
            const { Description } = item;
            const trainName = Description.substr(1)
                .slice(0, -1)
                .split(":")[1];

            const obj = {};
            obj[trainName] = item;
            return obj;
        });

        const getPointListResponses = yield all(
            filterAccordingToName.map(item => call(getPointList, item.ID))
        );

        let locationIds = {};
        const locationIdsArray = [];
        getPointListResponses.forEach(item => {
            item.response.forEach(innerItem => {
              if (innerItem.Name.includes("Latitude") ||
                innerItem.Name.includes("Longitude")) {
                  const obj = {};
                  obj[innerItem.Name] = innerItem.ID;
                  locationIds = {
                      ...locationIds,
                      ...obj
                  };
                  // Object.assign(locationIds, obj);
              };
            });
            locationIdsArray.push(locationIds);
        });

        const locationData = yield all(
            locationIdsArray.map(item => {
                return nestedCall(Object.values(item));
            })
        );

        const latLongData = locationData.map(locationDataObj => {
            const normalizedLatLongData = locationDataObj.map(item => {
                const getData = getNormalisedData(item);
                return getData;
            });
            return normalizedLatLongData;
        });
        const mapLocationData = mapGpsMeasurementPointWithTrain(
            latLongData,
            locationIdsArray
        );
        yield put(getMapDataSuccess(mapLocationData));

        const maxDate = findMaxDate(mapLocationData);

        const modelMapData = mapModel(
            mapLocationData,
            maxDate,
            filterWithTrainId
        );
        yield put(getModelledMapData(modelMapData));
        yield put(stopLoading());
    } catch (error) {
        console.log(
            "%c error inside fetchMapDataAsync ",
            "background: salmon; color: black",
            error
        );
        toastr.error(ERROR_MSG.unexpectedError);
        yield put(stopLoading());
    }
}

export default [takeEvery(actionTypes.GET_MAP_DATA, fetchMapDataAsync)];
