import alarmStatusCode from "../../constants";

const getOverallTrainStatus = vehicleDetails => {
    let amberTrainsCount = 0;
    let redTrainsCount = 0;
    vehicleDetails.forEach(vehicle => {
        switch (vehicle.overallStatus) {
            case alarmStatusCode.dangerTemperature.color: {
                redTrainsCount += 1;
                break;
            }
            case alarmStatusCode.danger.color: {
                redTrainsCount += 1;
                break;
            }
            case alarmStatusCode.warning.color: {
                amberTrainsCount += 1;
                break;
            }
            default:
                break;
        }
    });

    return {
        amberTrains: amberTrainsCount,
        redTrains: redTrainsCount
    };
};

export default getOverallTrainStatus;
