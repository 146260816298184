import { cloneDeep } from "lodash";
import computeEvents, {
    getAxlePointList
} from "./utils/computeEventsAndPointList";
import eventLevelconstant from "./utils/constants";
import { getOverallStatus } from "./utils/computeStatusCounts";
import { computeCountsAxleBoxes } from "./utils/computeWarningAndCounts";

const modelAxleBoxes = (axleBoxes, events, pointsList) => {
    const modelledAxleBoxes = axleBoxes.map(axleBox => {
        let clonedAxleBox = cloneDeep(axleBox);
        // computing events for axleboxes
        const axleBoxEvents = computeEvents(
            clonedAxleBox.LocationName,
            events,
            eventLevelconstant.assetId
        );
        clonedAxleBox.events = axleBoxEvents;

        // computing pointList for axleBoxes
        const axlePoints = getAxlePointList(clonedAxleBox.Side, pointsList);
        clonedAxleBox.pointList = axlePoints;

        // computing and adding counts for axleBoxes
        clonedAxleBox = computeCountsAxleBoxes(
            axleBoxEvents,
            axlePoints,
            clonedAxleBox
        );

        // updating axleBoxes with overallStatus
        clonedAxleBox.overallStatus = getOverallStatus(axleBoxEvents);

        return clonedAxleBox;
    });
    return modelledAxleBoxes;
};
export default modelAxleBoxes;
