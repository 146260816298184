import { takeLatest, put } from "redux-saga/effects";
import actionTypes from "../constants";
import {
    postFeedback,
    postFeedbackImages
} from "../../services/feedbackService";
import {
    eventFeedbackSuccess,
    eventFeedbackFailure
} from "../actions/modal.action";

function* postFeedbackData(action) {
    const { payload } = action;

    try {
        const { data, images, eventCaseId } = payload;
        const responseData = yield postFeedback(data);
        const form = new FormData();
        const feedbackId = responseData.slice(20);
        form.append("EventCaseId", eventCaseId);
        form.append("FeedBackID", feedbackId);
        for (let i = 0; i < payload.images.length; i += 1) {
            const file = images[i];

            form.append("File", file, file.name);
        }
        const imagesData = yield postFeedbackImages(form);
        // console.log(imagesData, "images data");
        if (imagesData && responseData) {
            yield put(eventFeedbackSuccess());
        }
    } catch (error) {
        yield put(eventFeedbackFailure());
        console.log("error", error);
    }
}

export default [
    takeLatest(actionTypes.SEND_FEEDBACK_DATA_REQUEST, postFeedbackData)
];
