import actionTypes from "../constants";

const initialState = {
    itemsPerPage: 0,
    noOfPages: 0,
    activeKey: 1
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.ADD_NO_OF_PAGES:
            return {
                ...state,
                noOfPages: payload.noOfPages,
                itemsPerPage: payload.itemsPerPage
            };
        case actionTypes.CHANGE_PAGINATION_ROUTE:
            return {
                ...state,
                activeKey: payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
