import React from "react";
import { Button } from "reactstrap";
import classNames from "classnames";
import "./styles.scss";

const ButtonComponent = props => {
    const btnClass = classNames({
        border: props.border,
        "btn-secondary": props.btnSecondary,
        "slight-left": props.slightLeft
    });
    return (
        <div className="button-container">
            <Button
              color={props.color}
              className={btnClass}
              onClick={props.onclick}
            >
                {props.text}
            </Button>
        </div>
    );
};

export default ButtonComponent;
