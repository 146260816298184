import _ from "lodash";
import alarmStatusCode, {
    measurementTypes,
    assetTypes
} from "../utils/constants";
// import pointsListTest from "../utils/fakeData/pointlist";
import getmodeledPoint from "../utils/vehicle/pointsList/model";

const createPointsListModel = (pointsList, vehicles, wheelsets) => {
    const wheelref = _.get(wheelsets, "entities.wheelsets", {});
    const wheelsetArray = Object.values(wheelref);
    const reducedPointList = pointsList.reduce((acc, point) => {

        const modeledEventObj = getmodeledPoint(point, vehicles, wheelsetArray);
        acc.push(modeledEventObj);
        // if (
        //     _.get(modeledEventObj, "metaData.measurementType", "") ===
        //     measurementTypes.sensor
        // ) {
        //     if (point.Status.includes(12)) {
        //         const sensorAlarmObj = _.cloneDeep(modeledEventObj);
        //         sensorAlarmObj.metaData.assetType = assetTypes.sensor;
        //         sensorAlarmObj.metaData.measurementType =
        //             measurementTypes.sensor;
        //         sensorAlarmObj.status = alarmStatusCode.warning.color;
        //         acc.push(sensorAlarmObj);
        //     }
        // }
        return acc;
    }, []);

    let modeledPointsList = _.filter(reducedPointList, event => {
        if (event.metaData.assetType !== alarmStatusCode.none.value) {
            return true;
        }
        return false;
    });

    modeledPointsList = _.filter(modeledPointsList, event => {
        if (event.status !== alarmStatusCode.none.value) {
            return true;
        }
        return false;
    });

    const uniqObj = {};
    const modeledPointsLists = [];
    modeledPointsList.forEach(p => {

        if (p.metaData.assetId &&
            (p.metaData.assetType === assetTypes.sensor.value)
        ) {

            if (!uniqObj[p.metaData.assetId]) {
                uniqObj[p.metaData.assetId] = [p];
                modeledPointsLists.push(p);
                return modeledPointsLists;
            }
            uniqObj[p.metaData.assetId].push(p);

            return false;
        }
        return modeledPointsLists.push(p);
    });

    return modeledPointsLists;
};

export default createPointsListModel;
