import React from "react";
import { connect } from "react-redux";
import "./style.scss";
import RenderRow from "./RenderRow";
import alarmStatusCode from "../../utils/constants";

const ReportRow = props => {
    if (!(props.eventsArray && props.eventsArray.length)) {
        return (
            <tbody>
                <tr>
                    <td>No Data Found</td>
                </tr>
            </tbody>
        );
    }

    return props.eventsArray.map(rowData =>
        rowData.metaData.assetType !== alarmStatusCode.none.value ||
        rowData.metaData.measurementType !== alarmStatusCode.none.value ||
        rowData.metaData.action !== alarmStatusCode.none.value ? (
            <RenderRow
              showFeedback={props.showFeedback}
              rowData={rowData}
              key={rowData.eventCaseId}
              pageFrom={props.pageFrom}
            />
        ) : null
    );
};

export default connect()(ReportRow);
