import React from "react";
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import { RightArrow, LeftArrow } from "./SliderIcon";
import Carriage from "./Carriage";
import "./style.scss";
import { bogieConstants } from "../../utils/constants";

const sliderConfig = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    arrows: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />
};

const getGroups = str => {
    const groups = str.match(/(?:^|[()])[^()]+/g);
    if (!groups) return [];
    let parenLevel = 0;
    return groups.map(v => {
        if (v[0] === "(") {
            parenLevel += 1;
        } else if (v[0] === ")") {
            parenLevel -= 1;
        }
        v = v.replace(/[()]/, "");
        return parenLevel > 0 ? `(${v})` : v;
    });
};

const findBogieStatus = bogie => {
    const obj = {};
    obj.sharingStatus = /[()]/.test(bogie)
        ? `${bogieConstants.bogieSharingStatus}`
        : `${bogieConstants.bogieNonSharingStatus}`;
    obj.poweredStatus = /[a-z]/.test(bogie)
        ? `${bogieConstants.bogiePoweredStatus}`
        : `${bogieConstants.bogieNonPoweredStatus}`;
    return obj;
};

const findBogiePattern = uicCode => {
    let splitBogies = [];
    if (uicCode.includes("(")) {
        splitBogies = getGroups(uicCode);
    } else {
        splitBogies = uicCode.split("'");
    }
    return splitBogies;
};

const TrainTopView = props => {
    const carriageLength = props.visibleTrain.carriages.length;
    return (
        <div>
            <Slider {...sliderConfig}>
                {props.visibleTrain &&
                    props.visibleTrain.carriages.map((item, index) => {
                        const splitBogies = findBogiePattern(item.UIC);

                        const bogieFirstStatus = findBogieStatus(
                            splitBogies[0]
                        );
                        const bogieSecondStatus = findBogieStatus(
                            splitBogies[1]
                        );

                        return (
                            <Carriage
                                bogieFirstStatus={bogieFirstStatus}
                                bogieSecondStatus={bogieSecondStatus}
                                carriageLength={carriageLength}
                                carriageData={item}
                                carriagePosition={index + 1}
                            />
                        );
                    })}
            </Slider>
            <ReactTooltip effect="float" />
        </div>
    );
};

export default TrainTopView;
