import React from "react";
import "./style.scss";
import WheelSet from "../WheelSet";
import { bogieConstants } from "../../../utils/constants";

const NonSharingBogie = props => {
    return (
        <div className="wheelSets-container">
            <WheelSet
              sharing={props.sharing}
              position={bogieConstants.positionFront}
              wheelSetData={props.wheelSetData && props.wheelSetData[0]}
              routeName={props.routeName}
              match={props.match}
              carriageReference={props.carriageReference}
              poweredStatus={props.poweredStatus}
            />
            <WheelSet
              sharing={props.sharing}
              position={bogieConstants.positionBack}
              wheelSetData={props.wheelSetData && props.wheelSetData[1]}
              routeName={props.routeName}
              match={props.match}
              carriageReference={props.carriageReference}
              poweredStatus={props.poweredStatus}
            />
        </div>
    );
};

const SharingBogie = props => {
    return (
        <div className="wheelSets-container">
            <WheelSet
              sharing={props.sharing}
              wheelSetData={props.wheelSetData && props.wheelSetData[0]}
              position={props.position}
              routeName={props.routeName}
              match={props.match}
              carriageReference={props.carriageReference}
              poweredStatus={props.poweredStatus}
            />
        </div>
    );
};

const Bogie = props => {
    return (
        <div>
            {props.bogieStatus.sharingStatus ===
            `${bogieConstants.bogieNonSharingStatus}` ? (
                <div className="carriage-first-bogie-bg-img">
                    <NonSharingBogie
                      sharing=""
                      wheelSetData={props.bogieData && props.bogieData}
                      routeName={props.routeName}
                      match={props.match}
                      carriageReference={props.carriageReference}
                      poweredStatus={props.bogieStatus.poweredStatus}
                    />
                </div>
            ) : (
                <div
                  className={
                        props.bogiePosition ===
                        `${bogieConstants.positionFront}`
                            ? "carriage-first-bogie-bg-img-sharing"
                            : ""
                    }
                >
                    <SharingBogie
                      position={
                            props.bogiePosition ===
                            `${bogieConstants.positionFront}`
                                ? `${bogieConstants.positionBack}`
                                : `${bogieConstants.positionFront}`
                        }
                      sharing={
                            props.bogiePosition ===
                            `${bogieConstants.positionFront}`
                                ? `${bogieConstants.sharingClass}`
                                : ""
                        }
                      wheelSetData={props.bogieData && props.bogieData}
                      routeName={props.routeName}
                      match={props.match}
                      carriageReference={props.carriageReference}
                      poweredStatus={props.bogieStatus.poweredStatus}
                    />
                </div>
            )}
        </div>
    );
};
export default Bogie;
