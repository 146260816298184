const alarmStatusCode = {
    danger: {
        code: "1",
        value: "Act",
        label: "Danger",
        color: "Red",
        colorCase: "red"
    },
    warning: {
        code: "2",
        value: "Plan",
        label: "Warning",
        color: "Amber",
        colorCase: "amber"
    },
    dangerTemperature: { value: "Act now", label: "Danger", color: "Danger" },
    none: { value: "None", label: "None", color: "grey" },
    sensor: { colorCase: "sensor" }
};
export const buttonColorClass = {
    primary: "primary",
    secondary: "secondary",
    danger: "danger"
};

export const measurementTypes = {
    temperature: "Temperature",
    vibration: "Vibration",
    sensor: "Sensor"
};
export const assetTypes = {
    bearing: { value: "Bearing", code: "WS" },
    wheel: { value: "Wheel", code: "WS" },
    sensor: { value: "Sensor", code: "WS" },
    gearbox: { value: "Gearbox", code: "GB" },
    tractionMotor: {
        value: "Traction Motor",
        shortValue: "Traction",
        code: "TN"
    }
};

export const eventProgress = {
    0: "New",
    1: "Open",
    2: "FollowUp",
    3: "Closed"
};

export const errorMsg = {
    unexpectedError:
        "OOPS !!! Something went wrong , Please contact SKF server for more information",
    tokenExpire: `Login Token expired, please Login again.
        If this error persists contact SKF administrator for more information.`,
    documentFetchError: `Cannot fetch documents, please try again.
        If this error persists contact SKF administrator for more information.`,
    downloadError: `Download incomplete, please try again.
        If this error persists contact SKF administrator for more information`
};

export const DASHBOARD_FILTER_CONFIG = {
    fleet: true
};
export const TRAIN_DETAIL_FILTER_CONFIG = {
    fleet: true
};
export const FLEET_FILTER_CONFIG = {
    fleet: true,
    action: true,
    measurement: true
};

export const PAGE_NAME = {
    dashboard: "dashboard",
    fleet: "fleet",
    trainDetails: "trainDetail"
};

export const TRAINS_FLEET_TYPE = {
    electric: {
        label: "Electric",
        value: "electric"
    },
    diesel: {
        label: "Diesel",
        value: "diesel"
    }
};
export const trainModels = {
    model1: "model 1",
    model2: "model 2"
};
export const axleBox = {
    position: {
        leftSide: "LH",
        rightSide: "RH",
        frontLeft: "front-left",
        frontRight: "front-right",
        backLeft: "back-left",
        backRight: "back-right",
        noPosition: "none",
        middleLeft: "middle-left",
        middleRight: "middle-right",
        noClass: "no-class"
    },
    bearingValue: {
        redBearingValue: "bearing-red",
        amberBearingValue: "bearing-amber",
        noBearingValue: "bearing-none"
    },
    wheelValue: {
        amberWheelValue: "wheel-amber",
        redWheelValue: "wheel-red",
        noWheelValue: "wheel-none"
    },
    sensorValue: {
        noSensorValue: "sensor-none",
        sensor: "sensor-amber"
    },
    gearValue: {
        redGearBoxValue: "gear-red",
        amberGearBoxValue: "gear-amber",
        noGearBoxValue: "gear-none",
        absentGearValue: "gear-absent"
    },
    tractionMotorValue: {
        redTractionMotorValue: "traction-red",
        amberTractionMotorValue: "traction-amber",
        noTractionMotorValue: "traction-none",
        absentTractionMotorValue: "traction-absent"
    }
};

export const progressBar = {
    WEAK: "weak",
    FAIR: "fair",
    GOOD: "good",
    STRONG: "strong"
};

export const fleetRoutes = {
    trainRoute: "train",
    vehicleRoute: "vehicle",
    assetRoute: "asset",
    gearBoxRoute: "gearBox",
    tractionMotorRoute: "tractionMotor"
};

export const gradient = [
    "rgba(99, 223, 195, 0)",
    "rgba(95, 216, 195, 1) ",
    "rgba(84, 196, 198, 1)",
    "rgba(80, 190, 199, 1)",
    "rgba(75, 180, 199, 1)",
    "rgba(59, 155, 203, 1)",
    "rgba(21, 41, 136, 1)",
    "rgba(34, 36, 134, 1)",
    "rgba(60, 35, 132, 1)",
    "rgba(71, 36, 130, 1)",
    "rgba(73, 36, 130, 1)"
];

export const propArr = [
    "overallAmberCount",
    "overallRedCount",
    "amberBearingCount",
    "amberWheelCount",
    "redBearingCount",
    "redWheelCount",
    "sensorCount"
];

export const errorTypes = {
    EMAIL_ERROR: "Email is required",
    PWD_ERROR: "Password is required",
    COMMENT_ERROR: "comment is required"
};

export const pwdErrorTypes = {
    CONFIRM_PWD_ERROR: "Passwords do not match"
};

export const assetCardArray = [
    { key: "Train Operator", value: "" },
    { key: "Bogie", value: "bogie" },
    { key: "Wheelset", value: "wheelset" },
    { key: "Wheel diameter", value: "wheelDiameter" },
    { key: "Axlebox ID", value: "axleId" },
    { key: "Brand", value: "brand" },
    { key: "Bearing Serial Number", value: "serialNumber" },
    { key: "Designation", value: "designation" }
];
export default alarmStatusCode;

export const actionStatusDropdown = [
    "Event report acknowledged",
    "Action planned",
    "Action on-going",
    "Action finished"
];

export const actionStatusDropdownProp = {
    actionStatus: "actionStatus"
};
export const feedbackConstants = {
    success: "Successful",
    failure: "There was Some error"
};

export const severityDropdown = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10"
];

export const UPLOAD_IMAGE_LIMIT = 5;

export const bogieConstants = {
    positionFront: "front",
    positionBack: "back",
    sharingClass: "-sharing",
    poweredClass: "-powered",
    nonPoweredClass: "-non-powered",
    bogieSharingStatus: "sharing",
    bogieNonSharingStatus: "nonSharing",
    bogiePoweredStatus: "powered",
    bogieNonPoweredStatus: "nonPowered"
};

export const carriageConstants = {
    carriageFirst: "first-carriage",
    carriageMiddle: "middle-carriage",
    carriageLast: "last-carriage"
};

export const opactityClasses = {
    noClass: "no-class",
    disableClass: "disable",
    lightClass: "-light"
};

export const assetCardConstants = {
    bearingText: "Bearing",
    bearingDangerType: "redBearings",
    bearingWarningType: "amberBearings",
    wheelText: "Wheels",
    wheelDangerType: "redWheels",
    wheelWarningType: "amberWheels",
    tractionText: "Traction Motor",
    tractionDangerType: "redTractionMotors",
    tractionWarningType: "amberTractionMotors",
    gearText: "Gear Box",
    gearDangerType: "redGearBoxes",
    gearWarningType: "amberGearBoxes",
    sensorText: "Sensor",
    sensorType: "sensor"
};
export const tableRowStatusConstants = {
    displayNormal: "display-normal",
    displayDanger: "display-danger",
    displayWarning: "display-warning"
};
export const mainStatusCountText = "Trains";

export const dashboardSortedTable = [
    { dropDownLabel: "Most critical trains", sortCriteria: "action" },
    { dropDownLabel: "Most recent", sortCriteria: "mostRecentEvent" }
];
