import _ from "lodash";
import actionTypes from "../constants";

const initialState = {
    vehicles: [],
    overallAssetStatus: {},
    overallTrainStatus: {},
    totalVehicleCount: 0,
    defectDropDown: []
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.STORE_VEHICLE_MODEL:
            return {
                ...state,
                vehicles: _.cloneDeep(payload)
            };
        case actionTypes.STORE_EVENTS_MODEL:
            return {
                ...state,
                events: _.cloneDeep(payload)
            };
        case actionTypes.STORE_OVERALL_ASSET_STATUS:
            return {
                ...state,
                overallAssetStatus: _.cloneDeep(payload)
            };
        case actionTypes.STORE_OVERALL_TRAIN_STATUS:
            return {
                ...state,
                overallTrainStatus: _.cloneDeep(payload)
            };
        case actionTypes.STORE_TOTAL_VEHICLE_COUNT:
            return {
                ...state,
                totalVehicleCount: _.cloneDeep(payload)
            };
        case actionTypes.NORMALISED_MODELED_EVENTS:
            return {
                ...state,
                modeledEvents: _.cloneDeep(payload)
            };
        case actionTypes.VEHICLE_FAILURE:
            return {
                vehicles: [],
                overallAssetStatus: {},
                overallTrainStatus: {},
                totalVehicleCount: 0
            };
        case actionTypes.DEFECT_DROP_DOWN:
            return {
                ...state,
                defectDropDown: _.cloneDeep(payload)
            };
        case actionTypes.LOGOUT:
            return initialState;

        default:
            return {
                ...state
            };
    }
}
