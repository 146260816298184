import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import Spinner from "../Spinner/index";
import "./styles.scss";

import ReportBreadCrumb from "../ReportContainer/ReportBreadCrumb";
import FeedbackDropdown from "../FeedbackDropdown";
import { actionStatusDropdown, feedbackConstants } from "../../utils/constants";
import error from "../../assets/images/error.png";
import {
    saveComments,
    clearFeedbackModalObj,
    sendFeedBackData,
    showPreviousFeedbacks,
    getPreviousComments,
    showCommentError
} from "../../store/actions/modal.action";
import PreviousFeedbacks from "./PreviousFeedbacks/index";
import CommentsSection from "./CommentsSection/index";

const ModalComponent = props => {
    useEffect(() => {
        props.getPreviousComments(
            props.modalData && props.modalData.eventCaseId
        );
    }, []);
    const closeBtn = (
        <button type="button" className="close" onClick={props.onclick}>
            &times;
        </button>
    );

    const previousFeedbackComments = () => {
        props.showPreviousFeedbacks();
    };

    const handleSave = responseData => {
        if (responseData.addCommentValue === "") {
            props.showCommentError(true);
        } else {
            const obj = {
                Auth: {
                    alias: props.userData.username,
                    auth_token: props.userData.auth_token
                },
                EventCaseId: props.modalData.eventCaseId,
                ModifiedBy: props.userData.username,
                FeedBackComment: props.responseData.addCommentValue,
                ActionStatus: props.responseData.actionStatus,
                Classification:
                    props.responseData.severity === ""
                        ? props.modalData.severity
                        : props.responseData.severity,
                DefectType: props.responseData.defect,
                Position: props.modalData.position
            };

            props.sendFeedBackData(
                JSON.stringify(obj),
                props.imagesArr,
                props.modalData.eventCaseId
            );
        }
    };
    const [disabledButton, disableButton] = useState(false);
    useEffect(() => {
        if (props.feedbackSuccess) {
            props.showCommentError(false);
            props.clearFeedbackModalObj();
            props.onclick();
        }
        if (disabledButton || props.commentError) {
            disableButton(!disabledButton);
        }
    }, [props.feedbackSuccess, props.commentError]);
    const clearFeedbackReducer = () => {
        props.clearFeedbackModalObj();
    };

    return (
        <div className="modal">
            <Modal isOpen={props.isOpen} size="lg" toggle={props.onclick}>
                <ModalHeader
                    toggle={props.onclick}
                    close={closeBtn}
                    className="feedback-modal-header"
                    onClick={() => {
                        clearFeedbackReducer();
                    }}
                >
                    Event Action
                </ModalHeader>
                <ModalBody>
                    <div className="event-status">
                        <div className="event-case">
                            <div className="event-case-key">Event Case:</div>
                            <div className="event-case-value">
                                {props.modalData.eventCaseId}
                            </div>
                        </div>
                        <div className="feedback-breadcrumb">
                            <ReportBreadCrumb
                                pathName={props.modalData.pathName}
                            />
                        </div>
                    </div>
                    <div className="event-info">
                        <div>
                            <div className="heading-div-light">
                                Indicated fault
                            </div>
                            <div className="modified-by">{`${props.modalData.assetType} defect`}</div>
                        </div>
                        <div style={{ paddingLeft: "50px" }}>
                            <div className="heading-div-light">
                                Recommended Action
                            </div>
                            <div className="modified-by">
                                {props.modalData.recommendedAction}
                            </div>
                        </div>
                    </div>
                    <hr className="horizontal-divider" />
                    <div className="action-status-div">
                        <div className="action-status-container">
                            <div className="heading-div">Action status</div>

                            <FeedbackDropdown
                                dropdownType="actionStatus"
                                dropdownItems={actionStatusDropdown}
                                defaultValue={_.get(
                                    props,
                                    "responseData.actionStatus",
                                    "Event Report Acknowledged"
                                )}
                            />
                        </div>
                        <div className="modified-by-container">
                            <div className="heading-div">
                                Action status modified by
                            </div>
                            <div className="modified-by">
                                {props.userData.username}
                            </div>
                        </div>
                    </div>

                    {props.showPreviousFeedback ? (
                        <PreviousFeedbacks />
                    ) : (
                        <CommentsSection />
                    )}

                    <div
                        className="comments-div"
                        onClick={() => previousFeedbackComments()}
                    >
                        {props.showPreviousFeedback ? (
                            <div />
                        ) : (
                            <p className="feedback-modal-footer">
                                Previous Comments
                            </p>
                        )}
                    </div>
                    {props.feedbackFailure ? (
                        <div className="failure-class">
                            <img src={error} alt="error" />
                            <div>{feedbackConstants.failure}</div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <ModalFooter>
                        <div>
                            {props.showPreviousFeedback ? (
                                <Button
                                    className="save-button"
                                    onClick={() => {
                                        previousFeedbackComments();
                                    }}
                                >
                                    Back
                                </Button>
                            ) : (
                                <Button
                                    disabled={disabledButton}
                                    className="save-button"
                                    onClick={() => {
                                        handleSave(
                                            props.responseData
                                            // props.imagesArr
                                        );
                                    }}
                                >
                                    {props.startSpinner ? (
                                        <div>
                                            <Spinner location="button" />
                                        </div>
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button
                                className="cancel-button"
                                onClick={() => {
                                    clearFeedbackReducer();
                                    props.onclick();
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    );
};

ModalComponent.propTypes = {
    modalData: propTypes.object,
    saveComments: propTypes.func,
    userData: propTypes.object,
    isOpen: propTypes.bool
};

ModalComponent.defaultProps = {
    modalData: {},
    saveComments: () => {},
    userData: propTypes.object,
    isOpen: false
};

function mapStateTopProps(state) {
    return {
        modalData: state.feedbackModal.modalData,
        userData: state.user.userData,
        responseData: state.feedbackModal.responseData,
        imagesArr: state.feedbackModal.images,
        defectDropDown: state.vehicleModel.defectDropDown,
        showPreviousFeedback: state.feedbackModal.showPreviousFeedbacks,
        feedbackSuccess: state.feedbackModal.feedbackSuccess,
        feedbackFailure: state.feedbackModal.feedbackFailure,
        startSpinner: state.feedbackModal.startSpinner,
        commentError: state.feedbackModal.showCommentError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveComments: value => dispatch(saveComments(value)),
        clearFeedbackModalObj: () => dispatch(clearFeedbackModalObj()),
        getPreviousComments: eventCaseId =>
            dispatch(getPreviousComments(eventCaseId)),
        sendFeedBackData: (data, images, eventCaseId) =>
            dispatch(sendFeedBackData(data, images, eventCaseId)),
        showPreviousFeedbacks: () => dispatch(showPreviousFeedbacks()),
        showCommentError: data => dispatch(showCommentError(data))
    };
}

export default connect(mapStateTopProps, mapDispatchToProps)(ModalComponent);
