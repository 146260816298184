import { takeLatest, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { callChangePassword } from "../../services/userService";
import { changePasswordSuccess } from "../actions/changePassword.actions";
import actionTypes from "../constants";

function* changePassword(action) {
    try {
        const { payload } = action;
        const { password } = payload;
        const user = localStorage.getItem("username");
        const body = {
            password
        };
        const response = yield callChangePassword(JSON.stringify(body), user);
        if (response) {
            yield put(changePasswordSuccess());
        }
    } catch (e) {
        toastr.error(`${e}, Please try again`);
    }
}
export default [
    takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePassword)
];
