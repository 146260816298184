import React, { useState } from "react";
import { Button, Input, FormGroup, Form, Label } from "reactstrap";
import { connect } from "react-redux";
import EyeOpen from "../../assets/images/icons8-eye-14.png";
import EyeClose from "../../assets/images/icons8-hide-14.png";
import PasswordStrengthIndicator from "./passwordStrengthIndicator/index";
import "./style.scss";
import { confirmPassword } from "../../utils/helper";
import Spinner from "../../components/Spinner/index";
import {
    setConfirmPwd,
    setCurrentPwd,
    setPassword,
    updateConfirmPwdError,
    removeConfrmPwdError,
    updatePassword
} from "../../store/actions/changePassword.actions";

import { pwdErrorTypes } from "../../utils/constants";

const ChangePassword = props => {
    const [currentType, setCurrentType] = useState("password");
    const [confirmType, setConfirmType] = useState("text");
    const [passwordError, setPasswordError] = useState(false);
    const handlePwdChange = e => {
        const { value } = e.target;
        setPasswordError(false);
        props.removeConfrmPwdError();
        props.setPassword(value);
    };

    const handleConfirmPwdChange = e => {
        const { value } = e.target;
        props.removeConfrmPwdError();
        props.setConfirmPwd(value);
    };

    const handleCurrentPwdChange = e => {
        const { value } = e.target;
        props.setCurrentPwd(value);
    };
    const handleClick = () => {
        setCurrentType(currentType === "text" ? "password" : "text");
    };
    const handleConfirmPwdClick = () => {
        setConfirmType(confirmType === "text" ? "password" : "text");
    };

    const handleSubmit = e => {
        props.removeConfrmPwdError();
        e.preventDefault();
        const strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        );
        if (strongRegex.test(props.password)) {
            const status = confirmPassword(props.password, props.confirmPwd);
            if (status === false) {
                props.updateConfirmPwdError(pwdErrorTypes.CONFIRM_PWD_ERROR);
            } else {
                const formData = {
                    currentPwd: props.currentPwd,
                    password: props.password,
                    confirmPwd: props.confirmPwd
                };
                props.updatePassword(formData);
            }
        } else {
            setPasswordError(true);
        }
    };
    return (
        <div>
            <div className="container-change-password">
                <h3 className="change-password-heading">Change Password</h3>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            className="input-form "
                            type="password"
                            placeholder="Current Password"
                            value={props.currentPwd}
                            onChange={handleCurrentPwdChange}
                        />
                        <Label>
                            <Input
                                className="input-form paddingStyle"
                                type={currentType}
                                placeholder="New Password"
                                value={props.password}
                                onChange={handlePwdChange}
                            />
                            <span className="eye-icon" onClick={handleClick}>
                                {currentType === "text" ? (
                                    <img src={EyeOpen} alt="Show" />
                                ) : (
                                    <img src={EyeClose} alt="Hide" />
                                )}
                            </span>
                        </Label>

                        <Label>
                            <Input
                                type={confirmType}
                                className="input-form paddingStyle"
                                placeholder="Confirm Password"
                                value={props.confirmPwd}
                                onChange={handleConfirmPwdChange}
                            />
                            <span
                                className="eye-icon"
                                onClick={handleConfirmPwdClick}
                            >
                                {confirmType === "text" ? (
                                    <img src={EyeOpen} alt="Show" />
                                ) : (
                                    <img src={EyeClose} alt="Hide" />
                                )}
                            </span>
                        </Label>

                        <p className="error-msg">
                            {props.confirmPwdError ? props.confirmPwdError : ""}
                        </p>
                        <p
                            className={
                                passwordError
                                    ? "password-rule-error"
                                    : "password-rule"
                            }
                        >
                            The password must contain at least 8 characters and
                            include a number, a capital Letter and a lowercase
                            letter
                        </p>
                        <PasswordStrengthIndicator password={props.password} />
                    </FormGroup>
                    <Button className="change-password-button">
                        {props.startSpinner ? (
                            <Spinner location="button" />
                        ) : (
                            "Change Password"
                        )}
                    </Button>
                </Form>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        confirmPwdError: state.profile.confirmPwdError,
        password: state.profile.password,
        confirmPwd: state.profile.confirmPwd,
        currentPwd: state.profile.currentPwd,
        startSpinner: state.profile.startSpinner
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeConfrmPwdError: () => dispatch(removeConfrmPwdError()),
        setCurrentPwd: value => dispatch(setCurrentPwd(value)),
        setConfirmPwd: value => dispatch(setConfirmPwd(value)),
        setPassword: value => dispatch(setPassword(value)),
        updateConfirmPwdError: value => dispatch(updateConfirmPwdError(value)),
        updatePassword: value => dispatch(updatePassword(value))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);
