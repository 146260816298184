import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import DashBoard from "./DashBoard";
import LoginPage from "./Login";
import Fleet from "./Fleet";
import Report from "./Report";
import History from "./History";
import VehicleDetail from "./VehicleDetail";
import Layout from "../components/Layout";

import changePassowrd from "./ChangePassword/index";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                localStorage.AUTH_TOKEN ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

const AppRoutes = props => {
    return (
        <Switch>
            <Route path="/" component={LoginPage} exact />
            <Route path="/login" component={LoginPage} exact />
            <Layout>
                <PrivateRoute path="/dashboard" component={DashBoard} exact />
                <PrivateRoute path="/fleet" component={Fleet} exact />
                <PrivateRoute
                    path="/change-password"
                    component={changePassowrd}
                    exact
                />
                <PrivateRoute path="/report" component={Report} exact />
                <PrivateRoute path="/history" component={History} exact />
                <PrivateRoute
                    path="/train/:id/vehicle/:vehicleId"
                    component={VehicleDetail}
                    exact
                />
                <PrivateRoute
                    path="/train/:id/vehicle/:vehicleId/asset/:assetId"
                    component={VehicleDetail}
                    exact
                />
                <PrivateRoute
                    path="/train/:id"
                    component={VehicleDetail}
                    exact
                />
                <PrivateRoute
                    path="/train/:id/vehicle/:vehicleId/gearBox/:gearBoxId"
                    component={VehicleDetail}
                    exact
                />
                <PrivateRoute
                    path="/train/:id/vehicle/:vehicleId/tractionMotor/:tractionMotorId"
                    component={VehicleDetail}
                    exact
                />
            </Layout>
        </Switch>
    );
};
const mapStateToProps = state => {
    return {
        isLoading: state.vehicleData.isLoading
    };
};

export default withRouter(connect(mapStateToProps)(AppRoutes));
