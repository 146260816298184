import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import StatusBar from "../../components/StatusBar";
import ReportContainer from "../../components/ReportContainer";
import Filter from "../../components/FleetFilter";
import "./style.scss";
import getAssetMeasurementFilteredEvents from "../../utils/reports/getFilterItemsEvents";
import trainFilteredFilteredEvents from "../../utils/reports/getTrainFilteredEvents";
import getDateFilteredItems from "../../utils/reports/getDateFilteredItems";
import { setToggleFlag } from "../../store/actions/filterData.action";

const Report = props => {
    const [reportsData, groupReportEvents] = useState([]);

    useEffect(() => {
        props.handleToggle(true);
        const groupedArray = [];
        const array = [];

        const reportEvents = _.get(props, "reportEvents", []);
        reportEvents.forEach(events => {
            Object.values(events).forEach(item => {
                item.forEach(eachObj => array.push(eachObj));
            });
        });
        const firstFilteredItem = trainFilteredFilteredEvents(
            array,
            props.trainFilter
        );
        const filteredItems = getAssetMeasurementFilteredEvents(
            props.actionMeasurementFilters,
            firstFilteredItem
        );
        const dateFilteredItems = getDateFilteredItems(
            filteredItems,
            props.startDate,
            props.endDate
        );
        const reportsObject = _.groupBy(dateFilteredItems, "metaData.assetId");
        groupedArray.push(reportsObject);
        groupReportEvents(groupedArray);
        return () => {
            props.handleToggle(false);
        };
    }, [
        props.reportEvents,
        props.actionMeasurementFilters,
        props.trainFilter,
        props.startDate,
        props.endDate
    ]);

    return (
        <div style={{ backgrounColor: "yellow" }}>
            <StatusBar title="Reports" />
            <div className="report-parent">
                <div className="report-body">
                    <div className="report-filter">
                        <Filter showDatePicker showTrainName={false} />
                    </div>
                    <div className="report-body-container">
                        {reportsData &&
                            reportsData[0] &&
                            Object.values(reportsData[0]).length === 0 && (
                                <div> No Records Found </div>
                            )}
                        {reportsData.map(
                            (reportsTableData, reportsTableDataIndex) => (
                                <ReportContainer
                                  showFeedback
                                  reportsTableData={reportsTableData}
                                  index={reportsTableDataIndex}
                                  pageFrom="report"
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        toggleFlag: state.fleetData.toggleFlag,
        reportEvents: state.vehicleModel.modeledEvents,
        actionMeasurementFilters: state.fleetData.filterData,
        trainFilter: state.fleetData.vehiclesData,
        endDate: state.fleetData.timeFilterData.endDate,
        startDate: state.fleetData.timeFilterData.startDate
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleToggle: toggleSwitch => {
            dispatch(setToggleFlag(toggleSwitch));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);
