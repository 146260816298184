import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AssetCard from "../AssetCard";
import computeAssetCardData from "./computeAsssetCardData";
import "./style.scss";

class DashboardAsset extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { assetArray: [], overallPropsStatus: {} };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.overallPropsStatus !== nextProps) {
            const assetArray = computeAssetCardData(
                nextProps.bearingStatus,
                nextProps.wheelStatus,
                nextProps.tractionMotorStatus,
                nextProps.gearboxStatus,
                nextProps.overallAssetStatus
            );
            return {
                assetArray,
                overallPropsStatus: nextProps
            };
        }
        return null;
    }

    render() {
        return (
            <div className="asset-container">
                <div className="col">
                    <div className="asset-container-heading">Assets</div>
                    <div className="asset-container-cards">
                        {this.state.assetArray &&
                            this.state.assetArray.map((item, itemindex) => {
                                return (
                                    <AssetCard
                                        text={item.text}
                                        danger={item.dangerCount}
                                        warnings={item.warningCount}
                                        sensors={'' + item.sensorCount}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        overallAssetStatus: state.vehicleModel.overallAssetStatus,
        bearingStatus: state.vehicleModel.overallAssetStatus.bearingsStatus,
        wheelStatus: state.vehicleModel.overallAssetStatus.wheelsStatus,
        gearboxStatus: state.vehicleModel.overallAssetStatus.gearboxStatus,
        tractionMotorStatus: state.vehicleModel.overallAssetStatus.tractionMotorStatus
    };
};

export default connect(mapStateToProps)(DashboardAsset);
