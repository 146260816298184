import React, { Component } from "react";
import zxcvbn from "zxcvbn";
import { progressBar } from "../../../utils/constants";
import "./style.scss";

class PasswordStrengthIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zxcvbnValue: zxcvbn(""),
            zxcvbnScore: 0
        };
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.password !== prevState.password) {
            const reszxcvbn = zxcvbn(nextProps.password);
            if (reszxcvbn.score < 2) {
                return {
                    password: nextProps.password,
                    zxcvbnValue: zxcvbn(nextProps.password),
                    zxcvbnScore: zxcvbn(nextProps.password).score
                };
            }
            const strongRegex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
            );
            if (strongRegex.test(nextProps.password)) {
                return {
                    zxcvbnValue: reszxcvbn,
                    zxcvbnScore: 4
                };
            }
            return {
                zxcvbnValue: reszxcvbn,
                zxcvbnScore: 2
            };
        }
        return null;
    };

    createPasswordLabel = () => {
        switch (this.state.zxcvbnScore) {
            case 0:
                return progressBar.WEAK;
            case 1:
                return progressBar.WEAK;
            case 2:
                return progressBar.FAIR;
            case 3:
                return progressBar.GOOD;
            case 4:
                return progressBar.STRONG;
            default:
                return progressBar.WEAK;
        }
    };

    render() {
        return (
            <div className="password-strength-meter">
                <h5>Password Strength</h5>

                <progress
                  className={`password-strength-meter-progress strength-${this.createPasswordLabel()}`}
                  value={this.state.zxcvbnScore}
                  max="4"
                />
                <label className="password-strength-meter-label">
                    {this.props.password && (
                        <>{this.createPasswordLabel(this.state.zxcvbnScore)}</>
                    )}
                </label>
            </div>
        );
    }
}

export default PasswordStrengthIndicator;
