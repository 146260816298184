import actionTypes from "../constants";

const initialState = {
    historyUnitData: [],
    historyTableData: [],
    historyVehicleFilterTree: []
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.HISTORY_UNIT_DATA:
            return {
                ...state,
                historyUnitData: payload
            };

        case actionTypes.HISTORY_TABLE_DATA:
            return {
                ...state,
                historyTableData: payload
            };
        case actionTypes.HISTORY_VEHICLE_FILTER_TREE:
            return {
                ...state,
                historyVehicleFilterTree: payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
