import _ from "lodash";
import alarmStatusCode from "./constants";

const findFunctionalLocationStatus = (pathName, assetId, vehicles) => {
    const array = pathName.split("/");

    const train = _.find(vehicles || [], { Designation: array[0] }) || {
        carriages: []
    };
    const carriage = _.find(train.carriages, { VehicleNumber: array[1] }) || {
        Wheelsets: []
    };

    const wheelSet = _.find(carriage.wheelSets, { Name: array[2] }) || {
        axleBoxes: []
    };

    const asset = _.find(wheelSet.axleBoxes, { LocationName: assetId }) || [];

    const gearBox = _.get(wheelSet, "gearBox", []);

    const tractionMotor = _.get(wheelSet, "tractionMotor", []);
    let status;

    if (_.isEmpty(asset)) {
        if (
            gearBox.overallStatus === "Red" ||
            tractionMotor.overallStatus === "Red"
        ) {
            status = "Act";
        } else if (
            gearBox.overallStatus === "Amber" ||
            tractionMotor.overallStatus === "Amber"
        ) {
            status = "Plan";
        } else {
            status = "None";
        }

        return status;
    }

    status =
        asset.overallStatus === alarmStatusCode.dangerTemperature.color
            ? alarmStatusCode.dangerTemperature.value
            : asset.overallStatus === alarmStatusCode.danger.color
            ? alarmStatusCode.danger.value
            : asset.overallStatus === alarmStatusCode.warning.color
            ? alarmStatusCode.warning.value
            : alarmStatusCode.none.value;
    return status;
};

export default findFunctionalLocationStatus;
