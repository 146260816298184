import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { get } from "lodash";
import rightArrow from "../../assets/images/rightArrow.svg";
import dangerIcon from "../../assets/images/redDanger.svg";
import warningIcon from "../../assets/images/yellowWarning.svg";
import NumberCount from "../NumberCount/index";
import "./style.scss";
import computeRouteName from "../../utils/fleet/routeName";
import { computeSupText } from "../../utils/fleet/compute";
import alarmStatusCode, { mainStatusCountText } from "../../utils/constants";

export const StatusCount = props => {
    return (
        <div className="status-count-container">
            <h1 className="heading-status-numbers">
                {props.sup && props.icon ? (
                    <div className="number-count-div">
                        <NumberCount
                            number={props.total}
                            sup
                            supText={props.sup}
                            icon={props.icon}
                            textToShow={props.text}
                        />
                        <p className="type-icon">{props.text}</p>
                    </div>
                ) : (
                    <div className="number-count-div">
                        <NumberCount number={props.total} default />
                        <p className="type">{props.text}</p>
                    </div>
                )}
            </h1>
        </div>
    );
};

class StatusBar extends PureComponent {
    render() {
        const route = computeRouteName(this.props.match);
        const supText = computeSupText(route);

        return (
            <div className="dashboard-parent-container">
                <div className="status-container">
                    <div className="status">{this.props.title}</div>

                    {this.props.showTrainsStatus ? (
                        <div className="trains-container">
                            <div className="trains-container-total-trains">
                                <StatusCount
                                    total={this.props.overallCount}
                                    text={mainStatusCountText}
                                />
                                <div className="right-arrow">
                                    <p>
                                        <img
                                            src={rightArrow}
                                            alt="right-arrow"
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="trains-container-total-danger">
                                <StatusCount
                                    total={this.props.overallRedCount}
                                    text={alarmStatusCode.danger.value}
                                    sup={supText}
                                    icon={dangerIcon}
                                />
                            </div>

                            <div className="trains-container-total-warning">
                                <StatusCount
                                    total={this.props.overallAmberCount}
                                    text={alarmStatusCode.warning.value}
                                    sup={supText}
                                    icon={warningIcon}
                                />
                            </div>
                        </div>
                    ) : null}

                    <div className="demo">
                        <p>{localStorage.getItem("username")}</p>
                    </div>
                </div>
            </div>
        );
    }
}

StatusBar.defaultProps = {
    overallCount: 0,
    overallRedCount: 0,
    showTrainsStatus: false,
    title: "",
    overallAmberCount: 0
};

StatusBar.propTypes = {
    overallCount: propTypes.number,
    overallRedCount: propTypes.number,
    showTrainsStatus: propTypes.bool,
    overallAmberCount: propTypes.number,
    title: propTypes.string
};
StatusCount.propTypes = {
    total: propTypes.number,
    text: propTypes.string,
    sup: propTypes.string
};

StatusCount.defaultProps = {
    total: 0,
    text: "",
    sup: ""
};

const mapStateToProps = state => {
    return {
        vehicleCount: get(state, "vehicleData.vehicleCount", 0)
    };
};

export default connect(mapStateToProps)(withRouter(StatusBar));
