import _ from "lodash";
import actionTypes from "../constants";

const initialState = {
    arrayToFilter: [],
    filterByItems: []
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.ADD_FILTER:
            return {
                ...state,
                arrayToFilter: _.cloneDeep(payload)
            };

        case actionTypes.FILTER:
            let array = [];
            if (state.filterByItems.indexOf(payload) === -1) {
                array = state.filterByItems.concat(payload);
            } else {
                array = state.filterByItems;
            }

            return {
                ...state,
                filterByItems: array
            };

        case actionTypes.UPDATE_FILTER_ARRAY:
            const array1 = _.clone(state.filterByItems);
            const removed = _.remove(array1, function(n) {
                return n.trainName === payload.trainName;
            });
            return {
                ...state,
                filterByItems: array1
            };

        case actionTypes.CLEAR_FILTER_ITEMS_ARRAY:
            return {
                ...state,
                filterByItems: []
            };
        case actionTypes.LOGOUT:
            return initialState;

        default:
            return {
                ...state
            };
    }
}
