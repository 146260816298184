import _ from "lodash";

const getModeledNormalisedEvents = events => {
    const modeledEventsArray = [];
    const trainIdEvents = _.groupBy(events, "metaData.trainId");
    const trainKeys = Object.keys(trainIdEvents);
    trainKeys.forEach(key => {
        const eventObj = {};
        eventObj[key] = trainIdEvents[key];
        modeledEventsArray.push(eventObj);
    });

    return modeledEventsArray;
};

export default getModeledNormalisedEvents;
