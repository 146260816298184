import { put, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import {
    requestUserSuccess,
    requestUserError,
    stopLoading
} from "../actions/user.action";
import actionTypes from "../constants";
import postUser from "../../services/userService";

function* fetchUserAsync(action) {
    try {
        const { payload } = action;

        const formData = Object.keys(payload)
            .map(key => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                    payload[key]
                )}`;
            })
            .join("&");

        let data = yield postUser(formData);
        if (data.message) {
            yield put(stopLoading());
            yield put(push("/login"));
        }
        data = { ...data, username: payload.username };
        localStorage.setItem("AUTH_TOKEN", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("username", payload.username);

        yield put(requestUserSuccess(data));
        yield put(push("/dashboard"));
    } catch (error) {
        yield put(requestUserError());
        toastr.error(`Invalid Credentials`);
    }
}

export default [takeLatest(actionTypes.USER_REQUEST, fetchUserAsync)];
