import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import findAssetOverlayClass from "../../../../utils/trainTopView/findAssetOverlayClass";
import { fleetRoutes, axleBox } from "../../../../utils/constants";

const GearBox = props => {
    const [gearLink, updateGearLink] = useState("");
    const [gearOverLay, updateGearOverLay] = useState(`${axleBox.noClass}`);
    const [dataTip, updateDataTip] = useState("");

    useEffect(() => {
        if (props.gearData !== undefined) {
            let newGearLink = props.gearData.LocationName;
            if (newGearLink) {
                updateDataTip(newGearLink);
                newGearLink = newGearLink.split(" ").join("");
                updateGearLink(newGearLink);
            }

            if (
                props.match.params.assetId ||
                props.match.params.gearBoxId ||
                props.match.params.tractionMotorId
            ) {
                const newGearOverLay = findAssetOverlayClass(
                    props.routeName,
                    props.gearData.LocationName &&
                        props.gearData.LocationName.split(" ").join(""),
                    props.match.params.gearBoxId,
                    `${fleetRoutes.gearBoxRoute}`
                );
                updateGearOverLay(newGearOverLay);
            }
        }
    }, [
        props.gearData,
        props.match.params.assetId,
        props.match.params.gearBoxId,
        props.match.params.tractionMotorId,
        props.routeName
    ]);

    return (
        <Link
          onClick={event => {
                if (!gearLink) {
                    event.preventDefault();
                }
            }}
          to={`/train/${props.match.params.id}/vehicle/${props.carriageReference}/gearBox/${gearLink}`}
          data-tip={dataTip}
        >
            <div className={`${props.position} ${gearOverLay}`}>
                <div className={props.gearValue} />
            </div>
        </Link>
    );
};
export default GearBox;
