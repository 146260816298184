import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./style.scss";
import {
    paginationConstants,
    computeInitialDisplayArray,
    computeDisplayArray
} from "./paginationUtils";

const PaginationComponent = props => {
    const [activeArray, updateActiveArray] = useState([]);
    useEffect(() => {
        let noOfPages = 0;
        if (props.itemsPerPage !== 0) {
            noOfPages = props.totalItems / props.itemsPerPage;
        }
        noOfPages = Math.ceil(noOfPages);
        props.addNoOfPagesAndItemsPerPage({
            noOfPages,
            itemsPerPage: props.itemsPerPage
        });
        const displayArray = computeInitialDisplayArray(
            props.displayLimit,
            noOfPages
        );
        updateActiveArray(displayArray);
        return () => {
            noOfPages = 0;
            const itemsPerPage = 0;
            props.addNoOfPagesAndItemsPerPage({
                noOfPages,
                itemsPerPage
            });
            const activeKey = 1;
            props.updateActiveRoute(activeKey);
        };
    }, [props.noOfPages, props.totalItems]);

    const onActiveKeyChange = activeKey => {
        props.updateActiveRoute(activeKey);
        const displayArray = computeDisplayArray(
            activeKey,
            props.displayLimit,
            props.noOfPages
        );

        updateActiveArray(displayArray);
    };

    const onClickArrow = arrow => {
        let newActiveKey = 0;

        if (
            (arrow === paginationConstants.paginationLeft &&
                props.activeKey - 1 !== 0) ||
            (arrow === paginationConstants.paginationRight &&
                props.activeKey < props.noOfPages)
        ) {
            newActiveKey =
                props.activeKey +
                (arrow === paginationConstants.paginationLeft ? -1 : +1);

            onActiveKeyChange(newActiveKey);
        }
    };

    const isDisabled = item => props.activeKey === item || item === "...";
    const isLeftLimit = () => props.activeKey - 1 === 0;
    const isRightLimit = () => props.activeKey === props.noOfPages;
    return (
        <div className="pagination">
            <button
                type="button"
                onClick={() => onClickArrow(paginationConstants.paginationLeft)}
                disabled={isLeftLimit()}
            >
                &laquo;
            </button>
            {activeArray.map((item, index) => {
                return (
                    <button
                        type="button"
                        key={index}
                        disabled={isDisabled(item)}
                        className={
                            item === props.activeKey
                                ? paginationConstants.paginationActive
                                : ""
                        }
                        onClick={() => onActiveKeyChange(item)}
                    >
                        {item}
                    </button>
                );
            })}
            <button
                type="button"
                onClick={() =>
                    onClickArrow(paginationConstants.paginationRight)
                }
                disabled={isRightLimit()}
            >
                &raquo;
            </button>
        </div>
    );
};

PaginationComponent.propTypes = {
    itemsPerPage: propTypes.number,
    totalItems: propTypes.number,
    displayLimit: propTypes.number,
    noOfPages: propTypes.number,
    activeKey: propTypes.number,
    addNoOfPagesAndItemsPerPage: propTypes.func,
    updateActiveRoute: propTypes.func
};
PaginationComponent.defaultProps = {
    itemsPerPage: 0,
    totalItems: 0,
    displayLimit: 0,
    noOfPages: 0,
    activeKey: 0,
    addNoOfPagesAndItemsPerPage() {},
    updateActiveRoute() {}
};

export default PaginationComponent;
