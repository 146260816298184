import * as ApiService from "./apiService";

export const fetchCategoryListDropdown = () => {
    const apiObject = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiFleetUrl + "/api/IMx/DocCategories"
    };
    return ApiService.callApi(apiObject);
};

export const fetchDocuments = trainId => {
    const apiObject = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiFleetUrl + `/api/IMx/TrainDigitalFiles/TrainId/${trainId}`
    };
    return ApiService.callApi(apiObject);
};

export const downloadCategoryFile = (trainId, file) => {
    const apiObject = {
        method: "GET",
        authentication: true,
        file,
        endPoint: ApiService.apiFleetUrl + `/api/IMx/TrainFile/TrainId/${trainId}/fileName/${file}`
    };
    return ApiService.callApi(apiObject);
};
