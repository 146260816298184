import actionTypes from "../constants";

const initialState = { data: [], isLoading: false };

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_HISTORY_MODEL:
            return {
                ...state,
                data: [...action.payload.data],
                isLoading: false
            };
        case actionTypes.FETCH_HISTORY_MODEL:
            return { ...state, isLoading: true };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default historyReducer;
