const find = dateArray => {
    let maxDtObj = new Date(dateArray[0]);
    let maxDt = dateArray[0];
    dateArray.forEach(dt => {
        if (new Date(dt) > maxDtObj) {
            maxDt = dt;
            maxDtObj = new Date(dt);
        }
    });
    return maxDt;
};

const findMaxDate = trainArray => {
    const maximumDate = trainArray.map(item => {
        const dateArray = Object.keys(item);
        const maxDate = find(dateArray);
        return maxDate;
    });

    return maximumDate;
};
export const findDate = date => {
    let newDate = new Date(date);
    newDate = newDate.toDateString().slice(4);
    return newDate;
};
export default findMaxDate;
