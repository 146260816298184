import React, { Component } from "react";

import {
    Navbar,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import notificationBell from "../../assets/images/notificationBell.png";
import "./style.scss";
import headerLinks from "./headerLink";
import toggle from "../../assets/images/toggle.svg";
import logo from "../../assets/images/logo.png";
import settingsIcon from "../../assets/images/settingsIcon.svg";
import NavItem from "./NavItem";
import showDropDownProfile from "../../store/actions/dropDownProfile";
import { requestVehicleDataFailure } from "../../store/actions/vehicleData.action";
import { requestUserError } from "../../store/actions/user.action";
import { requestVehicleFailure } from "../../store/actions/vehicle.action";
import { eventsFailure } from "../../store/actions/events.action";
import { getMapDataFailure } from "../../store/actions/getMapData.action";
import startLogout from "../../store/actions/logout.action";
import ChangePassword from "../../routes/ChangePassword/index";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavVisible: false
        };
    }

    toggle = () => {
        this.props.dispatch(showDropDownProfile());
    };

    handleLogout = () => {
        this.props.dispatch(startLogout());
        this.props.dispatch(showDropDownProfile());
    };

    handleChangePasswordClick = () => {
        this.props.history.push("/change-password");
    };

    render() {
        const { headerLink } = this.props;
        const headerInfo = Object.values(headerLink);
        return (
            <div className="header-div">
                <Navbar className="header">
                    <div className="header-links">
                        <div className="header-sections">
                            <Link to="/dashboard">
                                <img
                                  src={logo}
                                  alt="logo here"
                                  className="logo-class"
                                />
                            </Link>

                            <p className="heading-text">IMx Rail</p>
                            <div className="vertical-line" />
                        </div>

                        <div className="list-of-links">
                            <NavItem
                              headerInfo={headerInfo}
                              location={this.props.location}
                            />
                        </div>

                        <div className="togglebutton">
                            <button
                              type="button"
                              className="mobile-button"
                              onClick={() =>
                                    this.setState({
                                        isNavVisible: !this.state.isNavVisible
                                    })
                                }
                            >
                                <img
                                  src={toggle}
                                  alt="ToggleMenu"
                                  className="toggle-image"
                                />
                            </button>
                        </div>
                    </div>

                    <div className="profile-links">
                        <Dropdown
                          isOpen={this.props.dropdownOpenProfile}
                          toggle={this.toggle}
                        >
                            <DropdownToggle nav>
                                <img
                                  src={settingsIcon}
                                  className="profile-image"
                                  alt="pic"
                                />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem
                                  onClick={this.handleChangePasswordClick}
                                >
                                    Change password
                                </DropdownItem>
                                <DropdownItem
                                  className="seperate-background-color-last"
                                  onClick={this.handleLogout}
                                >
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Navbar>
                {this.state.isNavVisible && (
                    <div className="mobile-nav">
                        <NavItem
                          headerInfo={headerInfo}
                          location={this.props.location}
                        />
                    </div>
                )}
            </div>
        );
    }
}

Header.defaultProps = {
    headerLink: headerLinks
};

const mapStateToProps = state => {
    return {
        dropdownOpenProfile: state.showDropDownProfile.showDropDownProfile
        // settingsDropDown: state.showDropDownProfile.settingsDropDown
        // showDropDownProfile
    };
};

export default withRouter(connect(mapStateToProps)(Header));
