import React from "react";
import RenderHistoryRow from "./RenderHistoryRow";

const HistoryRow = props => {
    return props.carriageData.map((carriage, index) => {
        return (
            <RenderHistoryRow
                VehicleNumber={carriage.VehicleNumber}
                overallStatus={carriage.overallStatus}
                functionalLocations={carriage.functionalLocations}
                onLocationClick={props.onLocationClick}
                parentIndex={index}
                selected={props.selected}
            />
        );
    });
};

export default HistoryRow;
