import computeClass from "./computeClassForCarriageOverlay";
import { fleetRoutes, opactityClasses } from "../constants";

export const findOverlayClass = (linkId, route, wagonVehicleId) => {
    return route === fleetRoutes.assetRoute ||
        route === fleetRoutes.gearBoxRoute ||
        route === fleetRoutes.tractionMotorRoute
        ? opactityClasses.noClass
        : computeClass(linkId, route, wagonVehicleId);
};

export const findWheelSetOverLay = route => {
    return route === fleetRoutes.assetRoute ||
        route === fleetRoutes.tractionMotorRoute ||
        route === fleetRoutes.gearBoxRoute
        ? opactityClasses.lightClass
        : "";
};
