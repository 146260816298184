import React, { Component } from "react";
import _ from "lodash";
import "./style.scss";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import Dropdown from "../../../assets/images/arrowDropDown.svg";
import { updateFleetTrains } from "../../../store/actions/filterData.action";

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src={Dropdown} alt="dropDownIndicator" />
        </components.DropdownIndicator>
    );
};
class FilterItems extends Component {
    // colourStyles = {
    //     control: styles => ({
    //         ...styles,
    //         backgroundColor: "inherit",
    //         borderTop: 0,
    //         borderLeft: 0,
    //         borderRight: 0,
    //         borderColor: "#313A42",
    //         borderRadius: 0
    //     }),
    //     indicatorSeparator: styles => ({
    //         ...styles,
    //         backgroundColor: "inherit"
    //     })
    // };

    // screenReaderStatus = (arg, ...args) => {
    //     return undefined;
    // };

    handleChange = selectedOption => {
        let filterApplied = selectedOption;
        let len = 0;
        if (selectedOption === null) {
            len = 0;
        } else {
            len = selectedOption.length;
        }
        if (selectedOption === null || len === 0) {
            const filterData = {
                key: this.props.item.filterName,
                value: null
            };
            this.props.onDropdownValueChange(filterData);
            this.props.dispatch(updateFleetTrains(filterData));

            return;
        }
        // if (selectedOption[len - 1].value === "All") {
        //     const obj = selectedOption[len - 1];
        //     filterApplied = [];
        //     filterApplied.push(obj);
        // } else if (len >= 2 && selectedOption[len - 2].value === "All") {
        //     filterApplied.shift();
        const isAllOptionSelected = _.find(selectedOption, option => {
            if (option.value === "All") {
                return true;
            }
            return false;
        });

        if (isAllOptionSelected) {
            filterApplied = [isAllOptionSelected];
        }

        const filterData = {
            key: this.props.item.filterName,
            value: filterApplied
        };
        this.props.onDropdownValueChange(filterData);
        this.props.dispatch(updateFleetTrains(filterData));
    };

    render() {
        return (
            <div className="select-container">
                <p className="filter-name">{this.props.item.name}</p>
                <Select
                  styles={{
                        control: styles => ({
                            ...styles,
                            backgroundColor: "inherit",
                            borderTop: 0,
                            borderLeft: 0,
                            borderRight: 0,
                            borderColor: "#313A42",
                            borderRadius: 0
                        }),
                        indicatorSeparator: styles => ({
                            ...styles,
                            backgroundColor: "inherit"
                        })
                    }}
                  components={{ DropdownIndicator }}
                  value={this.props.initialFilters[this.props.item.filterName].value}
                  onChange={this.handleChange}
                  options={this.props.item.dropDownOptions}
                  isMulti
                  blurInputOnSelect={false}
                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        initialFilters: state.fleetData.filterData
    };
}
export default connect(mapStateToProps)(FilterItems);
