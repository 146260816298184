import React, { useState, useEffect } from "react";
import "./style.scss";
import AxleBox from "./AxleBox";
import GearBox from "./GearBox";
import TractionMotor from "./TractionMotor";
import {
    calculateGear,
    calculateTraction
} from "../../../utils/trainTopView/gearBoxTractionMotorCount";
import { findWheelSetOverLay } from "../../../utils/trainTopView/findOverlayClass";
import { bogieConstants } from "../../../utils/constants";

const WheelSet = props => {
    const [gearValue, updateGearValue] = useState("");
    const [tractionValue, updateTractionValue] = useState("");
    const [wheelSetOverLay, updateWheelSetOverLay] = useState("");
    const [wheelSetPoweredStatus, updateWheelSetPoweredStatus] = useState("");

    useEffect(() => {
        const newGearValue = calculateGear(
            props.wheelSetData && props.wheelSetData.gearBox,
            props.routeName,
            props.poweredStatus
        );
        updateGearValue(newGearValue);

        const newTractionValue = calculateTraction(
            props.wheelSetData && props.wheelSetData.tractionMotor,
            props.routeName,
            props.poweredStatus
        );
        updateTractionValue(newTractionValue);

        const newWheelSetOverLay = findWheelSetOverLay(props.routeName);
        updateWheelSetOverLay(newWheelSetOverLay);

        let newWheelSetPoweredStatus = "";
        if (props.poweredStatus === `${bogieConstants.bogiePoweredStatus}`) {
            newWheelSetPoweredStatus = `${bogieConstants.poweredClass}`;
        } else if (
            props.poweredStatus === `${bogieConstants.bogieNonPoweredStatus}`
        ) {
            newWheelSetPoweredStatus = `${bogieConstants.nonPoweredClass}`;
        }
        updateWheelSetPoweredStatus(newWheelSetPoweredStatus);
    });

    return (
        <div
            className={`carriage-first-bogie-${props.position}-wheelSet${wheelSetPoweredStatus}${props.sharing}${wheelSetOverLay}`}
        >
            <AxleBox
                position={`${props.position}-left`}
                data={props.wheelSetData && props.wheelSetData.axleBoxes[1]}
                routeName={props.routeName}
                match={props.match}
                carriageReference={props.carriageReference}
            />
            <GearBox
                match={props.match}
                carriageReference={props.carriageReference}
                gearValue={gearValue}
                position={props.position}
                routeName={props.routeName}
                gearData={props.wheelSetData && props.wheelSetData.gearBox}
            />
            <TractionMotor
                match={props.match}
                carriageReference={props.carriageReference}
                tractionValue={tractionValue}
                position={props.position}
                routeName={props.routeName}
                tractionMotorData={
                    props.wheelSetData && props.wheelSetData.tractionMotor
                }
            />
            <AxleBox
                position={`${props.position}-right`}
                data={props.wheelSetData && props.wheelSetData.axleBoxes[0]}
                routeName={props.routeName}
                match={props.match}
                carriageReference={props.carriageReference}
            />
        </div>
    );
};
export default WheelSet;
