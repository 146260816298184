import actionTypes from "../constants";

export const saveFilterData = filterData => {
    return {
        type: actionTypes.SAVE_FILTER_DATA,
        payload: filterData
    };
};
export const resetFilterData = filterData => {
    return {
        type: actionTypes.RESET_SAVE_FILTER_DATA,
        payload: filterData
    };
};

export const setToggleFlag = value => {
    return {
        type: actionTypes.SET_TOGGLE_FLAG,
        payload: value
    };
};
export const saveTimeFilterData = value => {
    return {
        type: actionTypes.SAVE_TIME_FILTER_DATA,
        payload: value
    };
};

export const saveCheckedData = value => {
    return {
        type: actionTypes.SAVE_CHECKED_DATA,
        payload: value
    };
};

export const saveTrainName = value => {
    return {
        type: actionTypes.SAVE_TRAIN_NAME,
        payload: value
    };
};

export const updateFleetTrains = payload => {
    return {
        type: actionTypes.UPDATE_FLEET_TRAINS,
        payload
    };
};
