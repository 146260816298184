import { cloneDeep } from "lodash";
import modelWheelSets from "./wheelSetsModel";
import eventLevelconstant from "./utils/constants";
import { statusCounts, getOverallStatus } from "./utils/computeStatusCounts";
import computeEvents from "./utils/computeEventsAndPointList";
import computeWarnings from "./utils/computeWarningAndCounts";
import wheelSetArrayForCarriages from "./utils/computeWheelSetArrayForCarriages";

const getModelledCarriages = (
    carriages,
    wheelSets,
    events,
    pointsList,
    UICCode
) => {
    let modelledCarriages = [];

    modelledCarriages = carriages.map(carriage => {
        let clonedCarriage = cloneDeep(carriage);

        // adding UIC to carriages
        clonedCarriage.UIC = UICCode[clonedCarriage.Position - 1];

        // updating the carriage added wheelsets array in place of reference
        const getWheelSetUpdatedCarriage = wheelSetArrayForCarriages(
            clonedCarriage.Wheelsets,
            wheelSets
        );
        delete clonedCarriage.Wheelsets;
        clonedCarriage.wheelSets = getWheelSetUpdatedCarriage;

        // adding events on carriage level
        const carriageEvents = computeEvents(
            clonedCarriage.VehicleNumber,
            events,
            eventLevelconstant.carriageId
        );
        clonedCarriage.events = carriageEvents;

        // adding warnings to carriages
        clonedCarriage = computeWarnings(carriageEvents, clonedCarriage);

        // modelling wheelSets array
        const wheelSetsArray = modelWheelSets(
            clonedCarriage.wheelSets,
            clonedCarriage.events,
            pointsList
        );
        clonedCarriage.wheelSets = wheelSetsArray;

        // updating the carriage with status counts
        clonedCarriage = statusCounts(clonedCarriage);

        // updating carriage with overallStatus
        clonedCarriage.overallStatus = getOverallStatus(carriageEvents);

        return clonedCarriage;
    });
    return modelledCarriages;
};
export default getModelledCarriages;
