import _ from "lodash";
import actionTypes from "../constants/index";

const filterData = {
    vehiclesData: {
        key: "vehicle",
        value: []
    },
    assetStatus: {
        value: [],
        key: "assetStatus"
    },
    measurementType: {
        value: [],
        key: "measurementType"
    }
};

const timeFilterData = {
    startDate: "",
    endDate: ""
};
const vehiclesData = {
    checked: []
};

const initialState = {
    timeFilterData: _.cloneDeep(timeFilterData),
    toggleFlag: false,
    filterData: _.cloneDeep(filterData),
    vehiclesData: _.cloneDeep(vehiclesData),
    currentTrain: "",
    updateFleetTrains: false
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SAVE_FILTER_DATA: {
            // const res = { ...state };
            const res = state;
            res.filterData = {
                ...state.filterData
            };
            const arr = res.filterData;
            const item = arr[payload.key];
            if (payload.value === null) {
                item.value = [];
            } else {
                item.value = _.cloneDeep(payload.value);
            }
            // res.filterData = _.cloneDeep(res.filterData);
            return {
                ...state,
                filterData: _.cloneDeep(res.filterData)
            };
            // return state;
        }
        case actionTypes.RESET_SAVE_FILTER_DATA: {
            return {
                ...state,
                filterData: _.cloneDeep(filterData),
                timeFilterData: _.cloneDeep(timeFilterData),
                vehiclesData: _.cloneDeep(vehiclesData)
            };
        }

        case actionTypes.SET_TOGGLE_FLAG:
            return {
                ...state,
                toggleFlag: payload
            };
        case actionTypes.SAVE_TIME_FILTER_DATA:
            return {
                ...state,
                timeFilterData: {
                    ...state.timeFilterData,
                    ...payload
                }
            };
        case actionTypes.SAVE_CHECKED_DATA:
            return {
                ...state,
                vehiclesData: {
                    checked: payload
                },
                filterData: {
                    ...state.filterData,
                    vehiclesData: {
                        key: "vehicle",
                        value: payload
                    }
                }
            };
        case actionTypes.SAVE_TRAIN_NAME:
            return {
                ...state,
                currentTrain: payload
            };
        case actionTypes.UPDATE_FLEET_TRAINS:
            return {
                ...state,
                updateFleetTrains: payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
}
