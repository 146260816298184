import { takeLatest, put } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import actionTypes from "../constants";
import { errorMsg } from "../../utils/constants";
import {
    fetchDocuments,
    downloadCategoryFile
} from "../../services/categoryListService";
import {
    saveDocuments,
    failureDocuments
} from "../actions/categoryList.action";

import modelDocuments from "../../model/categoryListModel";

export function* fetchCategoryDocuments(action) {
    const { payload } = action;
    try {
        const documents = yield fetchDocuments(payload);
        const modeledDocuments = modelDocuments(documents);
        yield put(saveDocuments(modeledDocuments));
    } catch (e) {
        yield put(failureDocuments());
        toastr.error(
           errorMsg. documentFetchError 
        );
    }
}

export function* downLoadFile(action) {
    const { payload } = action;
    const { trainId, file } = payload;
    try {
        const response = yield downloadCategoryFile(trainId, file[0]);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file[0]}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (e) {
        toastr.error(
            errorMsg.s
        );
    }
}

export const categoryListSaga = [
    takeLatest(actionTypes.DOCUMENTS_REQUEST, fetchCategoryDocuments),
    takeLatest(actionTypes.DOWNLOAD_FILE_REQUEST, downLoadFile)
];
