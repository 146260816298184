import React, { useState } from "react";
import classnames from "classnames";
import "./style.scss";
import propTypes from "prop-types";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import Spinner from "../../Spinner";
import UploadedImageDisplay from "../UploadedImageDisplay";
import { fetchUploadedImages } from "../../../store/actions/modal.action";

const PreviousFeedbacks = props => {
    const [activeFeedbackEvent, updateActiveFeedbackEvent] = useState(-1);
    const handleClick = (index, id, files) => {
        updateActiveFeedbackEvent(index);
        props.fetchUploadedImages({
            id,
            files
        });
    };

    return (
        <div className="previous-comments">
            <div className="previous-comments-heading">Previous Comments</div>
            <div className="previous-feedback-table">
                <Table>
                    <thead className="table-header">
                        <tr>
                            <th className="table-head-content">Defect</th>
                            <th className="table-head-content">Position</th>
                            <th className="table-head-content">Severity</th>
                            <th className="table-head-content"> Images</th>
                            <th className="table-head-content-comment">
                                Comment
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.previousFeedbacks.map((feedback, index) => {
                            const checkDisabled =
                                feedback.UploadFiles.length === 0;
                            return (
                                <div
                                    className="table-content"
                                    key={feedback.FeddBackId}
                                >
                                    <tr>
                                        <td>{feedback.DefectType}</td>
                                        <td>{feedback.Position}</td>
                                        <td>{feedback.Classification}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className={classnames({
                                                    "no-images-button": checkDisabled,
                                                    "images-display-button": !checkDisabled
                                                })}
                                                disabled={checkDisabled}
                                                onClick={() => {
                                                    handleClick(
                                                        index,
                                                        feedback.EventCaseId,
                                                        feedback.UploadFiles
                                                    );
                                                }}
                                            >
                                                {feedback.UploadFiles.length}
                                            </button>
                                        </td>
                                        <td>{feedback.FeedBackComment}</td>
                                    </tr>
                                    {activeFeedbackEvent === index && (
                                        <tr>
                                            <td colSpan="5">
                                                {props.uploadedImagesSpinner && (
                                                    <Spinner location="events-feedback-modal" />
                                                )}

                                                {!props.uploadedImagesSpinner &&
                                                    props.uploadedImagesError && (
                                                        <div className="display-error">
                                                            {
                                                                props.uploadedImagesError
                                                            }
                                                        </div>
                                                    )}

                                                {!(
                                                    props.uploadedImagesSpinner ||
                                                    props.uploadedImagesError
                                                ) && (
                                                    <UploadedImageDisplay
                                                        files={
                                                            props.uploadedImages
                                                        }
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </div>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

function mapStateTopProps(state) {
    return {
        previousFeedbacks: state.feedbackModal.previousFeedbacks,
        uploadedImages: state.feedbackModal.uploadedImages,
        uploadedImagesError: state.feedbackModal.uploadedImagesError,
        uploadedImagesSpinner: state.feedbackModal.uploadedImagesSpinner
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUploadedImages: value => dispatch(fetchUploadedImages(value))
    };
};
PreviousFeedbacks.propTypes = {
    previousFeedbacks: propTypes.array,
    uploadedImages: propTypes.array,
    uploadedImagesError: propTypes.string,
    uploadedImagesSpinner: propTypes.bool,
    fetchUploadedImages: propTypes.func
};
PreviousFeedbacks.defaultProps = {
    previousFeedbacks: [],
    uploadedImages: [],
    uploadedImagesError: "",
    uploadedImagesSpinner: "false",
    fetchUploadedImages
};
export default connect(mapStateTopProps, mapDispatchToProps)(PreviousFeedbacks);
