import actionTypes from "../constants";

const initialState = {
    mapLocationData: [],
    modelledMapData: [],
    toggleRoute: true,
    toggleLastPositionMarker: true
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.GET_MAP_DATA_SUCCESS:
            return {
                ...state,
                mapLocationData: payload
            };
        case actionTypes.GET_MAP_DATA_FAILURE:
            return {
                ...state,
                mapLocationData: [],
                modelledMapData: []
            };
        case actionTypes.GET_MODELLED_MAP_DATA:
            return {
                ...state,
                modelledMapData: payload
            };

        case actionTypes.TOGGLE_ROUTE_MAP:
            return {
                ...state,
                toggleRoute: payload
            };

        case actionTypes.TOGGLE_LAST_POSITION_MARKER:
            return {
                ...state,
                toggleLastPositionMarker: payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
