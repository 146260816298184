import _ from "lodash";

const getPointsListCallPoints = wheelsetsObjs => {
    const arrayOfPoints = [];
    wheelsetsObjs.forEach(wheelsetObj => {
        arrayOfPoints.push(_.get(wheelsetObj, "Reference", ""));
        if (wheelsetObj.GearBox !== null) {
            arrayOfPoints.push(_.get(wheelsetObj, "GearBox.Reference", ""));
        }
        if (wheelsetObj.TractionMotor !== null) {
            arrayOfPoints.push(
                _.get(wheelsetObj, "TractionMotor.Reference", "")
            );
        }
    });

    return arrayOfPoints;
};

export default getPointsListCallPoints;
