import { put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import createVehicleFilterData from "../../utils/vehicle/vehicleFilterData";
import actionTypes from "../constants";
import { fetchDefectList } from "../../services/feedbackService";
import { defectDropDownList } from "../actions/modal.action";
import { getVehicleData, fetchEvents } from "../../services/vehicleService";
import { fetchCategoryListDropdown } from "../../services/categoryListService";
import { sortByStatus } from "../../utils/vehicle/event/findSideAndStatus";
import getModeledNormalisedEvents from "../../utils/vehicle/event/getNormalisedEvents";
import {
    finalModeledNormalised,
    eventsSuccess,
    eventsFailure
} from "../actions/events.action";
import { getMapData } from "../actions/getMapData.action";
import { storeVehicleFilterTree } from "../actions/history.action";
import getDashBoardAssetStatus from "../../utils/vehicle/status/dashBoardAssetStatus";
import createPointsListModel from "../../model/pointsList";
import {
    getWheelsets,
    getNormalisedVehicles,
    getNormalisedWheelset,
    createVehicleModel
} from "../../model/vehicleModel";
import modelVehicles from "../../model/VehicleModel/index";
import { createEventModel } from "../../model/eventModel";
import {
    normalisedVehicleDetails,
    overallVehicleCount,
    overallAssetStatus,
    overallTrainStatus
} from "../actions/vehicleModel.action";
import {
    requestVehicleDataSuccess,
    requestVehicleDataFailure
} from "../actions/vehicleData.action";
import {
    requestVehicleSuccess,
    requestVehicleFailure
} from "../actions/vehicle.action";
import { dashboardTableData } from "../actions/dashboardTableData.action";
import { getTotalVehicleCount } from "../../utils/vehicle/count";
import dashboardTableModel from "../../model/dashboardTableModel";
import { fetchPointList } from "./pointList.saga";
import getOverallTrainStatus from "../../utils/vehicle/status/overallTrainStatus";
import categoryListDropdown from "../actions/categoryList.action";
import getPointsListCallPoints from "../../utils/vehicle/pointsList/pointsListPoints";
// import vehicleFakeData from "../../utils/fleet/vehicleFakeData";
// import eventPath from "../../utils/vehicle/eventPathConstants";

function* fetchVehicleAsync() {
    try {
        // tp fetch vehicles
        const vehicles = yield getVehicleData();
        // vehicles = vehicleFakeData; // linked to fake data for data modelling
        const count = getTotalVehicleCount(vehicles);

        // to  get the count of number of vehicles
        yield put(overallVehicleCount(count));
        // to dispatch vehicleReferences to the store
        yield put(requestVehicleSuccess(vehicles));
        // to dispatch vehicleDetails to the store
        yield put(requestVehicleDataSuccess(vehicles));

        // // vehicles = vehiclesHierarchryData;
        // // let events = eventsHierarchryData;

        // to fetch events
        let events = yield fetchEvents();
        // to store events to the redux
        yield put(eventsSuccess(events));
        events = _.filter(events, event => {
            if (event.reports) {
                if (event.reports.length > 0) {
                    return true;
                }
            }
            return false;
        });

        const normalisedVehicleData = getNormalisedVehicles(vehicles);
        const wheelsets = getWheelsets(normalisedVehicleData);

        // points on which pointsList call has to be made
        const pointsListPoints = getPointsListCallPoints(wheelsets);

        const normalisedWheelsetData = getNormalisedWheelset(wheelsets);

        // // to fetch pointsList
        const parameter = {
            payload: pointsListPoints
        };
        let pointsList = yield fetchPointList(parameter);

        pointsList = pointsList.flat();
        // const pointsList = pointsListHierarchryData;
        // to model events
        events = createEventModel(events, vehicles);

        // // to model pointsList

        const modledPointsList = createPointsListModel(
            pointsList,
            vehicles,
            normalisedWheelsetData
        );


        let finalModledEvents = events.concat(modledPointsList);



        finalModledEvents = sortByStatus(finalModledEvents);
        // // normalise events
        // events = getNormalisedEvents(events);

        const normalisedFinalModeledEvents = getModeledNormalisedEvents(
            finalModledEvents
        );


        // // changed the previos events array with new modelled events
        yield put(finalModeledNormalised(normalisedFinalModeledEvents));

        // // vehicle model
        // const normalisedVehicleModel = createVehicleModel(
        //     normalisedVehicleData,
        //     normalisedWheelsetData,
        //     pointsList,
        //     finalModledEvents,
        //     normalisedFinalModeledEvents
        // ); // old vehicle model
        const modelledVehicles = modelVehicles(
            normalisedVehicleData,
            normalisedWheelsetData,
            pointsList,
            finalModledEvents
        );
        console.debug("modelledVehicles", modelledVehicles); // to check the modelled Data for now

        yield put(normalisedVehicleDetails(modelledVehicles)); // replaced with new vehicle model

        const counts = getDashBoardAssetStatus(modelledVehicles); // replaced with new vehicle model
        const {
            bearingsStatus,
            wheelsStatus,
            sensorStatus,
            gearboxStatus,
            tractionMotorStatus
        } = counts;

        const overallAssetStatusData = {
            bearingsStatus,
            wheelsStatus,
            sensor: sensorStatus,
            gearboxStatus,
            tractionMotorStatus
        };

        // to put overallAssetInfo to the redux
        yield put(overallAssetStatus(overallAssetStatusData));

        // to get the overall train status
        const overallTrainStatusData = getOverallTrainStatus(modelledVehicles); // replaced with new vehicle model
        // to store the overall train status
        yield put(overallTrainStatus(overallTrainStatusData));
        // for the dashborad trainTable
        const tableModelDashboard = dashboardTableModel(modelledVehicles); //  replaced with new vehicle model
        // const duplicateEntries = tableModelDashboard.reduce((res, current) => {
        //     return res.concat(Array(15).fill(current));
        // }, []);
        yield put(dashboardTableData(tableModelDashboard));
        const defectListResponse = yield fetchDefectList();
        yield put(defectDropDownList(defectListResponse));
        const categoryListDropdownItems = yield fetchCategoryListDropdown();
        yield put(categoryListDropdown(categoryListDropdownItems));
        yield put(getMapData());
        const filterVehicleTree = createVehicleFilterData(modelledVehicles); //  replaced with new vehicle model

        yield put(storeVehicleFilterTree(filterVehicleTree));

        // to stop the loader
    } catch (error) {
        yield put(requestVehicleDataFailure(error));
        yield put(requestVehicleFailure());
        yield put(eventsFailure());
        console.log(error);

        toastr.error(
            `An error has ocurred, please try again.
            If this error persists contact SKF administrator for more information.`
        );
    }
}
export default [takeEvery(actionTypes.VEHICLE_REQUEST, fetchVehicleAsync)];
