import actionTypes from "../constants/index";

const initialState = () => {
    return {
        userData: {
            username: localStorage.getItem("username"),
            auth_token: localStorage.getItem("AUTH_TOKEN"),
            refresh_token: localStorage.getItem("refresh_token")
        },
        hasErrorEmail: "",
        hasErrorPwd: "",
        loginSpinner: false
    };
};

export default function(state = initialState(), action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.USER_REQUEST: {
            return {
                ...state,
                loginSpinner: true
            };
        }
        case actionTypes.USER_SUCCESS: {
            return {
                ...state,
                userData: payload,
                loginSpinner: false
            };
        }
        case actionTypes.USER_FAILURE: {
            return {
                ...state,
                loginSpinner: false
            };
        }
        case actionTypes.ERROR_EMAIL: {
            return {
                ...state,
                hasErrorEmail: payload
            };
        }
        case actionTypes.REMOVE_ERROR_EMAIL: {
            return {
                hasErrorEmail: payload
            };
        }
        case actionTypes.ERROR_PWD: {
            return {
                ...state,
                hasErrorPwd: payload
            };
        }
        case actionTypes.REMOVE_ERROR_PWD: {
            return {
                hasErrorPwd: payload
            };
        }
        case actionTypes.LOGOUT:
            return initialState;

        default:
            return { ...state };
    }
}
