import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import {
    calculateBearingValue,
    calculateWheelValue,
    calculateSensorValue
} from "../../../../utils/trainTopView/wheelBearingSensorCalculations";
import findAssetOverlayClass from "../../../../utils/trainTopView/findAssetOverlayClass";
import { fleetRoutes, axleBox } from "../../../../utils/constants";

const AxleBox = props => {
    const [wheelValue, updateWheelValue] = useState("");
    const [bearingValue, updateBearingValue] = useState("");
    const [sensorValue, updateSensorValue] = useState("");
    const [locationName, updateLocationName] = useState("");
    const [assetOverLay, updateAssetOverLay] = useState(`${axleBox.noClass}`);
    const [dataTip, updateDataTip] = useState("");

    useEffect(() => {
        const newWheelValue = calculateWheelValue(props.data);
        updateWheelValue(newWheelValue);
        const newBearingValue = calculateBearingValue(props.data);
        updateBearingValue(newBearingValue);

        const newSensorValue = calculateSensorValue(props.data);
        updateSensorValue(newSensorValue);

        if (props.data !== undefined) {
            let newLocationName = props.data.LocationName;
            newLocationName = newLocationName
                .split(" ")
                .join("")
                .replace("/", "-");
            updateLocationName(newLocationName);
            const newDataTip = props.data.LocationName;
            updateDataTip(newDataTip);
        }

        if (
            (props.data && props.match.params.assetId) ||
            (props.data && props.match.params.gearBoxId) ||
            (props.data && props.match.params.tractionMotorId)
        ) {
            const newAssetOverLay = findAssetOverlayClass(
                props.routeName,
                props.data.LocationName,
                props.match.params.assetId,
                `${fleetRoutes.assetRoute}`
            );
            updateAssetOverLay(newAssetOverLay);
        }
    }, [
        props.data,
        props.match.params.assetId,
        props.match.params.gearBoxId,
        props.match.params.tractionMotorId,
        props.routeName
    ]);

    return (
        <Link
          to={`/train/${props.match.params.id}/vehicle/${props.carriageReference}/asset/${locationName}`}
          data-tip={dataTip}
        >
            <div className={`${props.position} ${assetOverLay}`}>
                <div className={wheelValue} />
                <div className={bearingValue} />
                <div className={sensorValue} />
            </div>
        </Link>
    );
};
export default AxleBox;
