import actionTypes from "../constants";

const initialState = {
    showDropDownProfile: false
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.DROP_DOWN_PROFILE:
            return {
                ...state,
                showDropDownProfile: !state.showDropDownProfile
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
