import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import TrainsTableHeading from "./TrainsTableHeading";
import TrainsTableBody from "./TrainsTableBody";
import "./style.scss";
import Pagination from "../Pagination/index";
import {
    addNoOfPagesAndItemsPerPage,
    updateActiveRoute
} from "../../store/actions/pagination.action";
import { paginationConstants } from "../Pagination/paginationUtils";

const DashboardTrainsTable = props => {
    return (
        <div className="trains-table-container row">
            <div className="col">
                <TrainsTableHeading
                    updateActiveRoute={props.updateActiveRoute}
                />
                <TrainsTableBody />
                <Pagination
                    itemsPerPage={paginationConstants.itemsPerPage}
                    totalItems={props.dashboardTableRow.length}
                    displayLimit={paginationConstants.displayLimit}
                    noOfPages={props.noOfPages}
                    activeKey={props.activeKey}
                    addNoOfPagesAndItemsPerPage={
                        props.addNoOfPagesAndItemsPerPage
                    }
                    updateActiveRoute={props.updateActiveRoute}
                />
            </div>
        </div>
    );
};

DashboardTrainsTable.propTypes = {
    updateActiveRoute: propTypes.func,
    addNoOfPagesAndItemsPerPage: propTypes.func,
    dashboardTableRow: propTypes.array,
    noOfPages: propTypes.number,
    activeKey: propTypes.number
};
DashboardTrainsTable.defaultProps = {
    updateActiveRoute,
    addNoOfPagesAndItemsPerPage,
    dashboardTableRow: [],
    noOfPages: 0,
    activeKey: 0
};

const mapStateToProps = state => {
    return {
        dashboardTableRow: state.dashboardTableRow.dashboardTable,
        noOfPages: state.pagination.noOfPages,
        activeKey: state.pagination.activeKey,
        itemsPerPage: state.pagination.itemsPerPage
    };
};
const bindActions = dispatch => {
    return {
        addNoOfPagesAndItemsPerPage: value => {
            dispatch(addNoOfPagesAndItemsPerPage(value));
        },
        updateActiveRoute: value => dispatch(updateActiveRoute(value))
    };
};
export default connect(mapStateToProps, bindActions)(DashboardTrainsTable);
