import _ from "lodash";
import actionTypes from "../constants";

const initialState = {
    categoryListDropDown: [],
    showModal: false,
    category: "Choose",
    documents: []
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.CATEGORY_LIST:
            return {
                ...state,
                categoryListDropDown: _.cloneDeep(payload)
            };
        case actionTypes.SHOW_CATEGORY_LIST_MODAL:
            return {
                ...state,
                showModal: !state.showModal
            };
        case actionTypes.SET_CATEGORY_DROPDOWN:
            return {
                ...state,
                category: payload
            };
        case actionTypes.DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: _.cloneDeep(payload)
            };
        case actionTypes.DOCUMENTS_FAILURE:
            return {
                ...state,
                documents: []
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
