import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import findAssetOverlayClass from "../../../../utils/trainTopView/findAssetOverlayClass";
import { fleetRoutes, axleBox } from "../../../../utils/constants";

const TractionMotor = props => {
    const [tractionLink, updateTractionLink] = useState("");
    const [tractionOverLay, updateTractionOverLay] = useState(
        `${axleBox.noClass}`
    );
    const [dataTip, updateDataTip] = useState("");

    useEffect(() => {
        if (props.tractionMotorData !== undefined) {
            let newTractionLink = props.tractionMotorData.LocationName;
            if (newTractionLink) {
                updateDataTip(newTractionLink);
                newTractionLink = newTractionLink.split(" ").join("");
                updateTractionLink(newTractionLink);
            }
            if (
                props.match.params.assetId ||
                props.match.params.gearBoxId ||
                props.match.params.tractionMotorId
            ) {
                const newTractionOverLay = findAssetOverlayClass(
                    props.routeName,
                    props.tractionMotorData.LocationName &&
                        props.tractionMotorData.LocationName.split(" ").join(
                            ""
                        ),
                    props.match.params.tractionMotorId,
                    `${fleetRoutes.tractionMotorRoute}`
                );
                updateTractionOverLay(newTractionOverLay);
            }
        }
    }, [
        props.tractionMotorData,
        props.match.params.assetId,
        props.match.params.gearBoxId,
        props.match.params.tractionMotorId,
        props.routeName
    ]);
    return (
        <Link
          onClick={event => {
                if (!tractionLink) {
                    event.preventDefault();
                }
            }}
          to={`/train/${props.match.params.id}/vehicle/${props.carriageReference}/tractionMotor/${tractionLink}`}
          data-tip={dataTip}
        >
            <div className={`${props.position} ${tractionOverLay}`}>
                <div className={props.tractionValue} />
            </div>
        </Link>
    );
};
export default TractionMotor;
