const getDashBoardAssetStatus = vehicles => {
    let redBearingCount = 0;
    let redWheelCount = 0;
    let amberWheelCount = 0;
    let amberBearingCount = 0;
    let sensorCount = 0;
    let amberGearboxCount = 0;
    let redGearboxCount = 0;
    let amberTractionMotorCount = 0;
    let redTractionMotorCount = 0;
    vehicles.forEach(vehicle => {
        redBearingCount += vehicle.redBearingCount;
        redWheelCount += vehicle.redWheelCount;
        amberBearingCount += vehicle.amberBearingCount;
        amberWheelCount += vehicle.amberWheelCount;
        sensorCount += vehicle.sensorCount;
        amberGearboxCount += vehicle.amberGearboxCount;
        redGearboxCount += vehicle.redGearboxCount;
        amberTractionMotorCount += vehicle.amberTractionMotorCount;
        redTractionMotorCount += vehicle.redTractionMotorCount;
    });
    const bearingsStatus = {
        redBearings: redBearingCount,
        amberBearings: amberBearingCount
    };
    const wheelsStatus = {
        redWheels: redWheelCount,
        amberWheels: amberWheelCount
    };

    const sensorStatus = sensorCount;
    const gearboxStatus = {
        redGearBoxes: redGearboxCount,
        amberGearBoxes: amberGearboxCount
    };
    const tractionMotorStatus = {
        redTractionMotors: redTractionMotorCount,
        amberTractionMotors: amberTractionMotorCount
    };

    return {
        bearingsStatus,
        wheelsStatus,
        sensorStatus,
        gearboxStatus,
        tractionMotorStatus
    };
};
export default getDashBoardAssetStatus;
