import _ from "lodash";
import { assetTypes } from "../../constants";

const findFunctionalLocation = (
    vehicleReference,
    vehicles,
    side,
    assetType,
    wheelSetUniqueReference
) => {
    let location = "";
    if (wheelSetUniqueReference) {
        vehicles.forEach(vehicle => {
            if (
                vehicle.Vehicle.Designation.toString() ===
                vehicleReference.toString()
            ) {
                vehicle.Vehicle.Wheelsets.forEach(wheelset => {
                    if (
                        wheelset.Reference.toString() ===
                        wheelSetUniqueReference.toString()
                    ) {
                        if (assetType === assetTypes.gearbox.value) {
                            if (
                                wheelset.GearBox &&
                                wheelset.GearBox.LocationName
                            ) {
                                location = wheelset.GearBox.LocationName;
                            }
                        } else if (
                            assetType === assetTypes.tractionMotor.value
                        ) {
                            if (
                                wheelset.TractionMotor &&
                                wheelset.TractionMotor.LocationName
                            ) {
                                location = wheelset.TractionMotor.LocationName;
                            }
                        } else {
                            wheelset.Axleboxes.find(ax => {
                                if (ax.Side === side) {
                                    location = ax.LocationName;
                                    return true;
                                }
                                return false;
                            });
                        }
                    }
                    return true;
                });
            }
            return true;
        });
    }
    return location;
};

export const getPointsListBySide = (points, side) => {
    const filteredPoints = points.filter(point => {
        if (point.Name.split(" ")[1] === side) {
            return true;
        }
        return false;
    });
    return filteredPoints;
};
export default findFunctionalLocation;
