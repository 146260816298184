import React, { Component } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import propTypes from "prop-types";
import "./style.scss";
import { connect } from "react-redux";
import {
    sortDashboardTable,
    dropDownDashboard,
    dropDownDashboardOpen
} from "../../../store/actions/sort.action";
import { dashboardSortedTable } from "../../../utils/constants";

class TrainsDropdown extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {};
    }

    sortTrains = (totalTrainsArray, text, dropDownLabel) => {
        this.props.dropDownDashboard(dropDownLabel);
        this.props.sortDashboardTable({
            totalTrainsArray,
            sortCriteria: text
        });
    };

    toggle() {
        this.props.dropDownDashboardOpen();
    }

    render() {
        return (
            <div className="drop-down-parent">
                <Dropdown
                    isOpen={this.props.dropdownOpen}
                    toggle={this.toggle}
                    className="dropdown"
                >
                    <DropdownToggle tag="button" className="toggle" caret>
                        {this.props.dropDownValue}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                        {dashboardSortedTable.map((item, index) => {
                            return (
                                <div key={item.sortCriteria}>
                                    <DropdownItem
                                        className="dropdownItem"
                                        onClick={() => {
                                            this.sortTrains(
                                                this.props.totalTrainsArray,
                                                item.sortCriteria,
                                                item.dropDownLabel
                                            );
                                            this.props.updateActiveRoute(1);
                                        }}
                                    >
                                        {item.dropDownLabel}
                                    </DropdownItem>
                                    {dashboardSortedTable.length - 1 ===
                                    index ? null : (
                                        <DropdownItem divider />
                                    )}
                                </div>
                            );
                        })}
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

TrainsDropdown.propTypes = {
    dropDownDashboard: propTypes.func,
    sortDashboardTable: propTypes.func,
    dropDownDashboardOpen: propTypes.func,
    updateActiveRoute: propTypes.func,
    totalTrainsArray: propTypes.array,
    dropdownOpen: propTypes.bool,
    dropDownValue: propTypes.string
};
TrainsDropdown.defaultProps = {
    dropDownDashboard,
    sortDashboardTable,
    dropDownDashboardOpen,
    updateActiveRoute() {},
    totalTrainsArray: [],
    dropdownOpen: " ",
    dropDownValue: " "
};
const mapStateToProps = state => {
    return {
        dropdownOpen: state.dropDownDashboard.dropdownOpen,
        dropDownValue: state.dropDownDashboard.dropDownValue,
        totalTrainsArray: state.dashboardTableRow.dashboardTable
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        dropDownDashboard: value => {
            dispatch(dropDownDashboard(value));
        },
        sortDashboardTable: value => {
            dispatch(sortDashboardTable(value));
        },
        dropDownDashboardOpen: () => {
            dispatch(dropDownDashboardOpen());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrainsDropdown);
