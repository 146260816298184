import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import alarmStatusCode, {
    PAGE_NAME,
    buttonColorClass
} from "../../utils/constants";
import rightArrow from "../../assets/images/rightArrow.svg";
import downArrow from "../../assets/images/arrowDown.svg";
import ButtonComponent from "../Button";
import { findDate } from "../../utils/findDateAndTime";
import { isNilOrEmpty } from "../../utils/helper";
import ModalComponent from "../ModalComponent";
import {
    saveModalData,
    getPreviousFeedbacks
} from "../../store/actions/modal.action";

const RenderRow = props => {
    const [collapse, toggle] = useState(false);
    const [toggleModal, setToggle] = useState(false);

    const handleKeyPress = () => {};
    const setModal = value => {
        setToggle(value);

        const modalObj = {};

        modalObj.eventCaseId = props.rowData.eventCaseId;
        modalObj.position = props.rowData.metaData.assetId;
        modalObj.assetType = props.rowData.metaData.assetType;
        modalObj.pathName = props.rowData.metaData.pathName;
        modalObj.severity = props.rowData.feedBackSeverity;
        modalObj.recommendedAction =
            props.rowData.computedReport.recommendedAction;

        props.saveModalData(modalObj);

        props.getPreviousFeedbacks(modalObj.eventCaseId);
    };

    return (
        <tbody>
            <tr>
                <td
                    className={
                        props.rowData.status ===
                        alarmStatusCode.dangerTemperature.color
                            ? "report-table-row-side-red-highlighter"
                            : props.rowData.status ===
                              alarmStatusCode.danger.color
                            ? "report-table-row-side-red-highlighter"
                            : "report-table-row-side-yellow-highlighter"
                    }
                >
                    <div />
                </td>

                {props.pageFrom === PAGE_NAME.fleet && (
                    <td className="td-text">
                        <div>{props.rowData.metaData.assetId}</div>
                    </td>
                )}

                <td className="td-text table-cell-width">
                    <div>{props.rowData.metaData.assetType}</div>
                </td>
                <td className="td-text table-cell-width">
                    <div>{props.rowData.metaData.measurementType}</div>
                </td>
                <td className="btn-style">
                    {props.rowData.status ===
                    alarmStatusCode.dangerTemperature.color ? (
                        <ButtonComponent
                            color={buttonColorClass.danger}
                            text={alarmStatusCode.dangerTemperature.value}
                        />
                    ) : props.rowData.status ===
                      alarmStatusCode.danger.color ? (
                        <ButtonComponent
                            color={buttonColorClass.danger}
                            text={alarmStatusCode.danger.value}
                            slightLeft
                        />
                    ) : (
                        <div className="td-text">
                            {alarmStatusCode.warning.value}
                        </div>
                    )}
                </td>
                <td className="td-text table-cell-width">
                    <div>
                        {!isNilOrEmpty(props.rowData.created)
                            ? findDate(props.rowData.created)
                            : "-"}
                    </div>
                </td>
                <td className="td-text btn-style">
                    <ButtonComponent
                        color={buttonColorClass.secondary}
                        text={props.rowData.metaData.eventProgress}
                        btnSecondary
                    />
                </td>

                <td className="td-text-recommended-actions .table-cell-width-recommendation">
                    <div>{props.rowData.computedReport.recommendedAction}</div>
                </td>
                <td
                    className="td-text table-cell-width"
                    style={{ display: "flex" }}
                >
                    <div className="right-arrow-reports">
                        {props.showFeedback ? (
                            <div>
                                <ButtonComponent
                                    color={buttonColorClass.primary}
                                    text="Feedback"
                                    border
                                    onclick={() => setModal(!props.showModal)}
                                />
                            </div>
                        ) : null}

                        <div
                            className="arrow-div"
                            onClick={() => {
                                toggle(!collapse);
                            }}
                            onKeyPress={handleKeyPress}
                            tabIndex="-1"
                            role="button"
                        >
                            <img
                                src={collapse ? downArrow : rightArrow}
                                alt="right-arrow"
                            />
                        </div>
                    </div>
                </td>
            </tr>

            {collapse ? (
                <tr
                    className={`${
                        collapse ? "collapse-visible" : "collapse-collapse "
                    }`}
                >
                    <td
                        className={
                            props.rowData.status ===
                            alarmStatusCode.dangerTemperature.color
                                ? "report-table-row-side-red-highlighter"
                                : props.rowData.status ===
                                  alarmStatusCode.danger.color
                                ? "report-table-row-side-red-highlighter"
                                : "report-table-row-side-yellow-highlighter"
                        }
                    />

                    <td colSpan={7}>
                        <div>
                            <div className="recommendation">
                                <div>Diagnosis:&nbsp;</div>
                                <div>
                                    {props.rowData.computedReport.diagnosis}
                                </div>
                            </div>

                            {props.rowData.metaData.measurementType ===
                                "Temperature" ||
                            props.rowData.metaData.measurementType ===
                                "Sensor" ? null : (
                                <div className="recommendation">
                                    <div>Recommendation:&nbsp; </div>
                                    <div>
                                        {" "}
                                        {
                                            props.rowData.computedReport
                                                .recommendation
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            ) : null}

            {toggleModal ? (
                <ModalComponent
                    isOpen={toggleModal}
                    onclick={() => setToggle(!toggleModal)}
                />
            ) : null}
        </tbody>
    );
};

RenderRow.propTypes = {
    rowData: PropTypes.object
};

RenderRow.defaultProps = {
    rowData: {}
};

function mapStateToProps(state) {
    return {
        showModal: state.feedbackModal.showModal,
        modalData: state.feedbackModal.modalData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveModalData: data => {
            dispatch(saveModalData(data));
        },
        getPreviousFeedbacks: data => {
            dispatch(getPreviousFeedbacks(data));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RenderRow);
