import actionTypes from "../constants";

export const getMapData = () => {
    return {
        type: actionTypes.GET_MAP_DATA
    };
};

export const getMapDataSuccess = mapLocationData => {
    return {
        type: actionTypes.GET_MAP_DATA_SUCCESS,
        payload: mapLocationData
    };
};

export const getMapDataFailure = () => {
    return {
        type: actionTypes.GET_MAP_DATA_FAILURE
    };
};

export const getModelledMapData = modelledMapData => {
    return {
        type: actionTypes.GET_MODELLED_MAP_DATA,
        payload: modelledMapData
    };
};

export const toggleRouteMap = toggleRouteMap => {
    return {
        type: actionTypes.TOGGLE_ROUTE_MAP,
        payload: toggleRouteMap
    };
};

export const toggleLastPositionMarker = toggleValue => {
    return {
        type: actionTypes.TOGGLE_LAST_POSITION_MARKER,
        payload: toggleValue
    };
};
