import actionTypes from "../constants/index";

export const setPassword = data => {
    return {
        type: actionTypes.SET_PASSWORD,
        payload: data
    };
};

export const setConfirmPwd = data => {
    return {
        type: actionTypes.SET_CONFIRM_PWD,
        payload: data
    };
};

export const setCurrentPwd = data => {
    return {
        type: actionTypes.SET_CURRENT_PWD,
        payload: data
    };
};
export const updateConfirmPwdError = data => {
    return {
        type: actionTypes.CONFIRM_PWD_ERROR,
        payload: data
    };
};
export const removeConfrmPwdError = () => {
    return {
        type: actionTypes.REMOVE_CONFIRM_PWD_ERROR,
        payload: ""
    };
};
export const updatePassword = formData => {
    return {
        type: actionTypes.CHANGE_PASSWORD_REQUEST,
        payload: formData
    };
};

export const changePasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS
    };
};
