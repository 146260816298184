import _ from "lodash";

const findEventVehicleReference = (carriageReference, vehicles) => {
    let vehicleObj = _.filter(vehicles, vehicle => {
        const isMatched = _.find(vehicle.Vehicle.Carriages, carriage => {
            if (carriage.VehicleNumber === carriageReference) {
                return true;
            }
            return false;
        });
        if (isMatched) {
            return vehicle;
        }
        return false;
    });

    vehicleObj.forEach(vehicle => {
        vehicleObj = {
            vehicleReference: _.get(vehicle, "Vehicle.Designation", ""),
            traction: _.get(vehicle, "Vehicle.Traction", "")
        };
    });

    return vehicleObj;
};

export default findEventVehicleReference;
