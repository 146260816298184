import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { geolocated } from "react-geolocated";
import * as _ from "lodash";
import { Button } from "reactstrap";
import MapWrapper from "./MyMap";
import "./style.scss";
import { getFilteredTrainArr } from "../../utils/vehicle/filterFleetTrains";
import {
    toggleRouteMap,
    toggleLastPositionMarker
} from "../../store/actions/getMapData.action";

const computeHistoryMapData = (trainArr, mapModeledData) => {
    const res = {
        filteredTrainPath: [],
        lastPositionMarkerArray: []
    };
    if (trainArr && mapModeledData) {
        _.forEach(trainArr, trainObj => {
            const trainName = _.get(trainObj, "Designation", "");
            const status = _.get(trainObj, "overallStatus", "");
            const path = _.get(mapModeledData[trainName] || {}, "pathData", []);
            const lastPosition = _.get(
                mapModeledData[trainName] || {},
                "lastPositionRecorded",
                {}
            );

            if (!(_.isEmpty(path) || _.isEmpty(lastPosition))) {
                res.filteredTrainPath = [...res.filteredTrainPath, ...path];
                res.lastPositionMarkerArray = [
                    ...res.lastPositionMarkerArray,
                    { ...lastPosition, status }
                ];
            }
        });
    }

    return res;
};

const MapHistory = props => {
    const trainObjects = Object.keys(props.modelledMapData);

    const [filteredPathArray, setFilteredPathArray] = useState([]);
    const [lastPositionMarker, setLastPositionMarker] = useState([]);
    const [markerColor, setMarkerColor] = useState("");
    useEffect(() => {
        const trainNameArray = trainObjects;

        let filteredTrainPath = [];
        let lastPositionMarkerArray = [];
        let markerStatusArray = [];

        trainNameArray.forEach(trainName => {
            const findTrain = _.find(_.get(props, "vehicles", []), {
                Designation: trainName
            });
            const status = _.get(findTrain, "overallStatus", "");

            const path = _.get(
                props.modelledMapData[trainName],
                "pathData",
                []
            );
            const lastPosition = _.get(
                props.modelledMapData[trainName],
                "lastPositionRecorded",
                {}
            );

            filteredTrainPath = [...filteredTrainPath, ...path];
            lastPositionMarkerArray = {
                ...lastPositionMarkerArray,
                ...lastPosition
            };

            markerStatusArray = [...markerStatusArray, status];
        });

        setFilteredPathArray(filteredTrainPath);
        setLastPositionMarker(lastPositionMarkerArray);
        setMarkerColor(markerStatusArray);
    }, []);

    useEffect(() => {
        const filteredTrains = getFilteredTrainArr(
            props.allVehicle,
            props.appliedFilters,
            props.filterConfig,
            props.pageName
        );
        const computedData = computeHistoryMapData(
            filteredTrains,
            props.modelledMapData
        );
        setFilteredPathArray(computedData.filteredTrainPath);
        setLastPositionMarker(computedData.lastPositionMarkerArray);
    }, [props.allVehicle, props.appliedFilters]);

    return (
        <div className="map">
            <div className="col">
                <div className="row map-row d-flex justify-content-between">
                    <div className="col-md-6">
                        <p
                            className="float-left"
                            style={{ fontWeight: "bold", color: "black" }}
                        >
                            Fleet History Map
                        </p>
                    </div>
                    <div className="col-md-6 ">
                        <p
                            className="float-right"
                            style={{ fontSize: 14, color: "grey" }}
                        >
                            Last 7 Days
                        </p>
                    </div>
                </div>
                <div className="map-container">
                    <div className="map-container-top-div row">
                        <Button
                            className={`${
                                props.toggleRoute
                                    ? "map-button "
                                    : "map-disable-button"
                            } col-sm`}
                            onClick={() => {
                                props.toggleRouteMap(!props.toggleRoute);
                            }}
                        >
                            Route Map
                        </Button>
                        <Button
                            className={`${
                                props.toggleLastPositionMarker
                                    ? "map-button "
                                    : "map-disable-button"
                            } col-sm`}
                            onClick={() => {
                                props.toggleLastPosition(
                                    !props.toggleLastPositionMarker
                                );
                            }}
                        >
                            Last Position Recorded
                        </Button>
                    </div>

                    <MapWrapper
                        googleMapsApiKey={`AIzaSyD1ZyxUNNJiDzzOHNDI_16dQ8bBEkIGfpA`}
                        markers={props.toggleRoute ? filteredPathArray : []}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: "80vh" }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        lastPositionMarker={
                            props.toggleLastPositionMarker
                                ? lastPositionMarker
                                : {}
                        }
                        markerColor={markerColor}
                    />
                </div>
            </div>
            <div className="marginInfoFleet row">
                <div className="col-5">
                    <span className="fleetInfoMapTitle">
                        Density of Taken Measurements:
                    </span>
                </div>
                <div className="col-2" />
                <div className="col-5">
                    <div className="gradient-container row">
                        <div className="col-12">
                            <div className="gradient" />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="gradient-low-label col-6">
                                    <p> Low </p>
                                </div>
                                <div className="gradient-high-label col-6">
                                    <p> High </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateProps(state) {
    return {
        modelledMapData: state.mapData.modelledMapData,
        vehicles: state.vehicleModel.vehicles,
        toggleRoute: state.mapData.toggleRoute,
        toggleLastPositionMarker: state.mapData.toggleLastPositionMarker,
        filterData: state.fleetData.filterData,
        updateFleetTrains: state.fleetData.updateFleetTrains
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleRouteMap: value => dispatch(toggleRouteMap(value)),
        toggleLastPosition: value => dispatch(toggleLastPositionMarker(value))
    };
}

const MainWithGeoloc = geolocated({
    positionOptions: {
        enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
})(connect(mapStateProps, mapDispatchToProps)(withRouter(MapHistory)));
export default MainWithGeoloc;

MapHistory.propTypes = {
    modelledMapData: propTypes.object,
    toggleRoute: propTypes.bool,
    toggleLastPositionMarker: propTypes.bool,
    toggleRouteMap: propTypes.func,
    toggleLastPosition: propTypes.func,
    allVehicle: propTypes.array,
    appliedFilters: propTypes.object,
    filterConfig: propTypes.object,
    pageName: propTypes.string
};

MapHistory.defaultProps = {
    modelledMapData: {},
    toggleRoute: true,
    toggleLastPositionMarker: true,
    toggleRouteMap: () => {},
    toggleLastPosition: () => {},
    allVehicle: [],
    appliedFilters: {},
    filterConfig: {},
    pageName: ""
};
