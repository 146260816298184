import { put, takeLatest } from "redux-saga/effects";
import actionTypes from "../constants";
import { getPreviousFeedbackService } from "../../services/feedbackService";
import {
    previousFeedbacksSuccess,
    previousFeedbacksFailure
} from "../actions/modal.action";

function* fetchPreviousFeedbacks(action) {
    const { payload } = action;
    try {
        const previosFeedbacks = yield getPreviousFeedbackService(payload);
        yield put(previousFeedbacksSuccess(previosFeedbacks));
    } catch (e) {
        console.log(e);
        yield put(previousFeedbacksFailure());
    }
}

export default [
    takeLatest(
        actionTypes.FEEDBACK_PREV_FEEDBACKS_REQUEST,
        fetchPreviousFeedbacks
    )
];
