import actionTypes from "../constants";

const startHistory = data => {

    return {
        type: actionTypes.HISTORY_UNIT_DATA,
        payload: data
    };
};

export const storeVehicleFilterTree = data => {
    return {
        type: actionTypes.HISTORY_VEHICLE_FILTER_TREE,
        payload: data
    };
};

export default startHistory;
