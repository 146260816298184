import React, { useEffect, useState } from "react";
import "./style.scss";
import propTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { assetCardArray } from "../../../../utils/constants";
import computeAssetCardData from "../../../../utils/fleet/computeAssetCardData";

const AssetInfoCard = props => {
    const [dataObj, updateDataObj] = useState({});
    useEffect(() => {
        const obj = computeAssetCardData(props.match, props.currentTrain);
        updateDataObj(obj);
    }, [props.match]);

    return (
        <div className="asset-card">
            <div
                style={{
                    paddingBottom: 10
                }}
            >
                Assets
            </div>

            <div className="asset-box-div">
                {assetCardArray.map(item => {
                    return (
                        <div className="card-div">
                            <div className="asset-heading-text">{item.key}</div>
                            <div className="asset-value-text">
                                {dataObj[item.value] || "-"}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
AssetInfoCard.defaultProps = {
    match: propTypes.object,
    currentTrain: propTypes.array
};
AssetInfoCard.propTypes = { match: {}, currentTrain: [] };
export default withRouter(AssetInfoCard);
