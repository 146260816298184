import actionTypes from "../constants";

export const saveModalData = modalData => {
    return {
        type: actionTypes.SAVE_MODAL_DATA,
        payload: modalData
    };
};

export const saveSelectedValue = data => {
    return {
        type: actionTypes.FEEDBACK_DROPDOWN_SELECTED_VALUE,
        payload: data
    };
};

export const saveComments = data => {
    return {
        type: actionTypes.SAVE_COMMENTS,
        payload: data
    };
};

export const saveImages = data => {
    return {
        type: actionTypes.SAVE_IMAGES,
        payload: data
    };
};

export const getPreviousFeedbacks = data => {
    return {
        type: actionTypes.GET_PREVIOUS_FEEDBACKS,
        payload: data
    };
};

export const clearFeedbackModalObj = () => {
    return {
        type: actionTypes.CLEAR_FEEDBACK_OBJ
    };
};

export const sendFeedBackData = (data, images, eventCaseId) => {
    return {
        type: actionTypes.SEND_FEEDBACK_DATA_REQUEST,
        payload: { data, images, eventCaseId }
    };
};

export const defectDropDownList = data => {
    return {
        type: actionTypes.DEFECT_DROP_DOWN,
        payload: data
    };
};
export const getPreviousComments = eventCaseId => {
    return {
        type: actionTypes.FEEDBACK_PREV_FEEDBACKS_REQUEST,
        payload: eventCaseId
    };
};

export const previousFeedbacksSuccess = previousFeedbacks => {
    return {
        type: actionTypes.FEEDBACK_PREV_FEEDBACKS_SUCCESS,
        payload: previousFeedbacks
    };
};

export const previousFeedbacksFailure = () => {
    return {
        type: actionTypes.FEEDBACK_PREV_FEEDBACKS_FAILURE,
        payload: []
    };
};

export const showPreviousFeedbacks = () => {
    return {
        type: actionTypes.SHOW_PREVIOUS_FEEDBACKS
    };
};

export const showCommentError = data => {
    return {
        type: actionTypes.COMMENT_ERROR,
        payload: data
    };
};

export const eventFeedbackSuccess = () => {
    return {
        type: actionTypes.SEND_FEEDBACK_DATA_SUCCESS
    };
};
export const eventFeedbackFailure = () => {
    return {
        type: actionTypes.SEND_FEEDBACK_DATA_FAILURE
    };
};
export const fetchUploadedImages = data => {
    return { type: actionTypes.FETCH_UPLOADED_IMAGES_REQUEST, payload: data };
};
export const fetchUploadedImagesSuccess = data => {
    return {
        type: actionTypes.FETCH_UPLOADED_IMAGES_SUCCESS,
        payload: data
    };
};
export const fetchUploadedImagesFailure = error => {
    return {
        type: actionTypes.FETCH_UPLOADED_IMAGES_FAILURE,
        payload: error
    };
};
