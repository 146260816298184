import actionTypes from "../constants";

const startLogout = () => {
    return {
        type: actionTypes.LOGOUT
    };
};
export const setIsProgress = () => {
    return {
        type: actionTypes.IS_PROGRESS
    };
};
export default startLogout;
