import _ from "lodash";
import { normalize, schema } from "normalizr";
import { getSensorCount } from "../utils/vehicle/count";
import getVehicleOverallCounts, {
    getVehicleIndividualCounts,
    getOverallStatus
} from "../utils/vehicle/status/vehicleOverallStatus";

import { getPointsListBySide } from "../utils/vehicle/wheelset/findFunctionalLocation";
import getAssetEventsStatusCounts from "../utils/vehicle/event/assetEventsStatusCounts";
import findcarriageWarnings, {
    findVehicleWarnings
} from "../utils/vehicle/event/findWarnings";
import {
    getOverallColorCount,
    getColourCountAxlebox,
    getWheelStatus,
    getBearingsStatus,
    getOverallCarriageStatus
} from "../utils/vehicle/status/overallAssetStatus";
import {
    findEventsByCarriage,
    getWheelsetEvents,
    getAxleboxEvents,
    filterTemperatureEvents
} from "../utils/vehicle/event/getEvents";

import alarmStatusCode from "../utils/constants";
import getVehicleModel from "../utils/vehicle/status/getVehicleModel";
import {
    getEvents,
    getStatus
} from "../utils/vehicle/gearBoxTractionMotor/overallStatusAndEvents";
import getGBCount, {
    getTMCount
} from "../utils/vehicle/gearBoxTractionMotor/getCounts";
import getUIC from "../utils/getUIC";

// to normalise vehicles
const vehicleDetailsSchema = new schema.Entity(
    "vehicle",
    {},
    {
        idAttribute: value => {
            return value.Vehicle.Reference;
        },
        processStrategy: value => {
            const cloneObj = {
                ...value
            };
            const vehicle = { ...cloneObj.Vehicle };
            delete cloneObj.Vehicle;
            return {
                ...cloneObj,
                ...vehicle
            };
        }
    }
);
export const getNormalisedVehicles = vehicleDetails => {
    return normalize(vehicleDetails, [vehicleDetailsSchema]);
};

// to get wheelsetsfrom every vehicle
export const getWheelsets = vehicleDetails => {
    let vehicleDetail = _.get(vehicleDetails, "entities.vehicle", {});
    const wheelsets = [];
    vehicleDetail = Object.values(vehicleDetail);
    vehicleDetail.forEach(vehicle => {
        wheelsets.push(vehicle.Wheelsets);
    });
    return wheelsets.flat();
};

// //to normalise wheelsets
const wheelsetsSchema = new schema.Entity(
    "wheelsets",
    {},
    {
        idAttribute: "Reference"
    }
);

export const getNormalisedWheelset = wheelsets => {
    return normalize(wheelsets, [wheelsetsSchema]);
};

// vehicle model
export const createVehicleModel = (
    vehicles,
    wheelsets,
    pointsList,

    finalModledEvents,
    normalisedFinalModeledEvents
) => {
    let vehicleDetails = _.get(vehicles, "entities.vehicle", {});
    const wheelsetDetails = _.get(wheelsets, "entities.wheelsets", {});
    vehicleDetails = Object.values(vehicleDetails);

    const cloneVehicle = vehicleDetails.map(vehicleObj => {
        const clonedVehicleObj = _.cloneDeep(vehicleObj);
        delete clonedVehicleObj.Wheelsets;
        let carriages = _.get(clonedVehicleObj, "Carriages", []);
        const UIC = getUIC(clonedVehicleObj.SetUIC);

        // tp filter out events per vehicle

        const filteredModelEvents = _.filter(finalModledEvents, eve => {
            return eve.metaData.trainId === clonedVehicleObj.Designation;
        });
        // to get the date number

        const dateEvents = filteredModelEvents.map(e => {
            const num = e.created !== null ? new Date(e.created).getTime() : 0;
            return num;
        });
        // to find the most recent event
        const mostRecentEvent = _.max(dateEvents);

        // to find the most oldest event
        const mostOldestEvents = _.filter(dateEvents, e => {
            return e !== 0;
        });

        const mostOldestEvent = _.min(mostOldestEvents);

        clonedVehicleObj.mostRecentEvent = mostRecentEvent;
        clonedVehicleObj.mostOldestEvent = mostOldestEvent;
        // const UIC = getUIC(clonedVehicleObj.SetUIC);

        clonedVehicleObj.normalisedEvents = _.filter(
            normalisedFinalModeledEvents,
            clonedVehicleObj.Designation
        );

        carriages = carriages.map((carriage, index) => {
            let points = [];
            const clonedCarriageObj = _.cloneDeep(carriage);
            clonedCarriageObj.normalisedEvents = findEventsByCarriage(
                clonedCarriageObj.VehicleNumber,
                clonedVehicleObj.normalisedEvents,
                clonedVehicleObj
            );
            // console.log(
            //     "carraige wise events----------------------------------------------",
            //     clonedVehicleObj.Designation,
            //     clonedCarriageObj.VehicleNumber,
            //     clonedCarriageObj.normalisedEvents
            // );
            // to find warnings
            const warnings = findcarriageWarnings(
                clonedCarriageObj.normalisedEvents
            );
            const {
                sensorWarning,
                temperatureWarning,
                vibrationWarning
            } = warnings;
            // to store warnings
            clonedCarriageObj.sensorWarning = sensorWarning;
            clonedCarriageObj.temperatureWarning = temperatureWarning;
            clonedCarriageObj.vibrationWarning = vibrationWarning;
            clonedCarriageObj.UIC = UIC[index];

            const mappedData = clonedCarriageObj.Wheelsets.map(
                wheelsetReference => {
                    const gearboxPoints = [];
                    const tractionMotorPoints = [];
                    points = [];
                    if (wheelsetDetails[wheelsetReference]) {
                        const localPoints = _.filter(pointsList, point => {
                            if (
                                wheelsetReference.toString() ===
                                point.ParentID.toString()
                            ) {
                                return true;
                            }
                            if (
                                _.get(
                                    wheelsetDetails[wheelsetReference],
                                    "GearBox",
                                    {}
                                )
                            ) {
                                if (
                                    _.get(
                                        wheelsetDetails[wheelsetReference],
                                        "GearBox.Reference",
                                        ""
                                    ).toString() === point.ParentID.toString()
                                ) {
                                    gearboxPoints.push(point);
                                }
                            }
                            if (
                                _.get(
                                    wheelsetDetails[wheelsetReference],
                                    "TractionMotor",
                                    {}
                                )
                            ) {
                                if (
                                    _.get(
                                        wheelsetDetails[wheelsetReference],
                                        "TractionMotor.Reference",
                                        ""
                                    ).toString() === point.ParentID.toString()
                                ) {
                                    tractionMotorPoints.push(point);
                                }
                            }
                            return false;
                        });

                        const wheelsetEvents = getWheelsetEvents(
                            wheelsetDetails[wheelsetReference].Name,
                            clonedCarriageObj.normalisedEvents
                        );
                        // console.log(
                        //     wheelsetEvents,
                        //     wheelsetReference,
                        //     "wheelset wise events -----------"
                        // );
                        points = [...points, ...localPoints];

                        const wheelset = _.cloneDeep(
                            wheelsetDetails[wheelsetReference]
                        );
                        let gearbox = {};
                        let tractionMotor = {};
                        // gearbox
                        if (wheelset.GearBox !== null) {
                            const str = "Gearbox";
                            const gearBoxEvents = getEvents(
                                wheelsetEvents,
                                str
                            );
                            gearbox = _.cloneDeep(
                                _.get(wheelset, "GearBox", {})
                            );
                            gearbox.events = gearBoxEvents;
                            gearbox.pointsList = gearboxPoints;
                            const countOfGB = getGBCount(gearBoxEvents);

                            const {
                                redGearboxCount,
                                amberGearboxCount
                            } = countOfGB;
                            gearbox.redGearboxCount = redGearboxCount;
                            gearbox.amberGearboxCount = amberGearboxCount;
                            gearbox.overallStatus = getStatus(
                                redGearboxCount,
                                amberGearboxCount
                            );
                        }
                        // tractionMotor
                        if (wheelset.TractionMotor !== null) {
                            const str = "Traction Motor";
                            const tractionMotorEvents = getEvents(
                                wheelsetEvents,
                                str
                            );
                            tractionMotor = _.cloneDeep(
                                _.get(wheelset, "TractionMotor", {})
                            );
                            tractionMotor.events = tractionMotorEvents;
                            tractionMotor.pointsList = tractionMotorPoints;

                            const countOfTM = getTMCount(tractionMotorEvents);
                            const {
                                redTractionMotorCount,
                                amberTractionMotorCount
                            } = countOfTM;
                            tractionMotor.redTractionMotorCount = redTractionMotorCount;
                            tractionMotor.amberTractionMotorCount = amberTractionMotorCount;
                            tractionMotor.overallStatus = getStatus(
                                redTractionMotorCount,
                                amberTractionMotorCount
                            );
                        }
                        wheelset.GearBox = gearbox;
                        wheelset.TractionMotor = tractionMotor;

                        // axlebox
                        const modeledAxleBoxes = _.get(
                            wheelset,
                            "Axleboxes",
                            []
                        );
                        let axleboxes = _.cloneDeep(modeledAxleBoxes);
                        const modelAxleBoxes = axleboxes.map(axlebox => {
                            const axleboxClone = _.cloneDeep(axlebox);

                            const axleboxEvents = getAxleboxEvents(
                                axleboxClone.LocationName,
                                wheelsetEvents
                            );
                            axleboxClone.events = axleboxEvents;
                            console.log(
                                axleboxEvents,
                                axleboxClone.LocationName,
                                "axlewise events"
                            );
                            axleboxClone.pointsList = getPointsListBySide(
                                localPoints,
                                axleboxClone.Side
                            );

                            const counts = getAssetEventsStatusCounts(
                                axleboxClone.events
                            );
                            const {
                                redBearingCount,
                                amberBearingCount,
                                redWheelCount,
                                amberWheelCount,
                                sensorCount,
                                overallRedCount,
                                overallAmberCount
                            } = counts;
                            axleboxClone.eventsCount = {
                                redBearingCount,
                                amberBearingCount,
                                redWheelCount,
                                amberWheelCount,
                                sensorCount,
                                overallRedCount,
                                overallAmberCount
                            };

                            const wheelsStatus = getWheelStatus(axleboxEvents);

                            const { amberWheels, redWheels } = wheelsStatus;

                            const bearingsStatus = getBearingsStatus(
                                axleboxEvents
                            );
                            const {
                                amberBearings,
                                redBearings
                            } = bearingsStatus;

                            axleboxClone.sensorCount = getSensorCount(
                                axleboxEvents
                            );
                            axleboxClone.amberWheelCount = amberWheels;
                            axleboxClone.redWheelCount = redWheels;
                            axleboxClone.redBearingCount = redBearings;
                            axleboxClone.amberBearingCount = amberBearings;
                            const tempEvents = filterTemperatureEvents(
                                axleboxClone.events
                            );

                            if (tempEvents.length === 0) {
                                axleboxClone.overallStatus = getOverallCarriageStatus(
                                    redBearings,
                                    redWheels,
                                    amberBearings,
                                    amberWheels,
                                    axleboxClone.sensorCount
                                );
                            } else {
                                axleboxClone.overallStatus =
                                    alarmStatusCode.dangerTemperature.color;
                            }

                            axleboxClone.overallRedCount = getColourCountAxlebox(
                                redBearings,
                                redWheels
                            );
                            axleboxClone.overallAmberCount = getColourCountAxlebox(
                                amberBearings,
                                amberWheels,
                                axleboxClone.sensorCount
                            );

                            return axleboxClone;
                        });

                        axleboxes = modelAxleBoxes;
                        wheelset.Axleboxes = axleboxes;
                        // to get all the counts of bearings wheels and sensor and status
                        const counts = getVehicleOverallCounts(axleboxes);
                        const {
                            amberBearingCount,
                            redBearingCount,
                            amberWheelCount,
                            redWheelCount,
                            sensorCount
                        } = counts;

                        wheelset.amberBearingCount = amberBearingCount;
                        wheelset.redBearingCount = redBearingCount;
                        wheelset.amberWheelCount = amberWheelCount;
                        wheelset.redWheelCount = redWheelCount;
                        wheelset.sensorCount = sensorCount;
                        wheelset.amberGearboxCount = _.get(
                            gearbox,
                            "amberGearboxCount",
                            0
                        );
                        wheelset.redGearboxCount = _.get(
                            gearbox,
                            "redGearboxCount",
                            0
                        );
                        wheelset.amberTractionMotorCount = _.get(
                            tractionMotor,
                            "amberTractionMotorCount",
                            0
                        );
                        wheelset.redTractionMotorCount = _.get(
                            tractionMotor,
                            "redTractionMotorCount",
                            0
                        );
                        const status = getOverallStatus(
                            amberBearingCount,
                            redBearingCount,
                            amberWheelCount,
                            redWheelCount,
                            sensorCount,
                            wheelset.amberGearboxCount,
                            wheelset.redGearboxCount,
                            wheelset.amberTractionMotorCount,
                            wheelset.redTractionMotorCount
                        );

                        const tempEvents = filterTemperatureEvents(
                            wheelsetEvents
                        );

                        if (tempEvents.length === 0) {
                            wheelset.overallStatus = status;
                        } else {
                            wheelset.overallStatus =
                                alarmStatusCode.dangerTemperature.color;
                        }

                        wheelset.overallRedCount = getOverallColorCount(
                            redBearingCount,
                            redWheelCount,
                            wheelset.redGearboxCount,
                            wheelset.redTractionMotorCount
                        );
                        wheelset.overallAmberCount = getOverallColorCount(
                            amberBearingCount,
                            amberWheelCount,
                            wheelset.sensorCount,
                            wheelset.amberGearboxCount,
                            wheelset.amberTractionMotorCount
                        );

                        return {
                            ...wheelset
                        };
                    }

                    return mappedData;
                }
            );

            // carriage
            clonedCarriageObj.Wheelsets = mappedData;
            // to get all the counts of bearings wheels and sensor and status
            const counts = getVehicleIndividualCounts(
                clonedCarriageObj.Wheelsets
            );
            const {
                status,
                amberBearingCount,
                redBearingCount,
                amberWheelCount,
                redWheelCount,
                sensorCount,
                amberGearboxCount,
                redGearboxCount,
                amberTractionMotorCount,
                redTractionMotorCount
            } = counts;
            clonedCarriageObj.amberBearingCount = amberBearingCount;
            clonedCarriageObj.redBearingCount = redBearingCount;
            clonedCarriageObj.amberWheelCount = amberWheelCount;
            clonedCarriageObj.redWheelCount = redWheelCount;
            clonedCarriageObj.sensorCount = sensorCount;
            clonedCarriageObj.amberGearboxCount = amberGearboxCount;
            clonedCarriageObj.redGearboxCount = redGearboxCount;
            clonedCarriageObj.amberTractionMotorCount = amberTractionMotorCount;
            clonedCarriageObj.redTractionMotorCount = redTractionMotorCount;
            const tempEvents = filterTemperatureEvents(
                clonedCarriageObj.normalisedEvents
            );

            if (tempEvents.length === 0) {
                clonedCarriageObj.overallStatus = status;
            } else {
                clonedCarriageObj.overallStatus =
                    alarmStatusCode.dangerTemperature.color;
            }

            clonedCarriageObj.overallRedCount = getOverallColorCount(
                redBearingCount,
                redWheelCount,
                redGearboxCount,
                redTractionMotorCount
            );
            clonedCarriageObj.overallAmberCount = getOverallColorCount(
                amberBearingCount,
                amberWheelCount,
                sensorCount,
                amberGearboxCount,
                amberTractionMotorCount
            );

            return clonedCarriageObj;
        });
        // vehicle
        clonedVehicleObj.Carriages = carriages;
        const warnings = findVehicleWarnings(carriages);
        const {
            sensorWarning,
            temperatureWarning,
            vibrationWarning
        } = warnings;
        clonedVehicleObj.sensorWarning = sensorWarning;
        clonedVehicleObj.temperatureWarning = temperatureWarning;
        clonedVehicleObj.vibrationWarning = vibrationWarning;
        // to get all the counts of bearings wheels and sensor and status
        const counts = getVehicleIndividualCounts(carriages);
        const {
            status,
            amberBearingCount,
            redBearingCount,
            amberWheelCount,
            redWheelCount,
            sensorCount,
            amberGearboxCount,
            redGearboxCount,
            amberTractionMotorCount,
            redTractionMotorCount
        } = counts;

        clonedVehicleObj.amberBearingCount = amberBearingCount;
        clonedVehicleObj.redBearingCount = redBearingCount;
        clonedVehicleObj.amberWheelCount = amberWheelCount;
        clonedVehicleObj.redWheelCount = redWheelCount;
        clonedVehicleObj.sensorCount = sensorCount;

        clonedVehicleObj.amberGearboxCount = amberGearboxCount;
        clonedVehicleObj.redGearboxCount = redGearboxCount;
        clonedVehicleObj.amberTractionMotorCount = amberTractionMotorCount;
        clonedVehicleObj.redTractionMotorCount = redTractionMotorCount;
        const tempEvents = filterTemperatureEvents(filteredModelEvents);

        if (tempEvents.length === 0) {
            clonedVehicleObj.overallStatus = status;
        } else {
            clonedVehicleObj.overallStatus =
                alarmStatusCode.dangerTemperature.color;
        }
        clonedVehicleObj.overallAmberCount = getOverallColorCount(
            amberBearingCount,
            amberWheelCount,
            sensorCount,
            amberGearboxCount,
            amberTractionMotorCount
        );
        clonedVehicleObj.overallRedCount = getOverallColorCount(
            redBearingCount,
            redWheelCount,
            redGearboxCount,
            redTractionMotorCount
        );
        clonedVehicleObj.modelType = getVehicleModel(clonedVehicleObj);

        return clonedVehicleObj;
    });

    return cloneVehicle;
};

export default getNormalisedVehicles;
