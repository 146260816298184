import { put, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import actionTypes from "../constants";
import startLogout from "../actions/logout.action";
import { errorMsg as ERROR_MSG } from "../../utils/constants";

function* initiateRefreshToken() {
    try {
        toastr.error(ERROR_MSG.tokenExpire);
        yield put(startLogout());
        yield put(push("/login"));
        window.location.reload();
    } catch (e) {
        toastr.error(
            `An error has ocurred, please try again. If this error persists contact SKF administrator for more information.`
        );
    }
}

export default [
    takeLatest(actionTypes.REFRESH_TOKEN_REQUEST, initiateRefreshToken)
];
