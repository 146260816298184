import React, { Component } from "react";
import "./style.scss";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Input, FormGroup, Form } from "reactstrap";
import Spinner from "../../components/Spinner";
import {
    startLoginUser,
    updateEmailErrorMsg,
    removeEmailErrorMsg,
    updatePwdErrorMsg,
    removePwdErrorMsg
} from "../../store/actions/user.action";
import logo from "../../assets/images/skf.png";
import { errorTypes } from "../../utils/constants";
import { signInValidate } from "../../utils/helper";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
    }

    componentDidMount() {
        if (localStorage.AUTH_TOKEN) {
            this.props.history.push("/dashboard");
        }
    }

    handleChange = e => {
        const { name } = e.target;
        const { value } = e.target;
        this.setState({
            [name]: value
        });
    };

    handleSubmit = e => {
        this.props.removeEmailErrorMsg();
        this.props.removePwdErrorMsg();
        e.preventDefault();
        const status = signInValidate(this.state.email, this.state.password);
        if (status === true) {
            const formData = {
                // username: "UserTest",
                // password: "Test$08SKF",
                // grant_type: "password"
                username: this.state.email,
                password: this.state.password,
                grant_type: "password"
            };
            this.props.startLoginUser(formData);
        }
        if (status === errorTypes.PWD_ERROR) {
            this.props.updatePwdErrorMsg(errorTypes.PWD_ERROR);
        }
        if (status === errorTypes.EMAIL_ERROR) {
            this.props.updateEmailErrorMsg(errorTypes.EMAIL_ERROR);
        }
    };

    render() {
        return (
            <div className="fullscreen">
                <img className="logo" src={logo} alt="Logo" />
                <div className="form-section">
                    <Form
                        className="form-container"
                        onSubmit={this.handleSubmit}
                    >
                        <h2 className="login-heading">
                            {" "}
                            Welcome to SKF IMx-Rail
                        </h2>
                        <FormGroup>
                            <Input
                                type="text"
                                className="input-form"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                            />
                            <p className="error-msg">
                                {this.props.hasErrorEmail
                                    ? this.props.hasErrorEmail
                                    : ""}
                            </p>
                            <Input
                                className="input-form padding-style"
                                type="password"
                                placeholder="Password"
                                value={this.state.password}
                                name="password"
                                onChange={this.handleChange}
                            />
                            <p className="error-msg">
                                {this.props.hasErrorPwd
                                    ? this.props.hasErrorPwd
                                    : ""}
                            </p>
                        </FormGroup>

                        <Button className="login-button">
                            Log In
                            {this.props.spinner ? (
                                <Spinner location="button" />
                            ) : null}
                        </Button>
                        <br />
                    </Form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.user.userData,
        hasErrorEmail: state.user.hasErrorEmail,
        hasErrorPwd: state.user.hasErrorPwd,
        spinner: state.user.loginSpinner
    };
};
const mapDispatchToProps = dispatch => {
    return {
        removeEmailErrorMsg: () => {
            dispatch(removeEmailErrorMsg());
        },
        removePwdErrorMsg: () => {
            dispatch(removePwdErrorMsg());
        },
        startLoginUser: value => {
            dispatch(startLoginUser(value));
        },
        updatePwdErrorMsg: value => {
            dispatch(updatePwdErrorMsg(value));
        },
        updateEmailErrorMsg: value => {
            dispatch(updateEmailErrorMsg(value));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
LoginPage.propTypes = {
    hasErrorPwd: propTypes.string,
    hasErrorEmail: propTypes.string,
    removeEmailErrorMsg: propTypes.func,
    removePwdErrorMsg: propTypes.func,
    startLoginUser: propTypes.func,
    updatePwdErrorMsg: propTypes.func,
    updateEmailErrorMsg: propTypes.func,
    history: propTypes.object,
    spinner: propTypes.bool
};

LoginPage.defaultProps = {
    hasErrorPwd: "",
    hasErrorEmail: "",
    removeEmailErrorMsg,
    removePwdErrorMsg,
    startLoginUser,
    updatePwdErrorMsg,
    updateEmailErrorMsg,
    history: {},
    spinner: false
};
