import React, { useEffect, useState } from "react";
import "./style.scss";
import propTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

const getBreadCrumbItems = match => {
    const breadCrumbArray = [];
    if (match.params.id) {
        breadCrumbArray.push({
            path: `/train/${match.params.id}`,
            name: match.params.id
        });
        if (match.params.vehicleId) {
            breadCrumbArray.push({
                path: `${breadCrumbArray[0].path}/vehicle/${match.params.vehicleId}`,
                name: match.params.vehicleId
            });
            if (match.params.assetId) {
                breadCrumbArray.push({
                    path: `${breadCrumbArray[1].path}/asset/${match.params.assetId}`,
                    name: match.params.assetId
                });
            } else if (match.params.tractionMotorId) {
                breadCrumbArray.push({
                    path: `${breadCrumbArray[1].path}/tractionMotor/${match.params.tractionMotorId}`,
                    name: match.params.tractionMotorId
                });
            } else if (match.params.gearBoxId) {
                breadCrumbArray.push({
                    path: `${breadCrumbArray[1].path}/tractionMotor/${match.params.gearBoxId}`,
                    name: match.params.gearBoxId
                });
            }
        }
    }
    return breadCrumbArray;
};
const CustomBreadCrumb = props => {
    const [breadcrumbItem, updateBreadCrumbItem] = useState("");
    const [breadCrumbArray, updateBreadCrumbArray] = useState([]);
    useEffect(() => {
        if (props.match.params.id) {
            props.vehicleDetails.forEach(item => {
                if (item.Designation === props.match.params.id) {
                    const newBreadCrumbItem = item.Traction;
                    updateBreadCrumbItem(newBreadCrumbItem);
                }
            });
            const newKeys = getBreadCrumbItems(props.match);
            updateBreadCrumbArray(newKeys);
        }
    }, []);

    return (
        <div className="parent-breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/fleet">{breadcrumbItem}</Link>
                </BreadcrumbItem>

                {breadCrumbArray.map((key, index) => {
                    return (
                        <BreadcrumbItem>
                            <Link
                                to={`${key.path}`}
                                className={
                                    index === breadCrumbArray.length - 1
                                        ? "active"
                                        : null
                                }
                            >
                                {`${key.name}`}
                            </Link>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};
CustomBreadCrumb.propTypes = {
    match: propTypes.object,
    vehicleDetails: propTypes.array
};
CustomBreadCrumb.defaultProps = {
    match: {},
    vehicleDetails: []
};
export default withRouter(CustomBreadCrumb);
