import _ from "lodash";

const mapModel = (mapLocationData, maxDateArray, filterWithTrainId) => {
    let lastPositionObj = {};
    const trainObj = {};

    const lastPositionObjArray = [];

    mapLocationData.forEach((mapDataObj, index) => {
        lastPositionObj = {};
        Object.assign(lastPositionObj, mapDataObj[maxDateArray[index]]);
        lastPositionObjArray.push(lastPositionObj);
    });

    filterWithTrainId.forEach((trainObject, trainObjIndex) => {
        const trainDataObj = {};

        const routesArray = [];

        Object.values(mapLocationData[trainObjIndex]).forEach(item => {
            const latMeasurementValue = _.get(item.lat, "Measurements.0.Level", 0);
            const longMeasurementValue = _.get(item.long, "Measurements.0.Level", 0);
            if (latMeasurementValue !== 0 && longMeasurementValue !== 0) {
                const obj = {};

                obj.latitude = item.lat.Measurements[0].Level;
                obj.longitude = item.long.Measurements[0].Level;

                routesArray.push(obj);
            }
        });

        trainDataObj.pathData = routesArray;

        trainDataObj.lastPositionRecorded = lastPositionObjArray[trainObjIndex];
        trainObj[Object.keys(trainObject)] = trainDataObj;
    });

    return trainObj;
};

export default mapModel;
