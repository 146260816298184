import computeClass from "./computeClassForCarriageOverlay";
import { fleetRoutes, opactityClasses } from "../constants";

const computeClassForNonAssetRoute = route => {
    if (
        route === fleetRoutes.vehicleRoute ||
        route === fleetRoutes.trainRoute
    ) {
        return opactityClasses.noClass;
    }
    return opactityClasses.disableClass;
};

const findAssetOverlayClass = (route, assetId, assetLinkId, component) => {
    return route === component
        ? computeClass(assetLinkId, route, assetId)
        : computeClassForNonAssetRoute(route);
};
export default findAssetOverlayClass;
