import _ from "lodash";
import { fleetRoutes } from "../constants";

export const computeSupText = route => {
    let supText = "";
    if (route === "") {
        supText = "Trains";
    } else if (route === fleetRoutes.trainRoute) {
        supText = "Vehicle";
    } else if (route === fleetRoutes.vehicleRoute) {
        supText = "Asset";
    } else if (route === fleetRoutes.assetRoute) {
        supText = "Asset";
    } else if (route === fleetRoutes.tractionMotorRoute) {
        supText = "Traction";
    } else if (route === fleetRoutes.gearBoxRoute) {
        supText = "GearBox";
    }
    return supText;
};

export const computeStatusTitle = route => {
    let title = "";
    if (route === fleetRoutes.trainRoute) {
        title = "Train";
    } else if (route === fleetRoutes.vehicleRoute) {
        title = "Vehicle";
    } else if (route === fleetRoutes.assetRoute) {
        title = "Asset";
    } else if (route === fleetRoutes.tractionMotorRoute) {
        title = "TractionMotor";
    } else if (route === fleetRoutes.gearBoxRoute) {
        title = "GearBox";
    }
    return title;
};

export const computeFleetStatusProps = trainArray => {
    const statusObj = {
        overallRedCount: 0,
        overallAmberCount: 0
    };

    trainArray.forEach(vehicle => {
        if (vehicle.overallRedCount || vehicle.overallAmberCount) {
            if (vehicle.overallRedCount) {
                statusObj.overallRedCount += 1;
            } else if (vehicle.overallAmberCount) {
                statusObj.overallAmberCount += 1;
            }
        }
    });
    return statusObj;
};

export const getFilteredCategoryList = (categoryName, list) => {
    const filteredArray = _.filter(list, ele => {
        if (categoryName.includes(ele.value)) {
            return ele;
        }
        return false;
    });
    return filteredArray;
};
