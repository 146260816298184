import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import AssetCard from "../../../components/AssetCard";
import "./style.scss";
import ReportsRow from "../../../components/ReportContainer/ReportRow";
import { fleetRoutes } from "../../../utils/constants";
import computeRouteName from "../../../utils/fleet/routeName";
import HistoryGraph from "../../../components/HistoryGraph";
import AssetInfoCard from "./AssetInfoCard";
// import StatusInfoCard from "./StatusInfoCard";
import { fetchHistoryModel } from "../../../store/actions/historySensor.action";
import computeAssetReference from "../../../utils/fleet/computeAssetReference";

const TabView = props => {
    const [activeTab, toggle] = useState("status");
    const [activeEvents, updateActiveEvents] = useState([]);
    const routeName = computeRouteName(props.match);
    const startDateMoment = moment()
        .subtract(7, "d")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .utc();
    const startDate = new Date(startDateMoment.valueOf());

    const endDateMoment = moment()
        // .subtract(15-, "d")
        .set({ hour: 23, minute: 59, second: 59, millisecond: 99 })
        .utc();
    const endDate = new Date(endDateMoment.valueOf());
    useEffect(() => {
        if (
            routeName === fleetRoutes.assetRoute ||
            routeName === fleetRoutes.gearBoxRoute ||
            routeName === fleetRoutes.tractionMotorRoute
        ) {
            const references = computeAssetReference(
                props.vehicleDetails,
                props.match
            );
            const startDateForApi = startDateMoment.format(
                "YYYY-MM-DDTHH:MM:SS"
            );
            const endDateForApi = endDateMoment.format("YYYY-MM-DDTHH:MM:SS");
            const data = {
                references,
                startDate: startDateForApi,
                endDate: endDateForApi
            };
            props.fetchHistoryModel(data);
        }
        if (props.eventDetails) {
            const eventsToDisplay = props.eventDetails.filter(event => {
                return event.metaData.eventProgress !== "Closed";
            });
            updateActiveEvents(eventsToDisplay);
        }
    }, [props.match.url, props.eventDetails]);

    return (
        <div className="parent-tab-view">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: activeTab === "status"
                        })}
                        onClick={() => {
                            toggle("status");
                        }}
                    >
                        Status
                    </NavLink>
                </NavItem>
                {(routeName === fleetRoutes.assetRoute ||
                    routeName === fleetRoutes.gearBoxRoute ||
                    routeName === fleetRoutes.tractionMotorRoute) && (
                    <div className="asset-parts">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "trends"
                                })}
                                onClick={() => {
                                    toggle("trends");
                                }}
                            >
                                Trends
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "asset"
                                })}
                                onClick={() => {
                                    toggle("asset");
                                }}
                            >
                                Assets Information
                            </NavLink>
                        </NavItem>
                    </div>
                )}
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="status" className="parent-tab-pane">
                    <div className="asset-container-cards">
                        <AssetCard
                            text="Bearing"
                            danger={props.redBearingCount}
                            warnings={props.amberBearingCount}
                        />
                        <AssetCard
                            text="Wheels"
                            danger={props.redWheelCount}
                            warnings={props.amberWheelCount}
                        />
                        <AssetCard
                            text="Traction Motor"
                            danger={props.redTractionMotorCount}
                            warnings={props.amberTractionMotorCount}
                        />
                        <AssetCard
                            text="Gear box"
                            danger={props.redGearboxCount}
                            warnings={props.amberGearboxCount}
                        />
                        <AssetCard text="Sensors" sensors={props.sensorCount} />
                    </div>
                    <div className="events-part">
                        <div className="heading-events">Active Events</div>
                        <Table>
                            <ReportsRow
                                eventsArray={activeEvents}
                                pageFrom="fleet"
                            />
                        </Table>
                    </div>
                </TabPane>
                {(routeName === fleetRoutes.assetRoute ||
                    routeName === fleetRoutes.gearBoxRoute ||
                    routeName === fleetRoutes.tractionMotorRoute) && (
                    <TabPane tabId="asset" className="parent-tab-pane">
                        <div className="asset-view">
                            <AssetInfoCard currentTrain={props.currentTrain} />
                            {/* <StatusInfoCard /> */}
                        </div>
                    </TabPane>
                )}
                {(routeName === fleetRoutes.assetRoute ||
                    routeName === fleetRoutes.gearBoxRoute ||
                    routeName === fleetRoutes.tractionMotorRoute) && (
                    <TabPane tabId="trends" className="trends-tab-pane">
                        <div style={{ overflow: "auto" }}>
                            <HistoryGraph
                                graphData={props.graphData}
                                graphTimePeriod={{
                                    startDate,
                                    endDate
                                }}
                            />
                        </div>
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
};

TabView.propTypes = {
    match: propTypes.object,
    vehicleDetails: propTypes.array,
    fetchHistoryModel: propTypes.func,
    eventDetails: propTypes.array,
    currentTrain: propTypes.object,
    graphData: propTypes.array,
    redBearingCount: propTypes.number,
    amberBearingCount: propTypes.number,
    redWheelCount: propTypes.number,
    amberWheelCount: propTypes.number,
    redTractionMotorCount: propTypes.number,
    amberTractionMotorCount: propTypes.number,
    redGearboxCount: propTypes.number,
    amberGearboxCount: propTypes.number,
    sensorCount: propTypes.number
};
TabView.defaultProps = {
    match: {},
    vehicleDetails: [],
    fetchHistoryModel,
    eventDetails: [],
    currentTrain: {},
    graphData: [],
    redBearingCount: 0,
    amberBearingCount: 0,
    redWheelCount: 0,
    amberWheelCount: 0,
    redTractionMotorCount: 0,
    amberTractionMotorCount: 0,
    redGearboxCount: 0,
    amberGearboxCount: 0,
    sensorCount: 0
};

function mapStateToProps(state) {
    return {
        graphData: state.historyModel.data
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchHistoryModel: data => {
            dispatch(fetchHistoryModel(data));
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TabView));
