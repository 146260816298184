import { assetTypes } from "../constants";

export const getSensorCount = (events = []) => {
    let sensorCount = 0;
    events.forEach(event => {
        if (event.metaData.assetType === assetTypes.sensor.value) {
            sensorCount += 1;
        }
    });
    return sensorCount;
};

export const getTotalVehicleCount = vehiclesArray => {
    let count = 0;
    count = vehiclesArray.length;
    return count;
};
