import React from "react";

import { Table } from "reactstrap";
import TrainsTableRow from "../TrainsTableRow";
import "./style.scss";

const TrainsTableBody = () => {
    return (
        <div className="table-body-parent-container">
            <div className="table-container row">
                <Table className="font-weight-bold  table-sm onHover">
                    <thead className="table-header">
                        <tr>
                            <th className="normalize">
                                <div className="side-highlighter" />
                            </th>
                            <th className="normalize">
                                <div className="center style-font">Trains</div>
                            </th>
                            <th className="normalize style-font">
                                <div className="style-font">Actions</div>
                            </th>

                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <TrainsTableRow />
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default TrainsTableBody;
