import { cloneDeep } from "lodash";
import { overallStatus } from "./constants";

// computing overall status at each level
export const getOverallStatus = events => {
    let status = overallStatus.none;
    if (events) {
        const statusEvents = events.map(event => {
            return event.status;
        });
        if (statusEvents.includes(overallStatus.danger)) {
            status = overallStatus.danger;
        } else if (statusEvents.includes(overallStatus.red)) {
            status = overallStatus.red;
        } else if (statusEvents.includes(overallStatus.warning)) {
            status = overallStatus.warning;
        }
    }
    return status;
};

// computing and adding status counts to trains and carriages
export const statusCounts = unit => {
    const clonedUnit = cloneDeep(unit);

    // computed whether units are trains or carriages
    const entityArray = clonedUnit.Designation
        ? clonedUnit.carriages
        : clonedUnit.wheelSets;
    let amberBearingCount = 0;
    let redBearingCount = 0;
    let amberWheelCount = 0;
    let redWheelCount = 0;
    let sensorCount = 0;
    let overallAmberCount = 0;
    let overallRedCount = 0;
    let amberGearboxCount = 0;
    let redGearboxCount = 0;
    let amberTractionMotorCount = 0;
    let redTractionMotorCount = 0;

    // computing status counts
    entityArray.forEach(entity => {
        amberBearingCount += entity.amberBearingCount;
        redBearingCount += entity.redBearingCount;
        amberWheelCount += entity.amberWheelCount;
        redWheelCount += entity.redWheelCount;
        sensorCount += entity.sensorCount;
        overallAmberCount += entity.overallAmberCount;
        overallRedCount += entity.overallRedCount;
        amberGearboxCount += entity.amberGearboxCount;
        redGearboxCount += entity.redGearboxCount;
        amberTractionMotorCount += entity.amberTractionMotorCount;
        redTractionMotorCount += entity.redTractionMotorCount;
    });

    // updating  train and carriages with status counts
    clonedUnit.amberBearingCount = amberBearingCount;
    clonedUnit.redBearingCount = redBearingCount;
    clonedUnit.amberWheelCount = amberWheelCount;
    clonedUnit.redWheelCount = redWheelCount;
    clonedUnit.sensorCount = sensorCount;
    clonedUnit.overallAmberCount = overallAmberCount;
    clonedUnit.overallRedCount = overallRedCount;
    clonedUnit.amberGearboxCount = amberGearboxCount;
    clonedUnit.redGearboxCount = redGearboxCount;
    clonedUnit.amberTractionMotorCount = amberTractionMotorCount;
    clonedUnit.redTractionMotorCount = redTractionMotorCount;
    return clonedUnit;
};

const statusCountsForWheelSets = wheelSet => {
    const clonedWheelset = cloneDeep(wheelSet);
    const { axleBoxes, tractionMotor, gearBox } = clonedWheelset;
    let amberBearingCount = 0;
    let redBearingCount = 0;
    let amberWheelCount = 0;
    let redWheelCount = 0;
    let sensorCount = 0;
    let overallAmberCount = 0;
    let overallRedCount = 0;
    let amberGearboxCount = 0;
    let redGearboxCount = 0;
    let amberTractionMotorCount = 0;
    let redTractionMotorCount = 0;

    // computing counts for wheelsets
    axleBoxes.forEach(axleBox => {
        amberBearingCount += axleBox.amberBearingCount;
        redBearingCount += axleBox.redBearingCount;
        amberWheelCount += axleBox.amberWheelCount;
        redWheelCount += axleBox.redWheelCount;
        sensorCount += axleBox.sensorCount;
        overallAmberCount += axleBox.overallAmberCount;
        overallRedCount += axleBox.overallRedCount;
    });
    if (tractionMotor) {
        ({ amberTractionMotorCount } = tractionMotor);
        ({ redTractionMotorCount } = tractionMotor);
    }
    if (gearBox) {
        ({ amberGearboxCount } = gearBox);
        ({ redGearboxCount } = gearBox);
    }
    overallAmberCount += amberTractionMotorCount + amberGearboxCount;
    overallRedCount += redTractionMotorCount + redGearboxCount;

    // updating wheelSets with counts
    clonedWheelset.amberBearingCount = amberBearingCount;
    clonedWheelset.redBearingCount = redBearingCount;
    clonedWheelset.amberWheelCount = amberWheelCount;
    clonedWheelset.redWheelCount = redWheelCount;
    clonedWheelset.sensorCount = sensorCount;
    clonedWheelset.overallAmberCount = overallAmberCount;
    clonedWheelset.overallRedCount = overallRedCount;
    clonedWheelset.amberGearboxCount = amberGearboxCount;
    clonedWheelset.redGearboxCount = redGearboxCount;
    clonedWheelset.amberTractionMotorCount = amberTractionMotorCount;
    clonedWheelset.redTractionMotorCount = redTractionMotorCount;

    return clonedWheelset;
};
export default statusCountsForWheelSets;
