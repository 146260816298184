import _ from "lodash";

const computeAssetReference = (trainArray, match) => {
    const res = [];
    const trainIndex = _.findIndex(trainArray, {
        Designation: match.params.id
    });
    const carriageIndex = _.findIndex(trainArray[trainIndex].carriages, {
        VehicleNumber: match.params.vehicleId
    });

    const wheelsetsArray =
        trainArray[trainIndex].carriages[carriageIndex].wheelSets;
    wheelsetsArray.forEach(wheelSet => {
        const axleBox = wheelSet.axleBoxes;
        const gearBox = { wheelSet };
        const tractionMotor = { wheelSet };
        if (match.params.assetId) {
            axleBox.forEach(axle => {
                const locName = axle.LocationName.split(" ")
                    .join("")
                    .replace("/", "-");
                if (locName === match.params.assetId) {
                    res.push(axle);
                }
            });
        } else if (match.params.gearBoxId) {
            if (
                gearBox.LocationName &&
                gearBox.LocationName.split(" ").join("") ===
                    match.params.gearBoxId
            ) {
                res.push(gearBox);
            }
        } else if (match.params.tractionMotorId) {
            if (
                tractionMotor.LocationName &&
                tractionMotor.LocationName.split(" ").join("") ===
                    match.params.tractionMotorId
            ) {
                res.push(tractionMotor);
            }
        }
    });
    return res;
};
export default computeAssetReference;
