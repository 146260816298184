import * as _ from "lodash";
import moment from "moment";
import { errorTypes } from "./constants";

export const isNilOrEmpty = val => {
    return _.isNil(val) || _.isEmpty(val);
};

export const createAsyncAction = actions => {
    const objec = {};
    actions.forEach(actionType => {
        objec[`${actionType}_REQUEST`] = `${actionType}_REQUEST`;
        objec[`${actionType}_SUCCESS`] = `${actionType}_SUCCESS`;
        objec[`${actionType}_FAILURE`] = `${actionType}_FAILURE`;
    });
    return objec;
};

export const createSyncAction = actions => {
    const objec = {};
    actions.forEach(actionType => {
        objec[actionType] = actionType;
    });
    return objec;
};

export const getLastWeekDateRange = () => {
    const startDate = moment()
        .subtract(7, "days")
        .startOf("isoWeek")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
    const endDate = moment()
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss");
    return {
        startDate,
        endDate
    };
};

export const signInValidate = (email, password) => {
    if (!_.isEmpty(email)) {
        if (!_.isEmpty(password)) {
            return true;
        }
        return errorTypes.PWD_ERROR;
    }
    return errorTypes.EMAIL_ERROR;
};

export const confirmPassword = (pwd, confirmPwd) => {
    if (pwd === confirmPwd) {
        return true;
    }
    return false;
};
