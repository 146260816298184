import React, { useState } from "react";
import propTypes from "prop-types";
import { Collapse } from "reactstrap";
import classNames from "classnames";
import "./style.scss";
import alarmStatusCode from "../../utils/constants";
import accordionArrow from "../../assets/images/blueRightArrow.svg";
import dangerIcon from "../../assets/images/redDanger.svg";
import warningIcon from "../../assets/images/yellowWarning.svg";
import blueDownArrow from "../../assets/images/blueDownArrow.svg";

const assetSection = (location, selected) => {
    const assetSectionClass = classNames({
        selected: selected.includes(location.LocationName)
    });
    return assetSectionClass;
};
const RenderHistoryRow = props => {
    const [collapse, toggle] = useState(false);

    return (
        <div>
            <div className="history-accordion-container">
                <div
                    role="button"
                    className="row-container"
                    onClick={() => toggle(!collapse)}
                    tabIndex="-1"
                >
                    <div>
                        {props.overallStatus ===
                            `${alarmStatusCode.danger.color}` ||
                            (props.overallStatus ===
                                `${alarmStatusCode.danger.label}` && (
                                <img
                                    src={dangerIcon}
                                    alt={`${alarmStatusCode.danger.label}`}
                                />
                            ))}
                        {props.overallStatus ===
                        `${alarmStatusCode.warning.color}` ? (
                            <img
                                src={warningIcon}
                                alt={`${alarmStatusCode.warning.label}`}
                            />
                        ) : (
                            <div className="icon-missing-div" />
                        )}
                    </div>
                    <div className="text-div">{props.VehicleNumber}</div>
                    <div>
                        <img
                            src={collapse ? blueDownArrow : accordionArrow}
                            alt="downArrow"
                        />
                    </div>
                </div>

                {collapse ? (
                    props.functionalLocations.map(location => {
                        const assetSectionClass = `history ${assetSection(
                            location,
                            props.selected
                        )}`;
                        return (
                            <div
                                className={`${assetSectionClass}`}
                                key={location.LocationName}
                                onClick={() => {
                                    props.onLocationClick(
                                        location.LocationName
                                    );
                                }}
                            >
                                <Collapse isOpen={collapse}>
                                    <div className="row-container">
                                        <div>
                                            {location.overallStatus ===
                                                `${alarmStatusCode.danger.color}` ||
                                                (location.overallStatus ===
                                                    `${alarmStatusCode.danger.label}` && (
                                                    <img
                                                        src={dangerIcon}
                                                        alt={`${alarmStatusCode.danger.label}`}
                                                    />
                                                ))}

                                            {location.overallStatus ===
                                            `${alarmStatusCode.warning.color}` ? (
                                                <img
                                                    src={warningIcon}
                                                    alt={`${alarmStatusCode.warning.label}`}
                                                />
                                            ) : (
                                                <div className="icon-missing-div" />
                                            )}
                                        </div>

                                        <div className="text-dropdown">
                                            Axlebox ID :{location.LocationName}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

RenderHistoryRow.propTypes = {
    overallStatus: propTypes.string,
    VehicleNumber: propTypes.string,
    onLocationClick: propTypes.func,
    functionalLocations: propTypes.array,
    selected: propTypes.array
};

RenderHistoryRow.defaultProps = {
    overallStatus: "",
    VehicleNumber: "",
    onLocationClick() {},
    functionalLocations: [],
    selected: []
};

export default RenderHistoryRow;
