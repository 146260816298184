import actionTypes from "../constants";

export const sortDashboardTable = data => {
    return {
        type: actionTypes.SORTING_DASHBOARD_TABLE,
        payload: data
    };
};

export const dropDownDashboard = text => {
    return {
        type: "DROP_DOWN_DASHBOARD",
        payload: text
    };
};

export const dropDownDashboardOpen = () => {
    return {
        type: "DROP_DOWN_DASHBOARD_OPEN"
    };
};
