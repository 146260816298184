import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import FleetFilter from "../../components/FleetFilter";
import "./style.scss";
import FleetTrain from "./FleetTrain";
// import Layout from "../../components/Layout";
import MapHistory from "../../components/MapHistory";
import StatusBar from "../../components/StatusBar";
import filterFleetTrains from "../../utils/vehicle/filterFleetTrains";
import { computeFleetStatusProps } from "../../utils/fleet/compute";
import { FLEET_FILTER_CONFIG, PAGE_NAME } from "../../utils/constants";

const Fleet = props => {
    const [trainsArray, updateTrainsArray] = useState(props.trainArray);
    const [statusObj, updateStatusObj] = useState({});
    useEffect(() => {
        const filteredTrains = filterFleetTrains(
            props.filteredTrainsArray,
            props.filterData,
            props.trainArray
        );
        updateTrainsArray(filteredTrains);
        const newStatusObj = computeFleetStatusProps(filteredTrains);
        updateStatusObj(newStatusObj);
    }, [props.filteredTrainsArray, props.updateFleetTrains, props.trainArray]);

    return (
        <Fragment>
            <StatusBar
              title="Fleet"
              overallCount={trainsArray.length}
              overallRedCount={statusObj.overallRedCount}
              overallAmberCount={statusObj.overallAmberCount}
              showTrainsStatus
            />
            <div className="outer">
                <div className="contain">
                    <FleetFilter showTrainName={false} />
                </div>
                <div className="lower-view">
                    <div className="train-view col-8">
                        <FleetTrain trainsArray={trainsArray} />
                    </div>
                    <div className="map-view ">
                        <MapHistory
                          heading="Train"
                          days="7"
                          allVehicle={props.vehicleDetails}
                          filterConfig={FLEET_FILTER_CONFIG}
                          appliedFilters={props.filterData}
                          pageName={PAGE_NAME.fleet}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        filteredTrainsArray: state.fleetData.vehiclesData.checked,
        trainArray: state.vehicleModel.vehicles,
        updateFleetTrains: state.fleetData.updateFleetTrains,
        filterData: state.fleetData.filterData,
        vehicleDetails: state.vehicleModel.vehicles
    };
};

export default connect(mapStateToProps)(Fleet);
