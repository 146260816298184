import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import vibration from "../../../../assets/images/vibration.png";
import sensor from "../../../../assets/images/sensor.png";
import temperature from "../../../../assets/images/temperature.png";
import alarmStatusCode from "../../../../utils/constants";

const TrainWagon = props => {
    const [wagonClass, updateWagonClass] = useState("");
    const [dangerClass, updateDangerClass] = useState("");
    useEffect(() => {
        if (
            props.item.overallStatus === alarmStatusCode.dangerTemperature.color
        ) {
            updateDangerClass("danger");
        } else if (props.item.overallStatus === alarmStatusCode.danger.color) {
            updateDangerClass("danger");
        } else if (props.item.overallStatus === alarmStatusCode.warning.color) {
            updateDangerClass("warning");
        } else {
            updateDangerClass("");
        }

        if (
            props.item.Position === props.maxLength &&
            props.item.Position !== 1
        ) {
            updateWagonClass("focusRightBorder");
        } else if (props.item.Position === 1) {
            updateWagonClass("focusLeftBorder");
        } else {
            updateWagonClass("wagon");
        }
    }, [props.item.Position, props.item.overallStatus, props.maxLength]);

    return (
        <div data-tip={props.item.VehicleNumber}>
            <Link to={props.link}>
                <div className={`wagon-container ${wagonClass}`}>
                    <div className="upper-wagon">
                        <p className="key-for-wagon">{props.item.Position}</p>
                    </div>
                    <div className="wagon-tools">
                        <div className="status-div">
                            {props.item.overallStatus ===
                            alarmStatusCode.dangerTemperature.color
                                ? alarmStatusCode.dangerTemperature.value
                                : props.item.overallStatus ===
                                  alarmStatusCode.danger.color
                                ? alarmStatusCode.danger.value
                                : props.item.overallStatus ===
                                  alarmStatusCode.warning.color
                                ? alarmStatusCode.warning.value
                                : " "}
                        </div>
                        <div className="tool-kit">
                            {props.item.sensorWarning ? (
                                <img src={sensor} alt="Sensor" />
                            ) : null}
                            {props.item.temperatureWarning ? (
                                <img src={temperature} alt="Temperature" />
                            ) : null}
                            {props.item.vibrationWarning ? (
                                <img src={vibration} alt="Vibration" />
                            ) : null}
                        </div>
                    </div>
                    <div className={`color-section ${dangerClass}-bg-color`} />
                </div>
            </Link>
        </div>
    );
};
export default TrainWagon;
