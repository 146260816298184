import { cloneDeep, filter } from "lodash";
import { eventProgressStatus } from "./constants";

// computes events at each level
const computeEvents = (reference, events, level) => {

    let eventsLinked = cloneDeep(events);
    eventsLinked = filter(eventsLinked, event => {
        if (event && event.metaData) {
            return (
                event.metaData[level] === reference &&
                event.metaData.eventProgress !== eventProgressStatus.closed
            );
        }
        return [];
    });
    return eventsLinked;
};
export default computeEvents;

// compute pointsList at wheelSet, tractionMotor and gearBox level
export const getPointList = (reference, pointsList) => {
    let localPoints = cloneDeep(pointsList);
    localPoints = filter(localPoints, point => {
        return point.ParentID.toString() === reference;
    });
    return localPoints;
};

// compute pointsList at axleBoxLevel
export const getAxlePointList = (axleSide, pointsList) => {
    let localPoints = cloneDeep(pointsList);
    localPoints = filter(localPoints, point => {
        return point.Name.split(" ")[1] === axleSide;
    });
    return localPoints;
};
