import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { connectRouter } from "connected-react-router";
import usersReducers from "./user.reducers";
import profileReducers from "./changePassword.reducers";
import fleetDataReducers from "./fleetData.reducers";
import vehicleReducers from "./vehicle.reducers";
import vehicleModelReducers from "./vehicleModel.reducers";
import historyUnitData from "./historyUnitData.reducers";
import dashboardTable from "./dashboardTable.reducer";
import dropDownDashboardReducer from "./sort.reducers";
import history from "../history";
import filter from "./filter.reducers";
import mapLocationData from "./map.reducers";
import showDropDownProfileReducer from "./dropDownProfile";
import historyReducer from "./historySensor.reducer";
import isProgressReducer from "./logout.reducer";
import toggleFeedbackReducer from "./feedbackModal.reducer";
import paginationReducer from "./pagination.reducer";
import categoryListReducer from "./categoryList.reducer";

export default combineReducers({
    user: usersReducers,
    profile: profileReducers,
    toastr: toastrReducer,
    fleetData: fleetDataReducers,
    router: connectRouter(history),
    vehicleData: vehicleReducers,
    vehicleModel: vehicleModelReducers,
    historyData: historyUnitData,
    dashboardTableRow: dashboardTable,
    dropDownDashboard: dropDownDashboardReducer,
    filterArray: filter,
    mapData: mapLocationData,
    showDropDownProfile: showDropDownProfileReducer,
    historyModel: historyReducer,
    isProgress: isProgressReducer,
    feedbackModal: toggleFeedbackReducer,
    pagination: paginationReducer,
    categoryList: categoryListReducer
});

// const appReducers = combineReducers({
//     user: usersReducers,
//     profile: profileReducers,
//     toastr: toastrReducer,
//     fleetData: fleetDataReducers,
//     router: connectRouter(history),
//     vehicleData: vehicleReducers,
//     vehicleModel: vehicleModelReducers,
//     historyData: historyUnitData,
//     dashboardTableRow: dashboardTable,
//     dropDownDashboard: dropDownDashboardReducer,
//     filterArray: filter,
//     mapData: mapLocationData,
//     showDropDownProfile: showDropDownProfileReducer,
//     historyModel: historyReducer,
//     isProgress: isProgressReducer,
//     feedbackModal: toggleFeedbackReducer,
//     pagination: paginationReducer,
//     categoryList: categoryListReducer
// });

// const rootReducer = (state, action) => {
//     if (action.type === actionTypes.LOGOUT) state = undefined;

//     return appReducers(state, action);
// };

// export default rootReducer;
