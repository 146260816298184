import React, { useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { fetchDocuments } from "../../store/actions/categoryList.action";
import "./style.scss";
import CategoryDropdown from "./CategoryDropdown/index";
import Spinner from "../Spinner/index";
import CategoryListTable from "./CategoryListTable/index";

const closeModal = onClose => {
    return (
        <button type="button" className="close" onClick={onClose}>
            &times;
        </button>
    );
};

const CategoryListModal = props => {
    useEffect(() => {
        props.fetchDocuments(props.vehicleData.SetID);
    }, []);

    return (
        <div className="modal">
            <Modal isOpen={props.isOpen} size="lg" toggle={props.onClose}>
                <ModalHeader
                    className="modal-header"
                    close={closeModal(props.onClose)}
                >
                    {props.heading}
                </ModalHeader>

                <ModalBody>
                    <div className="modal-body-content">
                        <div className="upper-section">
                            <div className="train-name">
                                <div className="train-key">Train : </div>
                                <div className="train-value">
                                    {props.vehicleData.Designation}
                                </div>
                            </div>
                            <CategoryDropdown />
                        </div>
                        {props.documents ? (
                            <CategoryListTable />
                        ) : (
                            <Spinner location="category-modal" />
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

CategoryListModal.propTypes = {
    isOpen: propTypes.bool,
    heading: propTypes.string,
    fetchDocuments: propTypes.func,
    vehicleData: propTypes.object,
    onClose: propTypes.func,
    documents: propTypes.array
};
CategoryListModal.defaultProps = {
    isOpen: false,
    heading: "List of Documents",
    fetchDocuments,
    vehicleData: {},
    onClose() {},
    documents: []
};

function mapStateTopProps(state) {
    return {
        category: state.categoryList.category,
        documents: state.categoryList.documents
    };
}
function mapDispatchToProps(dispatch) {
    return {
        fetchDocuments: trainId => dispatch(fetchDocuments(trainId))
    };
}

export default connect(mapStateTopProps, mapDispatchToProps)(CategoryListModal);
