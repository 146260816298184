import _ from "lodash";
import {
    findStatus,
    findAssetTypeAndSide,
    formPathName
} from "../utils/vehicle/event/findSideAndStatus";
import findEventVehicleReference from "../utils/vehicle/event/findVehicleReference";

import findFunctionalLocation from "../utils/vehicle/wheelset/findFunctionalLocation";
import { findEventProgress } from "../utils/vehicle/event/findProgress";
import { measurementTypes } from "../utils/constants";
import { getEventCaseId } from "../utils/reports/getEventCaseId";

const findWheelSetUniqueReferece = (
    wheelsetReference,
    vehicles,
    carriageReference,
    vehicleReference
) => {
    const wheelSetIndex = wheelsetReference.split(" ")[1];
    let wheelSetReference = "";
    vehicles.forEach(vehicle => {
        if (vehicle.Vehicle.Designation === vehicleReference) {
            const carriages = vehicle.Vehicle.Carriages;
            carriages.find(carriage => {
                if (carriage.VehicleNumber === carriageReference) {
                    wheelSetReference = carriage.Wheelsets[wheelSetIndex - 1];
                }
                return wheelSetReference;
            });
        }
    });
    return wheelSetReference;
};

export const createEventModel = (events, vehicles) => {
    const eventsModel = events.map(event => {
        const eventClone = _.cloneDeep(event);
        const eventProgress = findEventProgress(eventClone.status);
        const carriageReference = eventClone.attachedTo.path
            .split("\\")[2]
            .split(" ")[1];
        console.log("carriageReference", carriageReference);
        const number = eventClone.attachedTo.path.split("\\")[3].split(" ")[3];
        console.log("number", number);
        const wheelsetReference = `Wheelset ${number}`;
        const transmissionId = event.attachedTo.src.split("/")[3];
        const details = findEventVehicleReference(carriageReference, vehicles);
        const { vehicleReference } = details;
        const { traction } = details;
        const reports = _.get(eventClone, "reports", []);
        const assessmentObj = _.get(reports[0], "assesments[0]", {});
        const status = findStatus(_.get(assessmentObj, "classification", 0));
        const component = _.get(assessmentObj, "component", "");

        const types = findAssetTypeAndSide(component);
        const { assetType, side } = types;
        const wheelSetUniqueReference = findWheelSetUniqueReferece(
            wheelsetReference,
            vehicles,
            carriageReference,
            vehicleReference
        );
        const metaData = {
            pathName: formPathName(
                vehicleReference,
                carriageReference,
                wheelsetReference
            ),
            trainId: vehicleReference,
            carriageId: carriageReference,
            wheelsetId: wheelSetUniqueReference,
            assetId: findFunctionalLocation(
                vehicleReference,
                vehicles,
                side,
                assetType,
                wheelSetUniqueReference
            ),

            measurementType: measurementTypes.vibration,
            assetType,
            side,
            eventProgress
        };
        const computedReport = {
            recommendedAction: _.get(assessmentObj, "recommendation", ""),
            symptoms: "",
            diagnosis: _.get(assessmentObj, "assessment", ""),
            recommendation: _.get(assessmentObj, "recommendation", "")
        };
        eventClone.status = status;
        eventClone.metaData = metaData;
        eventClone.computedReport = computedReport;
        eventClone.type = "Event";
        eventClone.traction = traction;
        eventClone.feedBackSeverity = _.get(assessmentObj, "classification", 0);
        const obj = {
            caseNoPrefix: eventClone.caseNoPrefix,
            id: eventClone.id,
            reportsId: reports[0].id
        };

        eventClone.eventCaseId = getEventCaseId(obj);

        return eventClone;
    });
    return eventsModel;
};

export default createEventModel;
