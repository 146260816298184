import { takeEvery, all } from "redux-saga/effects";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import actionTypes from "../constants";
import { getPointList } from "../../services/vehicleService";

export function* fetchPointList(action) {
    try {
        const wheelsets = _.get(action, "payload", []);
        let responses = yield all(
            wheelsets.map(wheelset => {
                return getPointList(wheelset);
            })
        );

        responses = responses.filter(res => {
            return res.response.length !== 0;
        });

        responses = responses.map(element => {
            return element.response;
        });

        return responses;
    } catch (e) {
        toastr.error(
            `An error has ocurred, please try again.
            If this error persists contact SKF administrator for more information.`
        );

        return e;
    }
}

export default [takeEvery(actionTypes.POINT_LIST_REQUEST, fetchPointList)];
