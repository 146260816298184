import React, { PureComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PropTypes from "prop-types";
import ReportTable from "../Table";
import ReportsRow from "./ReportRow";
import findFunctionalLocationStatus from "../../utils/findFunctionalLocationStatus";
import "./style.scss";
import alarmStatusCode from "../../utils/constants";
import ReportBreadCrumb from "./ReportBreadCrumb.js";

const StatusBarContent = props => {
    const [status, findStatus] = useState("");
    useEffect(() => {
        const dummy = findFunctionalLocationStatus(
            props.pathName,
            props.assetId,
            props.vehicles
        );

        findStatus(dummy);
    }, [props.pathName, props.assetId]);

    return (
        <div>
            <div className="reports-status-container">
                <div
                    className={`${
                        status === alarmStatusCode.dangerTemperature.value
                            ? "report-side-danger-hightlighter"
                            : status === alarmStatusCode.danger.value
                            ? "report-side-danger-hightlighter"
                            : "report-side-amber-hightlighter"
                    } `}
                />
                <div className="status-bar-content">
                    <div className="heading">Functional Location</div>
                    <div className="value">{props.assetId}</div>
                </div>
                <div className="status-bar-content">
                    <div className="heading">Functional Location Status</div>
                    <div className="value">{status}</div>
                </div>
            </div>
        </div>
    );
};

const TableHeader = props => {
    const [status, findStatus] = useState("");
    useEffect(() => {
        const dummy = findFunctionalLocationStatus(
            props.pathName,
            props.assetId,
            props.vehicles
        );

        findStatus(dummy);
    }, [props.pathName, props.assetId]);

    return (
        <thead className="report-table-header">
            <tr>
                <th
                    className={`${
                        status === alarmStatusCode.dangerTemperature.value
                            ? "report-table-head-danger-side-highlighter"
                            : status === alarmStatusCode.danger.value
                            ? "report-table-head-danger-side-highlighter"
                            : "report-table-head-amber-side-highlighter"
                    } `}
                />
                {props.pageFrom === "fleet" && (
                    <th>
                        <div>Asset Id</div>
                    </th>
                )}
                <th className="table-cell-width">
                    <div>Asset</div>
                </th>
                <th className="table-cell-width">
                    <div>Measurement Type</div>
                </th>
                <th className="table-cell-width align-text">
                    <div> Action</div>
                </th>
                <th className="table-cell-width">
                    <div>Created On</div>
                </th>

                <th className="table-cell-width align-text">
                    <div>Event Status</div>
                </th>
                <th className="table-cell-width-recommendation">
                    <div>Recommended Actions</div>
                </th>

                <th className="table-cell-width">
                    <div />
                </th>
            </tr>
        </thead>
    );
};

class ReportContainer extends PureComponent {
    render() {
        const reportsTableData = get(this.props, "reportsTableData", {});

        return (
            <div>
                {Object.keys(reportsTableData).map(assetId => {
                    const pathName = get(
                        this.props.reportsTableData[assetId],
                        "[0].metaData.pathName",
                        ""
                    );
                    return (
                        <div className="report-container">
                            <div className="report-body-status-bar">
                                <div className="report-status-bar">
                                    <div className="status-header">
                                        <StatusBarContent
                                            assetId={assetId}
                                            pathName={pathName}
                                            vehicles={get(
                                                this.props,
                                                "vehicles",
                                                []
                                            )}
                                        />
                                    </div>

                                    <ReportBreadCrumb pathName={pathName} />
                                </div>
                            </div>
                            <div className="report-body-table">
                                <ReportTable>
                                    <TableHeader
                                        pageFrom={this.props.pageFrom}
                                        assetId={assetId}
                                        pathName={pathName}
                                        vehicles={get(
                                            this.props,
                                            "vehicles",
                                            []
                                        )}
                                    />

                                    <ReportsRow
                                        showFeedback={this.props.showFeedback}
                                        eventsArray={
                                            this.props.reportsTableData[assetId]
                                        }
                                        pageFrom={this.props.pageFrom}
                                    />
                                </ReportTable>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

ReportContainer.defaultProps = {
    reportsTableData: PropTypes.object
};

ReportContainer.propTypes = {
    reportsTableData: {}
};

StatusBarContent.defaultProps = {
    vehicles: [],
    pathName: "",
    assetId: ""
};

StatusBarContent.propTypes = {
    vehicles: PropTypes.array,
    pathName: PropTypes.string,
    assetId: PropTypes.string
};

function mapStateToProps(state) {
    return {
        vehicles: state.vehicleModel.vehicles,
        actionType: state.fleetData.filterData.assetStatus.value
    };
}

export default connect(mapStateToProps)(ReportContainer);
