import React from "react";
import AppRoutes from "../../routes";
import "./styles.scss";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function AppBootUp() {
  return (
    <div className="app-container">
      <AppRoutes />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    router: state.router ,
  }

}
export default withRouter(connect(mapStateToProps)(AppBootUp));
