import _ from "lodash";
import getTrains, { getTypeTrains } from "./getTrains";
import { isNilOrEmpty } from "../helper";
import { PAGE_NAME } from "../constants";

const filterFleetTrains = (label, filterData, vehicles) => {
    let filterActionMeasurement;
    const dropDownTreeVehicles = getTrains(label, vehicles);

    if (
        filterData.assetStatus.value.length === 0 &&
        filterData.measurementType.value.length === 0
    ) {
        filterActionMeasurement = [];
        return [...new Set(dropDownTreeVehicles)];
    }
    filterActionMeasurement = getTypeTrains(filterData, dropDownTreeVehicles);

    return [...new Set(filterActionMeasurement)];
};

const filterTrainByFleetFilter = (
    trainArr,
    selectedTrainsArr = { value: [], key: "" }
) => {
    if (selectedTrainsArr && isNilOrEmpty(selectedTrainsArr.value)) {
        return trainArr;
    }

    const res = _.intersectionBy(
        trainArr,
        selectedTrainsArr.value,
        "Designation"
    );
    return res;
};

const isFleetMatches = (selectedTrainHierarchy, trainObj) => {
    return selectedTrainHierarchy.label === trainObj.Traction;
};
const isTrainNameMatches = (selectedTrainHierarchy, trainName) => {
    return selectedTrainHierarchy.value === trainName;
};
const isCarriageMatches = (carriageId, carriageArr) => {
    if (isNilOrEmpty(carriageId) || isNilOrEmpty(carriageArr)) {
        return true;
    }

    return !!_.find(carriageArr, carriageObj => {
        return carriageObj.VehicleNumber === carriageId;
    });
};

const isFindAndMatchCarriage = (selectedTrainHierarchy, trainObj) => {
    if (isNilOrEmpty(selectedTrainHierarchy.value)) {
        return true;
    }
    const splitValue = selectedTrainHierarchy.value.split("/");
    const trainName = splitValue[0];
    const carriageId = splitValue[1];
    return !!(
        isTrainNameMatches({ value: trainName }, trainObj.Designation) &&
        isCarriageMatches(carriageId, trainObj.carriages)
    );
};

const filterTrainByNestedFleetFilter = (
    trainArr,
    selectedTrainsArr = { value: [], key: "" }
) => {
    if (selectedTrainsArr && isNilOrEmpty(selectedTrainsArr.value)) {
        return trainArr;
    }

    const filterFn = trainObj => {
        const foundTrainHierarchy = _.filter(
            selectedTrainsArr.value,
            selectedTrainHierarchy => {
                if (
                    selectedTrainHierarchy._depth === 0 &&
                    isFleetMatches(selectedTrainHierarchy, trainObj)
                ) {
                    return true;
                }

                if (
                    selectedTrainHierarchy._depth === 1 &&
                    isTrainNameMatches(
                        selectedTrainHierarchy,
                        trainObj.Designation
                    )
                ) {
                    return true;
                }

                if (
                    selectedTrainHierarchy._depth === 2 &&
                    isFindAndMatchCarriage(selectedTrainHierarchy, trainObj)
                ) {
                    return true;
                }

                return false;
            }
        );
        return !!foundTrainHierarchy.length;
    };

    const res = _.filter(trainArr, filterFn);

    return res;
};

const implementFleetFilter = (
    trainArr,
    appliedFilters,
    filterConfig = {},
    pageName = ""
) => {
    let filteredTrainArr = trainArr;
    if (filterConfig && filterConfig.fleet) {
        if (
            pageName === PAGE_NAME.dashboard ||
            pageName === PAGE_NAME.trainDetails
        ) {
            filteredTrainArr = filterTrainByFleetFilter(
                filteredTrainArr,
                appliedFilters.vehiclesData
            );
        }
        if (pageName === PAGE_NAME.fleet) {
            filteredTrainArr = filterTrainByNestedFleetFilter(
                filteredTrainArr,
                appliedFilters.vehiclesData
            );
        }
    }
    return filteredTrainArr;
};

const isAllFilterSelected = selectedFilter => {
    if (
        selectedFilter &&
        selectedFilter[0] &&
        selectedFilter[0].label === "All"
    ) {
        return true;
    }
    return false;
};

const isOverAllStatusMatch = (obj, status, comparatorKey) => {
    if (_.isArray(status)) {
        return !!_.find(status, statusObj => {
            if (_.isFunction(comparatorKey)) {
                return comparatorKey(obj, statusObj);
            }
            return statusObj.value === _.get(obj, comparatorKey, "");
        });
    }

    if (obj) {
        if (_.isFunction(comparatorKey)) {
            return comparatorKey(obj, status);
        }
        return _.get(obj, comparatorKey, "") === status;
    }
    return false;
};

const isCarriageStatusMatches = (carriageArr, status, path) => {
    const matchedCarriageArr = _.filter(carriageArr, carriageObj =>
        isOverAllStatusMatch(carriageObj, status, path)
    );
    return !!matchedCarriageArr.length;
};

const implementTrainStatusFilter = (
    trainArr,
    appliedFilters,
    filterConfig = {}
) => {
    let filteredTrainArr = trainArr;
    if (filterConfig && filterConfig.action) {
        const selectedStatusArr = _.get(
            appliedFilters,
            "assetStatus.value",
            []
        );
        const comparatorFn = (trainObj, statusObj) => {
            return trainObj.overallStatus === statusObj.value;
        };
        if (selectedStatusArr && isNilOrEmpty(selectedStatusArr)) {
            return filteredTrainArr;
        }
        if (isAllFilterSelected(selectedStatusArr)) {
            return filteredTrainArr;
        }

        filteredTrainArr = _.filter(trainArr, trainObj => {
            return (
                isOverAllStatusMatch(
                    trainObj,
                    selectedStatusArr,
                    comparatorFn
                ) ||
                isCarriageStatusMatches(
                    trainObj.carriages,
                    selectedStatusArr,
                    comparatorFn
                )
            );
        });
    }
    return filteredTrainArr;
};
const implementTrainMeasurementFilter = (
    trainArr,
    appliedFilters,
    filterConfig = {}
) => {
    let filteredTrainArr = trainArr;
    if (filterConfig && filterConfig.measurement) {
        const selectedStatusArr = _.get(
            appliedFilters,
            "measurementType.value",
            []
        );
        const comparatorFn = (trainObj, statusObj) => {
            return _.get(trainObj, statusObj.value, false);
        };
        if (selectedStatusArr && isNilOrEmpty(selectedStatusArr)) {
            return filteredTrainArr;
        }
        if (isAllFilterSelected(selectedStatusArr)) {
            return filteredTrainArr;
        }

        filteredTrainArr = _.filter(trainArr, trainObj => {
            return (
                isOverAllStatusMatch(
                    trainObj,
                    selectedStatusArr,
                    comparatorFn
                ) ||
                isCarriageStatusMatches(
                    trainObj.carriages,
                    selectedStatusArr,
                    comparatorFn
                )
            );
        });
    }
    return filteredTrainArr;
};

export const getFilteredTrainArr = (
    trainArr,
    appliedFilters,
    filterConfig = {},
    pageName = ""
) => {
    let filteredTrains = _.cloneDeep(trainArr);
    filteredTrains = implementFleetFilter(
        trainArr,
        appliedFilters,
        filterConfig,
        pageName
    );
    filteredTrains = implementTrainStatusFilter(
        filteredTrains,
        appliedFilters,
        filterConfig,
        pageName
    );
    filteredTrains = implementTrainMeasurementFilter(
        filteredTrains,
        appliedFilters,
        filterConfig,
        pageName
    );

    return filteredTrains;
};

export default filterFleetTrains;
