import _ from "lodash";
import { eventProgress } from "../../constants";

export const findEventProgress = status => {
    if (status === 1) {
        return eventProgress[1];
    }
    if (status === 2) {
        return eventProgress[2];
    }
    if (status === 0) {
        return eventProgress[0];
    }
    if (status === 3) {
        return eventProgress[3];
    }
};

export const findPointProgress = status => {
    if (_.includes(status, 1)) {
        return eventProgress[1];
    }
    if (_.includes(status, 0)) {
        return eventProgress[0];
    }
    if (_.includes(status, 2)) {
        return eventProgress[2];
    }
    if (_.includes(status, 3)) {
        return eventProgress[3];
    }
    if (_.includes(status, 12) ||
        _.includes(status, 7) ||
        _.includes(status, 8) ||
        _.includes(status, 9) ||
        _.includes(status, 10)) {
        return eventProgress[1];
    }
};
export default findEventProgress;
