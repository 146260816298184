import React from "react";
import propTypes from "prop-types";
import "./style.scss";
import TrainsDropdown from "../TrainsDropDown";

const TrainsTableHeading = props => {
    return (
        <div className="table-header-container row">
            <div className="table-heading col">Trains</div>

            <div className="table-dropdown col-auto d-flex align-items-base-line">
                <div className="col-6 text-right">Order by </div>
                <div>
                    <TrainsDropdown
                        updateActiveRoute={props.updateActiveRoute}
                    />
                </div>
            </div>
        </div>
    );
};
TrainsTableHeading.propTypes = {
    updateActiveRoute: propTypes.func
};
TrainsTableHeading.defaultProps = {
    updateActiveRoute() {}
};
export default TrainsTableHeading;
