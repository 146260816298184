import { axleBox } from "../constants";

export const calculateBearingValue = data => {
    let bearingValue = axleBox.bearingValue.noBearingValue;
    if (data !== undefined) {
        if (data.amberBearingCount === 0 && data.redBearingCount === 0) {
            bearingValue = axleBox.bearingValue.noBearingValue;
        } else if (data.amberBearingCount > data.redBearingCount) {
            bearingValue = axleBox.bearingValue.amberBearingValue;
        } else {
            bearingValue = axleBox.bearingValue.redBearingValue;
        }
        return bearingValue;
    }
    return bearingValue;
};

export const calculateWheelValue = data => {
    let wheelValue = axleBox.wheelValue.noWheelValue;
    if (data !== undefined) {
        if (data.amberWheelCount === 0 && data.redWheelCount === 0) {
            wheelValue = axleBox.wheelValue.noWheelValue;
        } else if (data.amberWheelCount > data.redWheelCount) {
            wheelValue = axleBox.wheelValue.amberWheelValue;
        } else {
            wheelValue = axleBox.wheelValue.redWheelValue;
        }
        return wheelValue;
    }
    return wheelValue;
};

export const calculateSensorValue = data => {
    let sensorValue = axleBox.sensorValue.noSensorValue;
    if (data !== undefined) {
        if (data.sensorCount === 0) {
            sensorValue = axleBox.sensorValue.noSensorValue;
        } else {
            sensorValue = axleBox.sensorValue.sensor;
        }
        return sensorValue;
    }
    return sensorValue;
};
