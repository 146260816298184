import actionTypes from "../constants";

const categoryListDropdown = data => {
    return {
        type: actionTypes.CATEGORY_LIST,
        payload: data
    };
};

export const showCategoryListModal = () => {
    return {
        type: actionTypes.SHOW_CATEGORY_LIST_MODAL
    };
};
export const setCategoryDropDown = item => {
    return {
        type: actionTypes.SET_CATEGORY_DROPDOWN,
        payload: item
    };
};

export const fetchDocuments = trainId => {
    return {
        type: actionTypes.DOCUMENTS_REQUEST,
        payload: trainId
    };
};
export const saveDocuments = trainDocuments => {
    return {
        type: actionTypes.DOCUMENTS_SUCCESS,
        payload: trainDocuments
    };
};
export const failureDocuments = () => {
    return {
        type: actionTypes.DOCUMENTS_FAILURE
    };
};
export const downloadCategoryFile = data => {
    return {
        type: actionTypes.DOWNLOAD_FILE_REQUEST,
        payload: data
    };
};
export default categoryListDropdown;
