export const findDisplayArray = (
    activeKey,
    bandwidth,
    noOfPages,
    activeArrayLength
) => {
    const displayArray = [];
    if (activeKey >= 1 && activeKey <= bandwidth) {
        for (let i = 0; i < activeArrayLength - 2; i += 1) {
            displayArray.push(i + 1);
        }
        displayArray.push("...");
        displayArray.push(noOfPages);
    } else if (activeKey > bandwidth && activeKey <= noOfPages - bandwidth) {
        displayArray.push(1);
        displayArray.push("...");
        const lowerLimit = Math.floor(activeKey - bandwidth / 2);
        const upperLimit = Math.floor(activeKey + bandwidth / 2);
        for (let i = lowerLimit; i <= upperLimit; i += 1) {
            displayArray.push(i);
        }
        displayArray.push("...");
        displayArray.push(noOfPages);
    } else if (activeKey > noOfPages - bandwidth && activeKey <= noOfPages) {
        displayArray.push(1);
        displayArray.push("...");
        for (
            let i = noOfPages - activeArrayLength + 3;
            i <= noOfPages;
            i += 1
        ) {
            displayArray.push(i);
        }
    }

    return displayArray;
};

export const computeDisplayArray = (
    activeKey,
    activeArrayLength,
    noOfPages
) => {
    let displayArray = [];

    if (noOfPages < activeArrayLength) {
        for (let i = 0; i < noOfPages; i += 1) {
            displayArray.push(i + 1);
        }
        return displayArray;
    }
    const middleLength = activeArrayLength - 4;
    const bandwidth = middleLength - 1;
    displayArray = findDisplayArray(
        activeKey,
        bandwidth,
        noOfPages,
        activeArrayLength
    );
    return displayArray;
};

export const computeInitialDisplayArray = (activeArrayLength, noOfPages) => {
    const displayArray = [];
    if (noOfPages < activeArrayLength) {
        for (let i = 0; i < noOfPages; i += 1) {
            displayArray.push(i + 1);
        }
        return displayArray;
    }
    for (let i = 0; i < activeArrayLength - 2; i += 1) {
        displayArray.push(i + 1);
    }
    displayArray.push("...");
    displayArray.push(noOfPages);
    return displayArray;
};

export const paginationConstants = {
    itemsPerPage: 10,
    displayLimit: 7,
    paginationLeft: "left",
    paginationRight: "right",
    paginationActive: "active"
};
