import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";

import StatusBar from "../../components/StatusBar";
import HistoryContainer from "../../components/HistoryContainer";

import "./styles.scss";

const History = props => {
    useEffect(() => {}, []);
    return (
        <Fragment>
            <StatusBar title="History" />
            <div className="history-body">
                <div className="history-body-container">
                    <div>
                        <HistoryContainer />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => {
    return { vehicles: state.vehicleModel.vehicles };
};

export default connect(mapStateToProps)(History);
