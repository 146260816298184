import { axleBox, bogieConstants } from "../constants";

export const calculateGear = (data, routeName, status) => {
    let gearValue = axleBox.gearValue.noGearBoxValue;
    if (status === bogieConstants.bogieNonPoweredStatus) {
        gearValue = axleBox.gearValue.absentGearValue;
        return gearValue;
    }
    if (data !== undefined) {
        if (data.amberGearboxCount === 0 && data.redGearboxCount === 0) {
            gearValue = axleBox.gearValue.noGearBoxValue;
        } else if (data.amberGearboxCount > data.redGearboxCount) {
            gearValue = axleBox.gearValue.amberGearBoxValue;
        } else {
            gearValue = axleBox.gearValue.redGearBoxValue;
        }
        return gearValue;
    }
    return gearValue;
};

export const calculateTraction = (data, routeName, status) => {
    let tractionMotorValue = axleBox.tractionMotorValue.noTractionMotorValue;
    if (status === bogieConstants.bogieNonPoweredStatus) {
        tractionMotorValue =
            axleBox.tractionMotorValue.absentTractionMotorValue;
        return tractionMotorValue;
    }
    if (data !== undefined) {
        if (
            data.amberTractionMotorCount === 0 &&
            data.redTractionMotorCount === 0
        ) {
            tractionMotorValue =
                axleBox.tractionMotorValue.noTractionMotorValue;
        } else if (data.amberTractionMotorCount > data.redTractionMotorCount) {
            tractionMotorValue =
                axleBox.tractionMotorValue.amberTractionMotorValue;
        } else {
            tractionMotorValue =
                axleBox.tractionMotorValue.redTractionMotorValue;
        }
        return tractionMotorValue;
    }
    return tractionMotorValue;
};
