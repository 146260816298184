import React from "react";
import "./style.scss";

const Spinner = props => {
    return (
        <div>
            <div className={`spinner-${props.location}`} />
        </div>
    );
};

export default Spinner;
