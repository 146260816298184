import _ from "lodash";

const trainFilteredFilteredEvents = (reportEvents, filter) => {
    const trainTypeArray = [];
    const trainArray = [];
    const carriageArray = [];

    filter.checked.forEach(item => {
        if (item._depth === 0) {
            trainTypeArray.push(item);
        } else if (item._depth === 1) {
            trainArray.push(item);
        } else if (item._depth === 2) {
            carriageArray.push(item);
        }
    });
    let resAction1 = [];
    let resAction2 = [];
    let resAction3 = [];
    let resultArray = [];

    if (trainTypeArray && trainTypeArray.length !== 0) {
        const resAction = _.filter(reportEvents, event => {
            const isMatched = _.find(trainTypeArray, train => {
                if (train.label === event.traction) {
                    return true;
                }
                return false;
            });
            if (isMatched) {
                return event;
            }
            return false;
        });
        resAction1 = resAction;
    }

    if (trainArray && trainArray.length !== 0) {
        const resAction = _.filter(reportEvents, event => {
            const isMatched = _.find(trainArray, train => {
                if (train.value === event.metaData.trainId) {
                    return true;
                }
                return false;
            });
            if (isMatched) {
                return event;
            }
            return false;
        });
        resAction2 = resAction;
    }

    if (carriageArray && carriageArray.length !== 0) {
        const resAction = _.filter(reportEvents, event => {
            const isMatched = _.find(carriageArray, carriage => {
                if (carriage.label === event.metaData.carriageId) {
                    return true;
                }
                return false;
            });
            if (isMatched) {
                return event;
            }
            return false;
        });
        resAction3 = resAction;
    }

    resultArray = [...resAction1, ...resAction2, ...resAction3];

    if (_.isEmpty(resultArray) && _.isEmpty(filter.checked)) {
        return reportEvents;
    }
    if (_.isEmpty(resultArray) && !_.isEmpty(filter.checked)) {
        return resultArray;
    }
    return resultArray;
};
export default trainFilteredFilteredEvents;
