import { all } from "redux-saga/effects";
import { fromRenderProps } from "recompose";
import userSaga from "./user.saga";
import fetchRefreshToken from "./refreshToken.saga";
import fetchVehicle from "./vehicle.saga";
import dashboardSorts from "./sort.saga";
import calculateOverallAssetStatus from "./filteredOverallAssetStatus";
import getMapData from "./getMapData.saga";
import historyModelSaga from "./historyModel.saga";
import logoutSaga from "./logout.saga";
import postFeedbackData from "./feedbackModal.saga";
import { categoryListSaga } from "./categoryList.saga";
import getPreviousFeedbacks from "./previousFeedbacks.saga";
import updatePassword from "./changePassword.saga";
import fetchUploadedImages from "./fetchUploadedImages.saga";

export const tasks = [
    ...userSaga,
    ...fetchRefreshToken,
    ...fetchVehicle,
    ...dashboardSorts,
    ...calculateOverallAssetStatus,
    ...historyModelSaga,
    ...getMapData,
    ...logoutSaga,
    ...categoryListSaga,
    ...postFeedbackData,
    ...getPreviousFeedbacks,
    ...updatePassword,
    ...fetchUploadedImages
];

export default function* root() {
    yield all(tasks);
}
