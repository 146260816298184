import actionTypes from "../constants";

export const updateHistoryModel = data => {
    return {
        type: actionTypes.UPDATE_HISTORY_MODEL,
        payload: {
            data
        }
    };
};

export const fetchHistoryModel = data => {
    return {
        type: actionTypes.FETCH_HISTORY_MODEL,
        payload: data
    };
};
