import _ from "lodash";
import alarmStatusCode from "../utils/constants";

const tableModel = {
    id: "",
    trainName: "",
    Designation: "",
    action: "",
    timeStatus: ""
};

const findStatusCode = item => {
    switch (item) {
        case alarmStatusCode.danger.color: {
            return alarmStatusCode.danger.value;
        }
        case alarmStatusCode.dangerTemperature.color: {
            return alarmStatusCode.danger.value;
        }
        case alarmStatusCode.warning.color: {
            return alarmStatusCode.warning.value;
        }
        default: {
            return alarmStatusCode.none.value;
        }
    }
};

const dashboardTableModel = normalisedVehicleData => {
    return normalisedVehicleData.map((item, index) => {
        const tableModelObj = _.clone(tableModel);
        tableModelObj.id = index + 1;
        tableModelObj.trainName = item.Designation;
        tableModelObj.Designation = item.Designation;

        tableModelObj.action = findStatusCode(item.overallStatus);
        // remove this later
        tableModelObj.mostRecentEvent =
            item.mostRecentEvent !== undefined ? item.mostRecentEvent : 0;
        tableModelObj.amberBearingCount = item.amberBearingCount;
        tableModelObj.redBearingCount = item.redBearingCount;
        tableModelObj.amberWheelCount = item.amberWheelCount;
        tableModelObj.redWheelCount = item.redWheelCount;
        tableModelObj.overallStatus = item.overallStatus;
        tableModelObj.sensorCount = item.sensorCount;
        tableModelObj.amberGearboxCount = item.amberGearboxCount;
        tableModelObj.redGearboxCount = item.redGearboxCount;
        tableModelObj.amberTractionMotorCount = item.amberTractionMotorCount;
        tableModelObj.redTractionMotorCount = item.redTractionMotorCount;
        return tableModelObj;
    });
};

export default dashboardTableModel;
