import _ from "lodash";
import alarmStatusCode, { measurementTypes } from "../../constants";

const getPointsListStatus = (point, metaData) => {
    if (metaData.assetType === alarmStatusCode.none.value) {
        return alarmStatusCode.none.value;
    }
    if (metaData.measurementType === measurementTypes.temperature) {
        if (_.includes(point.Status, 9) || _.includes(point.Status, 10)) {
            return alarmStatusCode.dangerTemperature.color;
        } else {
          if (_.includes(point.Status, 7) || _.includes(point.Status, 8)) {
              return alarmStatusCode.warning.color;
          }
        }
    } else {
        if (_.includes(point.Status, 9) || _.includes(point.Status, 10)) {
            return alarmStatusCode.danger.color;
        } else {
          if (_.includes(point.Status, 7) || _.includes(point.Status, 8)) {
              return alarmStatusCode.warning.color;
          }
        }
        if (_.includes(point.Status, 12)) {
            return alarmStatusCode.warning.color;
        }
    }

    return alarmStatusCode.none.value;
};

export const getAlarmSeverity = status => {
    if (
        status === alarmStatusCode.dangerTemperature.color ||
        status === alarmStatusCode.danger.color
    ) {
        return 10;
    }
    if (status === alarmStatusCode.warning.color) {
        return 7;
    }
    return 0;
};
export default getPointsListStatus;
