import actionTypes from "../constants";

export const getEvents = () => {
    return {
        type: actionTypes.EVENTS_REQUEST
    };
};

export const eventsSuccess = data => {
    return {
        type: actionTypes.EVENTS_SUCCESS,
        payload: data
    };
};

export const eventsFailure = () => {
    return {
        type: actionTypes.EVENTS_FAILURE
    };
};

export const finalModeledNormalised = events => {
    return {
        type: actionTypes.NORMALISED_MODELED_EVENTS,
        payload: events
    };
};
