import * as ApiService from "./apiService";

// To fetch vehicleReferences
export const getVehicleData = () => {
    const apiObject = {
        method: "POST",
        authentication: true,
        body: "",
        endPoint: ApiService.apiFleetUrl + "/api/IMx/Fleet"
    };
    return ApiService.callApi(apiObject);
};

export const fetchEvents = () => {
    const apiObject = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiPhoenixUrl + "/v1/EventCases"
        //endPoint: "/v1/EventCase/EventCases"
    };
    return ApiService.callApi(apiObject);
};

export const getPointList = wheelsetID => {
    const apiObject = {
        method: "GET",
        authentication: true,
        //endPoint: ApiService.apiPhoenixUrl + `/API/DataService/GetPointsList/?AssetID=${wheelsetID}&IncludeLastMeasurement=false&IncludeDiagnoses=false&IncludeAlarmInfo=false&IncludeOverallAlarm=false&IncludeFrequencies=false`
        endPoint: ApiService.apiPhoenixUrl + `/v1/machines/${wheelsetID}/points/`
    };
    return ApiService.callApiParallel(apiObject);
};
