import * as ApiService from "./apiService";

async function postUser(data) {
    const apiObject = {
        method: "POST",
        body: data,
        endPoint: ApiService.apiPhoenixUrl + "/token",
        headers: {
            "content-type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
    };
    return ApiService.callApi(apiObject);
}

export async function callChangePassword(password, user) {
    const apiObject = {
        method: "POST",
        body: password,
        authentication: true,
        endPoint: ApiService.apiPhoenixUrl + `/v1/user/${user}/password/change`
    };
    return ApiService.callApi(apiObject);
}
export default postUser;
