import { cloneDeep } from "lodash";
import eventLevelconstant from "./utils/constants";
import { getOverallStatus } from "./utils/computeStatusCounts";
import { computeRedAmberEventCounts } from "./utils/computeWarningAndCounts";
import computeEvents, { getPointList } from "./utils/computeEventsAndPointList";

const modelTractionMotor = (tractionMotorObject, events, pointsList) => {
    const clonedTractionMotorObject = cloneDeep(tractionMotorObject);
    if (clonedTractionMotorObject.LocationName) {
        // computing events for tractionMotor
        const tractionMotorEvents = computeEvents(
            tractionMotorObject.LocationName,
            events,
            eventLevelconstant.assetId
        );
        clonedTractionMotorObject.events = tractionMotorEvents;

        // computing pointList for tractionMotor
        const tractionMotorPointList = getPointList(
            clonedTractionMotorObject.Reference,
            pointsList
        );
        clonedTractionMotorObject.pointList = tractionMotorPointList;

        // added amber and red counts for traction
        const countsObj = computeRedAmberEventCounts(tractionMotorEvents);
        clonedTractionMotorObject.redTractionMotorCount =
            countsObj.redTractionMotorCount;
        clonedTractionMotorObject.amberTractionMotorCount =
            countsObj.amberTractionMotorCount;

        // updating gaerBoxes with overallStatus
        clonedTractionMotorObject.overallStatus = getOverallStatus(
            tractionMotorEvents
        );
    }
    return clonedTractionMotorObject;
};
export default modelTractionMotor;
