import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import propTypes from "prop-types";
import CustomBreadCrumb from "./CustomBreadCrumb";
import "./style.scss";
import StatusBar from "../../components/StatusBar";
import MapHistory from "../../components/MapHistory";
import TabView from "./TabView";
import CustomDropdown from "../../components/CustomDropdown";
import CategoryListFolder from "../../assets/images/categoryList.svg";
import { saveTrainName } from "../../store/actions/filterData.action";
import TrainTopView from "../../components/TrainTopView";
import { computeStatusTitle } from "../../utils/fleet/compute";
import computeRouteData from "../../utils/fleet/routeData";
import { showCategoryListModal } from "../../store/actions/categoryList.action";
import { TRAIN_DETAIL_FILTER_CONFIG, PAGE_NAME } from "../../utils/constants";
import CategoryListModal from "../../components/CategoryListModal";

const VehicleDetail = props => {
    const [eventDetails, updateEventDetails] = useState([]);
    const [statusDetails, updateStatusDetails] = useState({});
    const [routeData, updateRouteData] = useState({});
    const [statusTitle, updateStatusTitle] = useState("");
    useEffect(() => {
        const data = computeRouteData(props.match.params);
        const status = data.computeStatus(props.trainData);
        const event = data.getEvents(props.trainEvents);
        updateEventDetails(event);
        updateStatusDetails(status);
        updateRouteData(data);
        const newStatusTitle = computeStatusTitle(data.routeName);
        updateStatusTitle(newStatusTitle);
    }, [props.match.params, props.trainData.Designation]);

    return (
        <Fragment>
            <StatusBar
                title={statusTitle}
                showTrainsStatus
                overallCount={"1"}
                overallAmberCount={statusDetails.overallAmberCount}
                overallRedCount={statusDetails.overallRedCount}
            />

            <div className="parent-vehicle">
                <div className="upper-section">
                    <CustomBreadCrumb vehicleDetails={props.vehicleDetails} />
                    <div className="side-section">
                        <div className="dropdown-section">
                            <p className="dropdown-text">
                                {props.dropdownType}
                            </p>
                            <CustomDropdown
                                menu={props.menu}
                                value={props.match.params.id}
                            />
                        </div>
                        <div
                            className="category-list-container"
                            onClick={() => props.showCategoryListModal()}
                        >
                            <div className="category-list-text">
                                {props.categoryListText}
                            </div>
                            <img
                                src={CategoryListFolder}
                                alt="folder"
                                className="category-list-folder"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="train-top-view-text">Train Top View</div>
                    <div className="train-top-view">
                        <TrainTopView
                            route={routeData.routeName}
                            visibleTrain={props.trainData}
                            length={
                                props.trainData.carriages &&
                                props.trainData.carriages.length
                            }
                        />
                    </div>
                </div>

                <div className="row vehicle-detail-lower-div">
                    <div className="col-lg-8">
                        <div className="tab-view">
                            <TabView
                                currentTrain={props.trainData}
                                amberBearingCount={statusDetails.amberBearingCount}
                                amberWheelCount={statusDetails.amberWheelCount}
                                redBearingCount={statusDetails.redBearingCount}
                                redWheelCount={statusDetails.redWheelCount}
                                sensorCount={statusDetails.sensorCount}
                                amberTractionMotorCount={statusDetails.amberTractionMotorCount}
                                redTractionMotorCount={statusDetails.redTractionMotorCount}
                                amberGearboxCount={statusDetails.amberGearboxCount}
                                redGearboxCount={statusDetails.redGearboxCount}
                                vehicleDetails={props.vehicleDetails}
                                eventDetails={eventDetails}
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <MapHistory
                            allVehicle={props.vehicleDetails}
                            filterConfig={TRAIN_DETAIL_FILTER_CONFIG}
                            appliedFilters={{
                                vehiclesData: {
                                    value: props.trainData
                                        ? [props.trainData]
                                        : [],
                                    key: "vehicles"
                                }
                            }}
                            pageName={PAGE_NAME.trainDetails}
                        />
                    </div>
                </div>
            </div>
            {props.showCategoryModal ? (
                <CategoryListModal
                    isOpen={props.showCategoryModal}
                    onClose={() => {
                        props.showCategoryListModal();
                    }}
                    vehicleData={props.trainData}
                />
            ) : null}
        </Fragment>
    );
};
VehicleDetail.propTypes = {
    menu: propTypes.array,
    match: propTypes.object,
    vehicleDetails: propTypes.array,
    dropdownType: propTypes.string,
    categoryListText: propTypes.string,
    showCategoryListModal: propTypes.func,
    trainData: propTypes.object,
    trainEvents: propTypes.array,
    showCategoryModal: propTypes.bool
};
VehicleDetail.defaultProps = {
    dropdownType: "Train",
    categoryListText: "Train’s digital Folder",
    menu: [],
    match: {},
    vehicleDetails: [],
    showCategoryListModal,
    trainData: {},
    trainEvents: [],
    showCategoryModal: false
};
function mapDispatchToProps(dispatch) {
    return {
        saveTrainName: value => {
            dispatch(saveTrainName(value));
        },
        showCategoryListModal: () => {
            dispatch(showCategoryListModal());
        }
    };
}

function mapStateToProps(state, props) {
    let trainData = _.get(state, "vehicleModel.vehicles", []).find(
        vehicle => vehicle.Designation === props.match.params.id
    );
    if (!trainData) trainData = {};

    const eventsData = _.get(state, "vehicleModel.modeledEvents", []).find(
        element => element[props.match.params.id]
    );
    let trainEvents = [];
    if (eventsData) {
        trainEvents = eventsData[props.match.params.id];
    }

    return {
        currentTrain: state.fleetData.currentTrain,
        vehicleDetails: state.vehicleModel.vehicles,
        trainData,
        trainEvents,
        menu: state.vehicleModel.vehicles.map(vehicle => vehicle.Designation),
        showCategoryModal: state.categoryList.showModal
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VehicleDetail));
