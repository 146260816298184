import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import "./style.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { saveTrainName } from "../../store/actions/filterData.action";

class CustomDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleClick(item) {
        this.props.saveTrainName(item);
    }

    render() {
        return (
            <div className="parent-custom-dropdown">
                <Dropdown
                    className="dropdown"
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                >
                    <DropdownToggle className="dropdown-toggle" caret>
                        {this.props.value}
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu">
                        {this.props.menu &&
                            this.props.menu.map(item => {
                                return (
                                    <Link
                                        to={`/train/${item}`}
                                        key={item}
                                        onClick={() => this.handleClick(item)}
                                    >
                                        <DropdownItem
                                            active={this.props.value === item}
                                        >
                                            {item}
                                        </DropdownItem>
                                    </Link>
                                );
                            })}
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        saveTrainName: value => dispatch(saveTrainName(value))
    };
}
function mapStateToProps(state) {
    return {
        currentTrain: state.fleetData.currentTrain
    };
}
CustomDropdown.propTypes = {
    saveTrainName: propTypes.func,
    value: propTypes.string,
    menu: propTypes.array
};
CustomDropdown.defaultProps = {
    saveTrainName,
    value: "",
    menu: []
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomDropdown);
