const getBogieUIC = element => {
    let c = 0;
    const array = [];
    const resultArray = [];
    element
        .split(/([()])/)
        .filter(Boolean)
        .forEach(e => {
            if (e === "(") {
                c += 1;
            } else if (e === ")") {
                c -= 1;
            } else if (c > 0) {
                array.push(`(${e})`);
            } else {
                array.push(e);
            }
        });
    let str = "";
    for (let i = 0; i < array.length - 1; i += 1) {
        str = `${array[i]}${array[i + 1]}`;
        resultArray.push(str);
    }
    return resultArray;
};
const getFormattedUIC = str => {
    const strArray = str.split("");
    const res = [];
    let k = 0;
    let i = 0;
    const [first, second] = strArray;
    res[0] = first;
    res[1] = second;
    for (i = 2, k = 2; i <= strArray.length - 3; i += 2, k += 3) {
        res[k] = "(";
        res[k + 1] = strArray[i];
        res[k + 2] = ")";
    }
    res[k] = strArray[strArray.length - 2];
    res[k + 1] = strArray[strArray.length - 1];
    return res.join("");
};
// const str = "Bo'Bo'+2'2'2'2'2'2'2'2'2'+Bo'Bo'";
// const str = "Bo'Bo'+2'(2)(2)(2)(2)(2)(2)(2)2'+Bo'Bo'";
// const str = "Bo'Bo'+2'(2)(2)(2)(2)(2)2'+Bo'Bo'";

const getUIC = UICStr => {
    const finalArray = [];
    const res = UICStr.split("+");
    res.forEach(ele => {
        if (ele.length <= 6) {
            finalArray.push(ele);
        } else if (ele.includes("(")) {
            const result = getBogieUIC(ele);
            finalArray.push(result);
        } else {
            let result = getFormattedUIC(ele);
            result = getBogieUIC(result);
            finalArray.push(result);
        }
    });
    return finalArray.flat();
};

export default getUIC;
