import { fleetRoutes } from "../constants";

const computeRouteName = match => {
    let route = "";
    if (match.params.tractionMotorId) {
        route = fleetRoutes.tractionMotorRoute;
        return fleetRoutes.tractionMotorRoute;
    }
    if (match.params.gearBoxId) {
        route = fleetRoutes.gearBoxRoute;
        return fleetRoutes.gearBoxRoute;
    }
    if (match.params.assetId) {
        route = fleetRoutes.assetRoute;
        return fleetRoutes.assetRoute;
    }
    if (match.params.vehicleId) {
        route = fleetRoutes.vehicleRoute;
        return fleetRoutes.vehicleRoute;
    }
    if (match.params.id) {
        route = fleetRoutes.trainRoute;
        return fleetRoutes.trainRoute;
    }

    return route;
};
export default computeRouteName;
