import httpStatus from "http-status";
import * as ApiService from "./apiService";
import ReduxStore from "../store/index";
import actionTypes from "../store/constants";

export const getMapData = () => {
    const apiObject = {
        method: "GET",
        authentication: true,
        //endPoint: ApiService.apiPhoenixUrl + `/API/DataService/GetAssetList`
        endPoint: ApiService.apiPhoenixUrl + `/V2/assets`
    };
    return ApiService.callApi(apiObject);
};

export const getTrendingList = (id, startDate, endDate) => {
    const trendingApiObj = {
        method: "GET",
        authentication: true,
        // endPoint: `/API/DataService/GetTrendList?PointID=${id}`
        //endPoint: ApiService.apiPhoenixUrl + `/API/DataService/GetTrendList?PointID=${id}&NumReadings=200000&FromDateUTC=${startDate}&ToDateUTC=${endDate}`
        endPoint: ApiService.apiPhoenixUrl + `/v1/points/${id}/trendMeasurements/?NumReadings=200000&FromDateUTC=${startDate}&ToDateUTC=${endDate}`
    };
    return new Promise(async resolve => {
        try {
            const response = await ApiService.callApi(trendingApiObj);
            resolve(response);
        } catch (err) {
            if (err.responseStatus === httpStatus.UNAUTHORIZED) {
                ReduxStore.dispatch({
                    type: actionTypes.REFRESH_TOKEN_REQUEST,
                    payload: {
                        responseStatus: httpStatus.UNAUTHORIZED
                    }
                });
            }

            resolve([]);
        }
    });
};

export const getTrendingListMap = (id, startDate, endDate) => {
    const trendingApiObj = {
        method: "GET",
        authentication: true,
        //endPoint: ApiService.apiPhoenixUrl + `/API/DataService/GetTrendList?PointID=${id}&NumReadings=1500`
        endPoint: ApiService.apiPhoenixUrl + `/v1/points/${id}/trendMeasurements/?NumReadings=1500`
    };
    return new Promise(async resolve => {
        try {
            const response = await ApiService.callApi(trendingApiObj);
            resolve(response);
        } catch (err) {
            resolve([]);
        }
    });
};
