import React, { useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { setCategoryDropDown } from "../../../store/actions/categoryList.action";

const CategoryDropdown = props => {
    const [showDropdowm, setDropdown] = useState(false);
    const toggle = () => {
        setDropdown(!showDropdowm);
    };
    const handleDropdown = item => {
        props.setCategoryDropDown(item);
    };
    return (
        <div className="category-dropdown-container">
            <div className="category-dropdown-heading">Category</div>
            <div className="category-dropdown">
                <Dropdown isOpen={showDropdowm} toggle={toggle}>
                    <DropdownToggle caret>{props.category}</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => handleDropdown("Choose")}>
                            All
                        </DropdownItem>
                        {props.dropdownItems.map(item => (
                            <DropdownItem
                              onClick={() => handleDropdown(item.Category)}
                            >
                                {item.Category}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        setCategoryDropDown: item => dispatch(setCategoryDropDown(item))
    };
}
function mapStateTopProps(state) {
    return {
        dropdownItems:
            state.categoryList && state.categoryList.categoryListDropDown,
        category: state.categoryList && state.categoryList.category
    };
}

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(CategoryDropdown);
