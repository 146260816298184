import _ from "lodash";
import actionTypes from "../constants";

const responseData = {
    actionStatus: "Event Report Acknowledged",
    defect: "Bearing Defect",
    severity: "",
    // images: [],
    addCommentValue: ""
};

const modalData = {
    eventCaseId: "",
    assetType: "",
    pathName: "",
    position: ""
};

const initialState = {
    modalData: _.cloneDeep(modalData),
    responseData: _.cloneDeep(responseData),
    images: [],
    previousFeedbacks: [],
    showPreviousFeedbacks: false,
    showCommentError: false,
    feedbackSuccess: false,
    feedbackFailure: false,
    startSpinner: false,
    uploadedImages: [],
    uploadedImagesError: "",
    uploadedImagesSpinner: false
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SAVE_MODAL_DATA:
            return {
                ...state,
                modalData: payload
            };

        case actionTypes.FEEDBACK_DROPDOWN_SELECTED_VALUE:
            return {
                ...state,
                responseData: { ...state.responseData, ...payload }
            };

        case actionTypes.SAVE_COMMENTS:
            return {
                ...state,
                responseData: {
                    ...state.responseData,
                    addCommentValue: payload
                }
            };

        case actionTypes.SAVE_IMAGES:
            return {
                ...state,
                images: payload
                // responseData: {
                //     ...state.responseData,
                //     images: payload
                // }
            };

        case actionTypes.CLEAR_FEEDBACK_OBJ:
            return initialState;

        case actionTypes.FEEDBACK_PREV_FEEDBACKS_SUCCESS:
            return {
                ...state,
                previousFeedbacks: _.cloneDeep(payload)
            };
        case actionTypes.FEEDBACK_PREV_FEEDBACKS_FAILURE:
            return {
                ...state,
                previousFeedbacks: payload
            };

        case actionTypes.SHOW_PREVIOUS_FEEDBACKS:
            return {
                ...state,
                showPreviousFeedbacks: !state.showPreviousFeedbacks
            };
        case actionTypes.COMMENT_ERROR:
            return {
                ...state,
                showCommentError: payload
            };
        case actionTypes.SEND_FEEDBACK_DATA_REQUEST:
            return {
                ...state,
                startSpinner: !state.startSpinner
            };
        case actionTypes.SEND_FEEDBACK_DATA_SUCCESS:
            return {
                ...state,
                feedbackSuccess: !state.feedbackSuccess,
                startSpinner: !state.startSpinner
            };
        case actionTypes.SEND_FEEDBACK_DATA_FAILURE:
            return {
                ...state,
                feedbackFailure: !state.feedbackFailure
            };
        case actionTypes.FETCH_UPLOADED_IMAGES_REQUEST:
            return {
                ...state,
                uploadedImagesSpinner: true
            };
        case actionTypes.FETCH_UPLOADED_IMAGES_SUCCESS:
            return {
                ...state,
                uploadedImages: action.payload,
                uploadedImagesError: "",
                uploadedImagesSpinner: false
            };
        case actionTypes.FETCH_UPLOADED_IMAGES_FAILURE:
            return {
                ...state,
                uploadedImages: "",
                uploadedImagesError: action.payload.error,
                uploadedImagesSpinner: false
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
