import _ from "lodash";
import alarmStatusCode from "../constants";

const getTrains = (label, vehicles) => {
    if (label.length === 0) {
        return vehicles;
    }
    const result = [];
    let isMatched = {};
    label.forEach(element => {
        const vehicleMatch = _.filter(vehicles, vehicle => {
            if (
                element.label === vehicle.Designation ||
                element.label === vehicle.Traction
            ) {
                return vehicle;
            }
            isMatched = _.find(vehicle.carriages, carriage => {
                return carriage.VehicleNumber === element.label;
            });
            if (isMatched) {
                return vehicle;
            }
        });
        result.push(vehicleMatch);
    });
    return result.flat();
};

const findCarriage = (carriages, status) => {
    const found = _.find(carriages, carriage => {
        if (carriage.overallStatus === status) {
            return true;
        }
        return false;
    });
    return found;
};

export const getTypeTrains = (filterData, vehicles) => {
    let finalArray = [];
    let measurementArray = [];
    if (
        filterData.assetStatus.value[0] &&
        filterData.assetStatus.value[0].label === "All"
    ) {
        const allVehicles = _.filter(vehicles, vehicle => {
            if (
                vehicle.overallStatus ===
                    alarmStatusCode.dangerTemperature.color ||
                vehicle.overallStatus === alarmStatusCode.danger.color ||
                vehicle.overallStatus === alarmStatusCode.warning.color
            ) {
                return true;
            }
            return false;
        });
        finalArray.push(allVehicles);
    }
    if (filterData.assetStatus.value.length > 0) {
        const actionFinalArray = vehicles.filter(vehicle => {
            const isMatch = _.find(
                _.get(filterData, "assetStatus.value", []),
                filterValue => {
                    const isCarriageMatch = findCarriage(
                        vehicle.carriages,
                        filterValue.value
                    );
                    if (isCarriageMatch) {
                        return true;
                    }
                    return false;
                }
            );
            if (isMatch) {
                return true;
            }
            return false;
        });
        finalArray.push(actionFinalArray);
    }
    if (filterData.measurementType.value.length > 0) {
        let measurementVehicles = [];
        if (finalArray.length > 0) {
            measurementVehicles = finalArray.flat();
        } else {
            measurementVehicles = vehicles;
        }
        if (
            filterData.measurementType.value[0] &&
            filterData.measurementType.value[0].label === "All"
        ) {
            const allVehicles = _.filter(measurementVehicles, vehicle => {
                if (
                    vehicle.temperatureWarning === true ||
                    vehicle.sensorWarning === true ||
                    vehicle.vibrationWarning === true
                ) {
                    return vehicle;
                }
                return false;
            });
            finalArray = [...allVehicles];
            return [...new Set(finalArray.flat())];
        }
        measurementArray = _.filter(measurementVehicles, vehicleObj => {
            const isMatched = _.find(
                _.get(filterData, "measurementType.value", []),
                filterValue => {
                    const isCarriageFound = _.find(
                        vehicleObj.carriages,
                        carriage => {
                            if (
                                _.get(carriage, `${filterValue.value}`) === true
                            ) {
                                return true;
                            }
                            return false;
                        }
                    );
                    if (isCarriageFound) {
                        return true;
                    }
                    return false;
                }
            );
            if (isMatched) {
                return true;
            }
            return false;
        });
        finalArray = [...measurementArray];
    }
    return [...new Set(finalArray.flat())];
};
export default getTrains;
