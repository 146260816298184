import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import history from "./history";
import sagaMiddleware from "../middleware/sagaMiddleware";
import createRootReducer from "./reducer/index";
import watchUserFetch from "./saga/index";

const logger = createLogger({});

function configureStore() {
    const middlewares = [sagaMiddleware, logger, routerMiddleware(history)];

    const enhancer = applyMiddleware(...middlewares);
    const finalCreateStore = compose(enhancer)(createStore);
    return finalCreateStore(createRootReducer);
}

const Store = configureStore();

sagaMiddleware.run(watchUserFetch);

export default Store;
