import React, { useEffect, useState } from "react";
import "./style.scss";
import propTypes from "prop-types";
import { getImage } from "../../../utils/reports/getEventCaseId";

const UploadedImageDisplay = props => {
    const [filesToDisplay, updateFilesToDisplay] = useState([]);
    useEffect(() => {
        if (props.files) {
            const newFiles = props.files.map(file => {
                const imageLink = getImage(file.type, file);
                const downLoadLink = URL.createObjectURL(file);
                return { imageLink, downLoadLink };
            });
            updateFilesToDisplay(newFiles);
        }
    }, [props.files]);
    return (
        <div className="preview-images">
            {filesToDisplay.map(file => {
                return (
                    <div className="preview" key={file}>
                        <a
                            href={file.downLoadLink}
                            className="preview-link"
                            download
                        >
                            <img
                                src={file.imageLink}
                                alt="uploaded file"
                                className="preview-image"
                            />
                        </a>
                    </div>
                );
            })}
        </div>
    );
};
export default UploadedImageDisplay;
UploadedImageDisplay.propTypes = {
    files: propTypes.array
};

UploadedImageDisplay.defaultProps = {
    files: []
};
