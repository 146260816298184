import * as ApiService from "./apiService";

export const fetchDefectList = () => {
    const apiObject = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiFleetUrl + "/api/IMx/DefectCategories"
    };
    return ApiService.callApi(apiObject);
};

export const getPreviousFeedbackService = eventCaseId => {
    const apiObj = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiFleetUrl + `/api/IMx/FeedBack/eventCaseId/${eventCaseId}`
    };
    return ApiService.callApi(apiObj);
};
export const getPreviousFeedbackImagesService = (eventCaseId, fileName) => {
    const apiObj = {
        method: "GET",
        authentication: true,
        endPoint: ApiService.apiFleetUrl + `/api/IMx/FeedBackFile/eventCaseId/${eventCaseId}/fileName/${fileName}`
    };
    return ApiService.callApi(apiObj);
};

export const postFeedback = feedbackPayload => {
    const apiObject = {
        method: "POST",
        body: feedbackPayload,
        authentication: true,
        endPoint: ApiService.apiFleetUrl + "/api/IMx/FeedBack",
        headers: {
            "content-type": "application/json"
        }
    };

    return ApiService.callApi(apiObject);
};

export const postFeedbackImages = feedBackImages => {
    const apiObject = {
        method: "POST",
        body: feedBackImages,
        authentication: true,
        endPoint: ApiService.apiFleetUrl + "/api/IMx/FeedBackFile",
        removeHeader: true
    };
    return ApiService.callApi(apiObject);
};
