import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { saveImages } from "../../store/actions/modal.action";
import { UPLOAD_IMAGE_LIMIT } from "../../utils/constants";
import paperClip from "../../assets/images/paperClip.svg";
import "./style.scss";

const ImageUpload = props => {
    const handleChange = e => {
        const images = Array.from(e.target.files);

        if (images.length) {
            if (images.length <= UPLOAD_IMAGE_LIMIT) {
                if (props.imagesArr.length < UPLOAD_IMAGE_LIMIT) {
                    const reducersArrLen = props.imagesArr.length;
                    const currArrLen = images.length;
                    if (reducersArrLen + currArrLen <= UPLOAD_IMAGE_LIMIT) {
                        const fileObj = images.map(image => {
                            return image;
                        });
                        const arrOfImages = [...props.imagesArr, ...fileObj];
                        props.saveImages(arrOfImages);
                    } else {
                        alert(
                            `You can upload only ${UPLOAD_IMAGE_LIMIT -
                                reducersArrLen} image`
                        );
                    }
                } else {
                    alert("You cannot upload more images");
                }
            } else {
                alert("You can only upload 5 images");
            }
        }
    };

    return (
        <div className="upload-btn-wrapper">
            <button className="btn">
                <img src={paperClip} alt="attach" className="paper-clip" />
                <span className="button-text">Upload Image</span>
            </button>
            <input
                type="file"
                onChange={e => handleChange(e)}
                required
                multiple
            />
        </div>
    );
};

ImageUpload.propTypes = {
    saveImages: propTypes.func
};

ImageUpload.defaultProps = {
    saveImages: () => {}
};

function mapStateToProps(state) {
    return {
        imagesArr: state.feedbackModal.images
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveImages: data => dispatch(saveImages(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);

// src: URL.createObjectURL(image),
