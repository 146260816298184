import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

const NavItem = props => {
    return props.headerInfo.map(item => (
        <Link key={item.key} to={item.link}>
            <div
                key={item.key}
                className={
                    props.location.pathname === item.link ||
                    (item.link === "/fleet" &&
                        props.location.pathname.split("/")[1] === "train")
                        ? "active-list-item "
                        : "list-items"
                }
            >
                <img src={item.icon} alt={item.name} className="icon" />
                <p className="headings">{item.name}</p>
            </div>
        </Link>
    ));
};
export default NavItem;
