const syncActions = [
    // Login form
    "ERROR_EMAIL",
    "ERROR_PWD",
    "REMOVE_ERROR_EMAIL",
    "REMOVE_ERROR_PWD",

    // Confirm password form
    "SET_PASSWORD",
    "SET_CONFIRM_PWD",
    "SET_CURRENT_PWD",
    "CONFIRM_PWD_ERROR",
    "REMOVE_CONFIRM_PWD_ERROR",

    "SAVE_FILTER_DATA",
    "RESET_SAVE_FILTER_DATA",
    "SET_TOGGLE_FLAG",
    "SAVE_TIME_FILTER_DATA",

    "HISTORY_UNIT_DATA",

    "SAVE_CHECKED_DATA",
    "DASHBOARD_TABLE_DATA",

    // vehicle
    "VEHICLE_COUNT",
    "STORE_VEHICLE_REFRENCES",
    "STORE_VEHICLE_DETAILS",
    "STORE_VEHICLE_MODEL",
    "STORE_OVERALL_ASSET_STATUS",
    "STORE_OVERALL_TRAIN_STATUS",
    "STORE_TOTAL_VEHICLE_COUNT",
    "STORE_EVENTS_MODEL",
    "LOADING",

    // pointList
    "POINT_LIST_REQUEST",
    "GET_POINT_LIST",
    "LOADING",
    "UPDATE_HISTORY_MODEL",
    "FETCH_HISTORY_MODEL",

    "ADD_FILTER",
    "FILTER",
    "UPDATE_FILTER_ARRAY",
    "CLEAR_FILTER_ITEMS_ARRAY",
    "CHANGE_OVERALL_ASSET_STATUS",
    "SORTING_DASHBOARD_TABLE",
    "SORTED_DASHBOARD_TABLE",
    //  /fleet/vehicle
    "SAVE_TRAIN_NAME",
    "HISTORY_TABLE_DATA",
    "DROP_DOWN_DASHBOARD",
    "DROP_DOWN_DASHBOARD_OPEN",

    // for getting map data
    "GET_MAP_DATA",
    "GET_MAP_DATA_SUCCESS",
    "GET_MAP_DATA_FAILURE",
    "GET_MODELLED_MAP_DATA",
    // profile dropDown
    "DROP_DOWN_PROFILE",
    "NORMALISED_MODELED_EVENTS",
    "HISTORY_VEHICLE_FILTER_TREE",
    "UPDATE_FLEET_TRAINS",
    "LOGOUT",
    "IS_PROGRESS",
    "TOGGLE_ROUTE_MAP",
    "TOGGLE_LAST_POSITION_MARKER",
    "TOGGLE_MODAL",
    "SAVE_MODAL_DATA",
    "FEEDBACK_DROPDOWN_SELECTED_VALUE",
    "SAVE_COMMENTS",
    "SAVE_IMAGES",
    "GET_PREVIOUS_FEEDBACKS",
    "CLEAR_FEEDBACK_OBJ",
    "SEND_FEEDBACK_DATA",

    "COMMENT_ERROR",
    "SHOW_PREVIOUS_FEEDBACKS",
    "DEFECT_DROP_DOWN",
    "ADD_NO_OF_PAGES", // pagination
    "CHANGE_PAGINATION_ROUTE", // pagination
    "CATEGORY_LIST", // category list
    "SHOW_CATEGORY_LIST_MODAL",
    "SET_CATEGORY_DROPDOWN"
];
export default syncActions;
