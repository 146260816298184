import React from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { Provider } from "react-redux";
import "./assets/bootstrap/scss/bootstrap.scss";
import "react-redux-toastr/src/styles/index.scss";
import ReduxToastr from "react-redux-toastr";
import { ConnectedRouter } from "connected-react-router";
import App from "./containers/AppBootUp";
import * as serviceWorker from "./serviceWorker";
import Store from "./store";
import history from "./store/history";

function APP() {
    return (
        <Provider store={Store}>
            <ConnectedRouter history={history}>
                <App />
                <ReduxToastr
                  timeOut={4000}
                  newestOnTop={false}
                  preventDuplicates
                  position="top-left"
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  progressBar
                  closeOnToastrClick
                />
            </ConnectedRouter>
        </Provider>
    );
}

ReactDOM.render(<APP />, document.getElementById("root"));
serviceWorker.unregister();
