import { select, takeEvery, put } from "redux-saga/effects";
import getFilteredArray from "../selectors/filterArray";
import actionTypes from "../constants";
import {
    overallAssetStatus,
    overallTrainStatus,
    overallVehicleCount
} from "../actions/vehicleModel.action";
import geVehicleDetails from "../selectors/vehicle";
import { getTotalVehicleCount } from "../../utils/vehicle/count";
import getOverallTrainStatus from "../../utils/vehicle/status/overallTrainStatus";

export function* calculateOverallAssetStatus(action) {
    try {
        let amberBearing = 0;
        let redBearing = 0;
        let amberWheel = 0;
        let redWheel = 0;
        let sensorCount = 0;
        let redGearBoxes = 0;
        let amberGearBoxes = 0;
        let redTractionMotors = 0;
        let amberTractionMotors = 0;
        let array = [];
        const filteredVehicles = yield select(getFilteredArray);

        const vehicles = yield select(geVehicleDetails);
        array =
            filteredVehicles.filteredArray.length !== 0
                ? filteredVehicles.filteredArray
                : vehicles.vehicle;

        array.forEach(vehicle => {
            amberBearing += vehicle.amberBearingCount;
            redBearing += vehicle.redBearingCount;
            amberWheel += vehicle.amberWheelCount;
            redWheel += vehicle.redWheelCount;
            redGearBoxes += vehicle.redGearboxCount;
            amberGearBoxes += vehicle.amberGearboxCount;
            redTractionMotors += vehicle.redTractionMotorCount;
            amberTractionMotors += vehicle.amberTractionMotorCount;
            sensorCount += vehicle.sensorCount;
        });

        const overallAssetStatusData = {
            bearingsStatus: {
                redBearings: redBearing,
                amberBearings: amberBearing
            },
            wheelsStatus: {
                redWheels: redWheel,
                amberWheels: amberWheel
            },
            gearboxStatus: {
                redGearBoxes,
                amberGearBoxes
            },
            tractionMotorStatus: { redTractionMotors, amberTractionMotors },
            sensor: sensorCount.toString()
        };

        const dashboardStatus = getOverallTrainStatus(array);
        yield put(overallAssetStatus(overallAssetStatusData));

        yield put(overallTrainStatus(dashboardStatus));

        const count = getTotalVehicleCount(array);
        yield put(overallVehicleCount(count));
    } catch (e) {
        console.log(e);
    }
}

export default [
    takeEvery(
        actionTypes.CHANGE_OVERALL_ASSET_STATUS,
        calculateOverallAssetStatus
    )
];
