import pdfIcon from "../../assets/images/pdfIcon.png";
import fileIcon from "../../assets/images/fileIcon.png";
import audioIcon from "../../assets/images/audioIcon.svg";
import videoIcon from "../../assets/images/videoIcon.svg";
import documentIcon from "../../assets/images/document.png";

export const getEventCaseId = eventsData => {
    const { caseNoPrefix, id, reportsId } = eventsData;

    if (caseNoPrefix === "") {
        if (id != null && reportsId != null) {
            return `${id}-${reportsId}`;
        }
        return "";
    }
    return `${caseNoPrefix}-${id}-${reportsId}`;
};

export const getEventCaseIdForAlarms = obj => {
    const { date, status, measurementType, ID } = obj;

    return `${date}-${status}-${measurementType}-${ID}`;
};

export const getImage = (type, file) => {
    if (type.match("image/*")) {
        return URL.createObjectURL(file);
    }
    if (type.match("video/*")) {
        return videoIcon;
    }
    if (type.match("application/pdf")) {
        return pdfIcon;
    }
    if (type.match(".document")) {
        return documentIcon;
    }
    if (type.match("audio/*")) {
        return audioIcon;
    }
    return fileIcon;
};
