import actionTypes from "../constants";

export const startVehicle = () => {
    return {
        type: actionTypes.VEHICLE_REQUEST
    };
};

export const requestVehicleSuccess = vehicleReferences => {
    return {
        type: actionTypes.VEHICLE_SUCCESS,
        payload: vehicleReferences
    };
};

export const requestVehicleFailure = () => {
    return {
        type: actionTypes.VEHICLE_FAILURE
    };
};

export const setVehicleCount = count => {
    return {
        type: actionTypes.VEHICLE_COUNT,
        payload: count
    };
};
