import React, { useState, useEffect } from "react";
import { Link as NavigationLink } from "react-router-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import alarmStatusCode, {
    tableRowStatusConstants
} from "../../../utils/constants";
import "./style.scss";
import linkArrow from "../../../assets/images/linkArrow.svg";
import Button from "../../Button";
import {
    addFilter,
    filteredArray
} from "../../../store/actions/addFilter.action";
import calculateOverallAssetStatusRequest from "../../../store/actions/overallAssetStatus";

const computePaginatedData = (
    tableData,
    noOfPages,
    activeKey,
    itemsPerPage
) => {
    const pageNumbersArray = [];
    if (noOfPages && noOfPages !== 0) {
        const pageFirstKey = activeKey * itemsPerPage - (itemsPerPage - 1);
        const pageSecondKey = activeKey * itemsPerPage;

        for (let i = pageFirstKey; i <= pageSecondKey; i += 1) {
            if (tableData[i - 1]) {
                pageNumbersArray.push(tableData[i - 1]);
            }
        }
    }
    return pageNumbersArray;
};

const computeClassColor = action => {
    let classColor = `${tableRowStatusConstants.displayNormal}`;
    if (action === alarmStatusCode.danger.value) {
        classColor = `${tableRowStatusConstants.displayDanger}`;
    }
    if (action === alarmStatusCode.dangerTemperature.value) {
        classColor = `${tableRowStatusConstants.displayDanger}`;
    }
    if (action === alarmStatusCode.warning.value) {
        classColor = `${tableRowStatusConstants.displayWarning}`;
    }
    return classColor;
};

const TrainsTableRow = props => {
    const [pageNumbersArray, updatePageNumbersArray] = useState([]);
    useEffect(() => {
        props.addFilter(props.dashboardTableRow);
    }, []);

    useEffect(() => {
        const newPageNumberArray = computePaginatedData(
            props.dashboardTableRow,
            props.noOfPages,
            props.activeKey,
            props.itemsPerPage
        );
        updatePageNumbersArray(newPageNumberArray);
    }, [props.dashboardTableRow, props.activeKey, props.itemsPerPage]);

    const handleClick = (e, item) => {
        e.preventDefault();
        props.filteredArray(item);
        const parameter = {
            payload: item
        };
        props.calculateOverallAssetStatusRequest(parameter);
    };

    return pageNumbersArray.map(item => {
        const { filterByItems } = props;
        const filterAppliedFlag = filterByItems.includes(item);
        const trClasses = classNames({
            normalize: true,
            "table-row-trains": true,
            changeColor: filterAppliedFlag === true
        });
        const tableRowStatusClass = computeClassColor(item.action);
        return (
            <tr className={trClasses} key={item.trainName}>
                <td className={`no-border normalize ${tableRowStatusClass}`}>
                    <div className="side-highlighter" />
                </td>
                <td className="normalize text">
                    <div className="center table-font-style">
                        {item.trainName}
                    </div>
                </td>
                <td className="normalize">
                    <div>
                        {item.action === alarmStatusCode.danger.value ? (
                            <Button color="danger" text={item.action} />
                        ) : (
                            <div className="center table-font-style">
                                {item.action}
                            </div>
                        )}
                    </div>
                </td>

                <td className="normalize">
                    <div className="table-links">
                        <a
                            className="a-link"
                            href="/dashboard"
                            onClick={e => handleClick(e, item)}
                        >
                            Filter
                        </a>
                    </div>
                </td>
                <td className="normalize">
                    <div className="table-links">
                        <NavigationLink to={`train/${item.trainName}`} href="">
                            View Details &nbsp;&nbsp;
                            <img src={linkArrow} alt="right-arrow" />
                        </NavigationLink>
                    </div>
                </td>
            </tr>
        );
    });
};

const mapDispatchToProps = dispatch => {
    return {
        filteredArray: value => dispatch(filteredArray(value)),
        addFilter: value => dispatch(addFilter(value)),
        calculateOverallAssetStatusRequest: value =>
            dispatch(calculateOverallAssetStatusRequest(value))
    };
};
const mapStateToProps = state => {
    return {
        dashboardTableRow: state.dashboardTableRow.dashboardTable,
        arrayToFilter: state.filterArray.arrayToFilter,
        filterByItems: state.filterArray.filterByItems,
        noOfPages: state.pagination.noOfPages,
        itemsPerPage: state.pagination.itemsPerPage,
        activeKey: state.pagination.activeKey
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainsTableRow);
