import _ from "lodash";
import actionTypes from "../constants";

const initialState = {
    dashboardTable: []
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.DASHBOARD_TABLE_DATA:
            return {
                ...state,
                dashboardTable: _.cloneDeep(payload)
            };

        case actionTypes.SORTED_DASHBOARD_TABLE:
            return {
                ...state,
                dashboardTable: _.cloneDeep(payload)
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
