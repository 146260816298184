import _ from "lodash";
import actionTypes from "../constants";

const initialState = {
    isLoading: false,
    vehicleReferences: [],
    vehicle: [],
    events: []
};
export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.VEHICLE_SUCCESS:
            return {
                ...state,
                vehicleReferences: _.cloneDeep(payload)
            };
        case actionTypes.VEHICLE_DATA_SUCCESS:
            return {
                ...state,
                vehicle: _.cloneDeep(payload)
            };
        case actionTypes.VEHICLE_DATA_FAILURE:
            return {
                ...state,
                vehicle: [],
                vehicleReferences: [],
                isLoading: false
            };
        case actionTypes.EVENTS_SUCCESS:
            return {
                ...state,
                events: _.cloneDeep(payload)
            };
        case actionTypes.EVENTS_FAILURE:
            return {
                ...state,
                events: []
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: true
            };

        case actionTypes.LOGOUT:
            return initialState;

        default:
            return {
                ...state
            };
    }
}
