import dashboardIcon from "../../assets/images/dashboard.png";
import history from "../../assets/images/history.png";
import list from "../../assets/images/list.png";
import train from "../../assets/images/train.png";

const headerLinks = [
    {
        key: 1,
        name: "Dashboard",
        link: "/dashboard",
        icon: dashboardIcon
    },
    {
        key: 2,
        name: "Fleets",
        link: "/fleet",
        icon: train
    },
    {
        key: 3,
        name: "Reports",
        link: "/report",
        icon: list
    },
    {
        key: 4,
        name: "History",
        link: "/history",
        icon: history
    }
];

export default headerLinks;
