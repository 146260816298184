import React, { PureComponent } from "react";
import propTypes from "prop-types";
import NumberCount from "../NumberCount/index";
import "./style.scss";

export default class AssetCard extends PureComponent {
    render() {
        return (
            <div className="card-container col ml-1 mr-1 mt-4 mb-5">
                <div className="card-heading">{this.props.text}</div>
                {this.props.sensors || this.props.sensors === 0 ? (
                    <div className="card-body">
                        <div className="card-body-danger">
                            <NumberCount number={parseInt(this.props.sensors, 10)} warning />
                            <p className="card-body-danger-text">Plan</p>
                        </div>
                    </div>
                ) : (
                    <div className="card-body">
                        <div className="card-body-danger">
                            <NumberCount
                              number={this.props.danger}
                              numberLeft
                              danger
                            />
                            <p className="card-body-danger-text">Act</p>
                        </div>
                        <div className="center-div" />
                        <div className="card-body-warning">
                            <NumberCount
                              number={this.props.warnings}
                              numberRight
                              defaultComponent
                              warning
                            />
                            <p className="card-body-warning-text">Plan</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

AssetCard.propTypes = {
    text: propTypes.string,
    sensors: propTypes.string,
    danger: propTypes.number,
    warnings: propTypes.number
};

AssetCard.defaultProps = {
    text: "",
    sensors: "",
    danger: 0,
    warnings: 0
};
