import _ from "lodash";

const getRef = (vehicles, wheelsetRef) => {
    let carriageReference = "";
    let vehicleReference = "";
    let traction = "";
    vehicles.find(vehicle => {
        vehicle.Vehicle.Carriages.find(carriage => {
            carriage.Wheelsets.find(wheelsetObj => {
                if (wheelsetObj === wheelsetRef) {
                    carriageReference = carriage.VehicleNumber;
                    vehicleReference = vehicle.Vehicle.Designation;
                    traction = _.get(vehicle, "Vehicle.Traction", "");
                }
            });
        });
    });
    return {
        carriageReference,
        vehicleReference,
        traction
    };
};

const getWheelsetReference = (wheelsetId, vehicles, wheelsets) => {
    let wheelsetReference = "";
    let carriageRef = "";
    let vehicleRef = "";
    let tractionRef = "";

    let refs;

    wheelsets.forEach(wheelset => {
        if (wheelset.Reference === wheelsetId.toString()) {
            wheelsetReference = wheelset.Name;
            refs = getRef(vehicles, wheelset.Reference);

            carriageRef = refs.carriageReference;
            vehicleRef = refs.vehicleReference;
            tractionRef = refs.traction;
        }
        if (
            wheelset.GearBox &&
            wheelset.GearBox !== null &&
            wheelset.GearBox.Reference === wheelsetId.toString()
        ) {
            wheelsetReference = wheelset.Name;
            refs = getRef(vehicles, wheelset.Reference);
            carriageRef = refs.carriageReference;
            vehicleRef = refs.vehicleReference;
            tractionRef = refs.traction;
        }
        if (
            wheelset.TractionMotor &&
            wheelset.TractionMotor !== null &&
            wheelset.TractionMotor.Reference === wheelsetId.toString()
        ) {
            wheelsetReference = wheelset.Name;
            refs = getRef(vehicles, wheelset.Reference);
            carriageRef = refs.carriageReference;
            vehicleRef = refs.vehicleReference;
            tractionRef = refs.traction;
        }
    });
    return {
        wheelsetReference,
        carriageRef,
        vehicleRef,
        tractionRef
    };
};

export default getWheelsetReference;
