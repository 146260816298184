const fleetFilters = [
    {
        key: 1,
        name: "Action",
        filterName: "assetStatus",
        dropDownOptions: [
            { value: "All", label: "All" },
            { value: "Danger", label: "Act now" },
            { value: "Red", label: "Act" },
            { value: "Amber", label: "Plan" }
        ]
    },
    {
        key: 2,
        name: "Meas. Type",
        filterName: "measurementType",
        dropDownOptions: [
            { value: "All", label: "All" },
            { value: "temperatureWarning", label: "Temperature" },
            { value: "vibrationWarning", label: "Vibration" },
            { value: "sensorWarning", label: "Sensor" }
        ]
    }
];

export default fleetFilters;
