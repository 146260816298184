import actionTypes from "../constants";

export const startVehicleData = data => {
    return {
        type: actionTypes.VEHICLE_DATA_REQUEST,
        payload: data
    };
};

export const requestVehicleDataSuccess = data => {
    return {
        type: actionTypes.VEHICLE_DATA_SUCCESS,
        payload: data
    };
};

export const requestVehicleDataFailure = () => {
    return {
        type: actionTypes.VEHICLE_DATA_FAILURE
    };
};
