import { get } from "lodash";
import { assetCardConstants } from "../../utils/constants";

const assetObject = (text, status, dangerType, warningType, sensorType) => {
    const assetObj = {};
    assetObj.text = text;
    assetObj.dangerCount = get(status, dangerType, 0);
    assetObj.warningCount = get(status, warningType, 0);
    assetObj.sensorCount = get(status, sensorType, "");
    return assetObj;
};

const computeAssetCardData = (
    bearingStatus,
    wheelStatus,
    tractionMotorStatus,
    gearboxStatus,
    overallAssetStatus
) => {
    const assetArray = [];
    const bearingObj = assetObject(
        assetCardConstants.bearingText,
        bearingStatus,
        assetCardConstants.bearingDangerType,
        assetCardConstants.bearingWarningType,
        null
    );
    assetArray.push(bearingObj);
    const wheelObj = assetObject(
        assetCardConstants.wheelText,
        wheelStatus,
        assetCardConstants.wheelDangerType,
        assetCardConstants.wheelWarningType,
        null
    );
    assetArray.push(wheelObj);
    const tractionObj = assetObject(
        assetCardConstants.tractionText,
        tractionMotorStatus,
        assetCardConstants.tractionDangerType,
        assetCardConstants.tractionWarningType,
        null
    );
    assetArray.push(tractionObj);
    const gearObj = assetObject(
        assetCardConstants.gearText,
        gearboxStatus,
        assetCardConstants.gearDangerType,
        assetCardConstants.gearWarningType,
        null
    );
    assetArray.push(gearObj);
    const sensorObj = assetObject(
        assetCardConstants.sensorText,
        overallAssetStatus,
        null,
        null,
        assetCardConstants.sensorType
    );
    assetArray.push(sensorObj);
    return assetArray;
};
export default computeAssetCardData;
