import { takeLatest, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment";
import actionTypes from "../constants";
import { getTrendingList } from "../../services/mapService";
import { updateHistoryModel } from "../actions/historySensor.action";

const requiredField = [
    { name: "temperature", code: "VT" },
    { name: "temperature", code: "LT" },
    { name: "temperature", code: "RT" },
    { name: "temperature", code: "AT" },
    { name: "velocity", code: "VE3" },
    { name: "velocity", code: "LE3" },
    { name: "velocity", code: "RE3" },
    { name: "velocity", code: "AE3" },
    { name: "speed", code: "VS" },
    { name: "speed", code: "LS" },
    { name: "speed", code: "RS" },
    { name: "speed", code: "AS" }
];

// Get Temperature, Velocity and Speed Sensor Id's for 1 functionalLocation
function fetchRequiredPoints(assetBox, options) {
    const { pointList, overallStatus } = assetBox;
    const {
        VehicleNumber,
        Designation,
        startDate,
        endDate,
        temperatureWarning
    } = options;

    return new Promise((resolve, reject) => {
        const finalFields = [];
        const sensorData = {};
        if (pointList.length > 0) {
            pointList.forEach(point => {
                const clonedField = _.cloneDeep(requiredField);
                const isMatchedField = clonedField.filter(field => {
                    const res = point.Name.split(" ")[0].match(
                        `^..${field.code}$`
                    );
                    if (res) {
                        field.id = point.ID;
                        return field;
                    }
                });
                if (isMatchedField.length !== 0) {
                    finalFields.push(...isMatchedField);
                }
            });
            const promises = [];
            finalFields.forEach(field => {
                if (field.id) {
                    const promise = new Promise(resolve => {
                        getTrendingList(field.id, startDate, endDate)
                            .then(data => {
                                delete data.responseStatus;
                                const finalTrendingObject = data.reduce(
                                    (allReading, trendObj) => {
                                        allReading[
                                            moment(
                                                `${trendObj.ReadingTimeUTC}z`
                                            ).valueOf()
                                        ] = {
                                            xaxis: `${trendObj.ReadingTimeUTC}z`,
                                            yaxis:
                                                trendObj.Measurements[0].Level
                                        };
                                        return allReading;
                                    },
                                    {}
                                );
                                sensorData[field.name] = _.reverse(
                                    Object.values(finalTrendingObject)
                                );
                                resolve();
                            })
                            .catch(() => {
                                sensorData[field.name] = [];
                                resolve();
                            });
                    });
                    promises.push(promise);
                }
            });
            Promise.all(promises)
                .then(() => {
                    sensorData.LocationName = assetBox.LocationName;
                    sensorData.Designation = Designation || "";
                    sensorData.VehicleNumber = VehicleNumber || "";
                    sensorData.overallStatus = overallStatus || "";
                    sensorData.temperatureWarning = temperatureWarning || "";
                    resolve([sensorData]);
                })
                .catch(err => {
                    resolve([]);
                });
        } else {
            resolve([]);
        }
    });
}
function fetchFromAxleBox(axleBox, options) {
    return fetchRequiredPoints(axleBox, options);
}
function fetchFromWheelset(wheelSet, options) {
    const promises = [];
    wheelSet.axleBoxes.forEach(axleBox => {
        promises.push(fetchFromAxleBox(axleBox, options));
    });
    if (wheelSet.gearBox) {
        promises.push(fetchRequiredPoints(wheelSet.gearBox, options));
    }
    if (wheelSet.tractionMotor) {
        promises.push(fetchRequiredPoints(wheelSet.tractionMotor, options));
    }
    return Promise.all(promises);
}
function fetchFromCarriage(carriage, options) {
    const promises = [];
    carriage.wheelSets.forEach(wheelSet => {
        promises.push(
            fetchFromWheelset(wheelSet, {
                ...options,
                VehicleNumber: carriage.VehicleNumber,
                temperatureWarning: carriage.temperatureWarning
            })
        );
    });
    return Promise.all(promises).then(values => [...values]);
}
function fetchFromVehicle(vehicle, options) {
    const promises = [];
    vehicle.carriages.forEach(carriage => {
        promises.push(
            fetchFromCarriage(carriage, {
                ...options,
                Designation: vehicle.Designation
            })
        );
    });
    return Promise.all(promises).then(values => [...values]);
}
function* fetchPointList(action) {
    const { payload } = action;
    const { references, startDate, endDate } = payload;
    const promises = [];
    // return new Promise(()=>)

    references.forEach(data => {
        let promise;
        if (data.carriages) {
            promise = fetchFromVehicle(data, { startDate, endDate });
        } else if (data.wheelSets) {
            promise = fetchFromCarriage(data, { startDate, endDate });
        } else if (data.pointsList) {
            promise = fetchFromAxleBox(data, { startDate, endDate });
        } else {
            promise = Promise.resolve([]);
        }

        promises.push(promise);
    });

    let sensorData = yield Promise.all(promises);

    sensorData = _.flattenDeep(sensorData);

    yield put(updateHistoryModel(sensorData));
}

export default [takeLatest(actionTypes.FETCH_HISTORY_MODEL, fetchPointList)];
