const asyncActions = [
    "USER",
    "REFRESH_TOKEN",
    "VEHICLE",
    "VEHICLE_DATA",
    "EVENTS",
    "DOCUMENTS",
    "DOWNLOAD_FILE",
    "SEND_FEEDBACK_DATA",
    "FEEDBACK_PREV_FEEDBACKS",
    "CHANGE_PASSWORD",
    "FETCH_UPLOADED_IMAGES"
];
export default asyncActions;
