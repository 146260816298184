import { takeLatest, put, all } from "redux-saga/effects";
import actionTypes from "../constants";
import { getPreviousFeedbackImagesService } from "../../services/feedbackService";
import {
    fetchUploadedImagesSuccess,
    fetchUploadedImagesFailure
} from "../actions/modal.action";

function* getFeedbackImages(action) {
    const { payload } = action;
    try {
        const images = yield all(
            payload.files.map(file => {
                return getPreviousFeedbackImagesService(payload.id, file);
            })
        );
        yield put(fetchUploadedImagesSuccess(images));
    } catch (error) {
        yield put(fetchUploadedImagesFailure(error));
    }
}
export default [
    takeLatest(actionTypes.FETCH_UPLOADED_IMAGES_REQUEST, getFeedbackImages)
];
