import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import HistoryRow from "./HistoryRow";
import HistoryGraph from "../HistoryGraph/index";
import Filter from "../FleetFilter";
import { saveHistoryData } from "../../store/actions/historyTableData.actions";
import { fetchHistoryModel } from "../../store/actions/historySensor.action";

const HistoryTable = props => {
    return (
        <Table striped className="history-table-container">
            <thead>
                <tr>
                    <th>Functional Location</th>
                    <th>Temperature</th>
                    <th>Vibration</th>
                    <th>Speed</th>
                    {/* <th>GPS</th>
                    <th>TimeStamp</th> */}
                </tr>
            </thead>
            <tbody>
                {props.tableData.map(item => {
                    return (
                        <tr
                            key={item.LocationName}
                            className={`${item.selected ? "selected" : ""}`}
                        >
                            <td>{item.LocationName}</td>
                            <td>{item.temperature}</td>
                            <td>{item.velocity}</td>
                            <td>{item.speed}</td>
                            {/* <td>{item.GPS}</td>
                            <td>{item.timeStamp}</td> */}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

function createDataFromCarriage(carriage, filter = "") {
    const data = {};
    data.VehicleNumber = carriage.VehicleNumber;
    data.functionalLocations = [];
    data.overallStatus = carriage.overallStatus;
    carriage.wheelSets.forEach(wheelSet => {
        wheelSet.axleBoxes.forEach(axleBox => {
            if (
                !filter ||
                (!!filter && filter.includes(axleBox.overallStatus))
            ) {
                data.functionalLocations.push({
                    LocationName: axleBox.LocationName,
                    overallStatus: axleBox.overallStatus,
                    selected: false
                });
            }
        });
        if (
            (!filter ||
                (!!filter &&
                    filter.includes(
                        wheelSet.gearBox && wheelSet.gearBox.overallStatus
                    ))) &&
            wheelSet.gearBox &&
            wheelSet.gearBox.Reference
        ) {
            data.functionalLocations.push({
                LocationName: wheelSet.gearBox.LocationName,
                overallStatus: wheelSet.gearBox.overallStatus,
                selected: false
            });
        }
        if (
            (!filter ||
                (!!filter &&
                    filter.includes(
                        wheelSet.tractionMotor &&
                        wheelSet.tractionMotor.overallStatus
                    ))) &&
            wheelSet.tractionMotor &&
            wheelSet.tractionMotor.Reference
        ) {
            data.functionalLocations.push({
                LocationName: wheelSet.tractionMotor.LocationName,
                overallStatus: wheelSet.tractionMotor.overallStatus,
                selected: false
            });
        }
    });

    if (data.functionalLocations.length === 0) {
        return false;
    }
    return data;
}
const HistoryContainer = props => {
    const format = "YYYY-MM-DD";
    const startTimeFormat = `${format}`;
    const endTimeFormat = `${format}`;
    const convertTime = time => {
        return moment(time).utc();
    };
    const [graphData, updateGraphData] = useState([]);
    const [graphTimePeriod, updateGraphTimePeriod] = useState({
        startDate: new Date(
            moment()
                .utc()
                .format(startTimeFormat)
        ),
        endDate: new Date(
            moment()
                .utc()
                .format(endTimeFormat)
        )
    });
    const [fetchRequestData, updateFetchRequestData] = useState({
        references: []
    });
    const [carriageDataModel, updateCarriageDataModel] = useState({
        data: [],
        selected: []
    });
    const [historyTableData, updateHistoryTableData] = useState([]);
    function createFilter() {
        let filter = "";
        props.filterAsset.forEach(obj => {
            filter += obj.value;
        });
        return filter;
    }
    function filterGrapDataByLocation(LocationName) {
        const selected = [...carriageDataModel.selected];

        const index = selected.findIndex(item => item === LocationName);

        if (index >= 0) {
            selected.splice(index, 1);
        } else {
            selected.push(LocationName);
        }
        const filter = createFilter();
        const gData = props.historyModelData.filter(data => {
            let flag = true;
            if (selected.length > 0) {
                flag = selected.includes(data.LocationName);
            }
            if (filter && flag) {
                flag =
                    filter.includes(data.overallStatus) ||
                    (filter.includes("actNow") && data.temperatureWarning);
            }
            return flag;
        });
        const htData = historyTableData.map(data => {
            if (selected.includes(data.LocationName)) {
                data.selected = true;
            } else {
                data.selected = false;
            }
            return data;
        });

        updateCarriageDataModel(prevState => ({ ...prevState, selected }));
        updateGraphData(gData);
        updateHistoryTableData(htData);
    }

    // listening to time and train changes
    useEffect(() => {
        const { timePeriod, vehiclesData } = props;
        if (
            timePeriod &&
            timePeriod.startDate &&
            timePeriod.endDate &&
            vehiclesData
        ) {
            const references = [];

            vehiclesData.forEach((data, index) => {
                let ref = "";
                const indexes = data._id
                    .slice(8)
                    .split("-")
                    .filter(e => e);
                if (indexes.length === 1) {
                    ref = props.vehicles[indexes[0]];
                } else if (indexes.length === 2) {
                    ref = props.vehicles[indexes[0]].carriages[indexes[1]];
                }
                if (ref) {
                    references.push(ref);
                }
            });
            const data = {
                references,
                startDate: convertTime(timePeriod.startDate).format(
                    startTimeFormat
                ),
                endDate: convertTime(timePeriod.endDate).format(endTimeFormat)
            };
            updateGraphTimePeriod({
                startDate: new Date(
                    convertTime(timePeriod.startDate).format(startTimeFormat)
                ),
                endDate: new Date(
                    convertTime(timePeriod.endDate).format(endTimeFormat)
                )
            });
            updateFetchRequestData(data);
            props.fetchHistoryModel(data);
        } else {
            updateFetchRequestData({ references: [] });
        }
    }, [props.vehiclesData, props.timePeriod]);
    // listening to fetched data changes
    useEffect(() => {
        let data = props.historyModelData;
        const cData = [];
        const htData = [];
        const filter = createFilter();
        fetchRequestData.references.forEach(reference => {
            if (reference.carriages) {
                reference.carriages.forEach(carriage => {
                    const data = createDataFromCarriage(carriage, filter);

                    if (data) {
                        cData.push(data);
                    }
                });
            } else if (reference.wheelSets) {
                const data = createDataFromCarriage(reference, filter);
                if (data) {
                    cData.push(data);
                }
            }
        });
        if (filter) {
            data = props.historyModelData.filter(data => {
                return (
                    filter.includes(data.overallStatus) ||
                    (filter.includes("actNow") && data.temperatureWarning)
                );
            });
        }
        data.forEach(data => {
            const tempIndex = data.temperature
                ? data.temperature.length - 1
                : -1;
            const velocityIndex = data.velocity ? data.velocity.length - 1 : -1;
            const speedIndex = data.speed ? data.speed.length - 1 : -1;

            htData.push({
                LocationName: data.LocationName,
                temperature: _.get(data, `temperature[${tempIndex}].yaxis`, 0),
                velocity: _.get(data, `velocity[${velocityIndex}].yaxis`, 0),
                speed: _.get(data, `speed[${speedIndex}].yaxis`, 0),
                selected: false
            });
        });

        updateHistoryTableData(htData);
        updateGraphData(data);
        updateCarriageDataModel({ data: cData, selected: [] });
    }, [props.historyModelData, props.filterAsset]);

    useEffect(() => { });

    // listening to selection of functionalLocation

    return (
        <div>
            <div className="history-parent-container">
                <div className="history-filter">
                    <Filter showDatePicker showTrainName showFilterBtn />
                </div>

                {fetchRequestData.references.length > 0 ? (
                    <div className="history-container">
                        <div className="history-left-container">
                            <HistoryRow
                                carriageData={carriageDataModel.data}
                                selected={carriageDataModel.selected}
                                onLocationClick={filterGrapDataByLocation}
                            />
                        </div>
                        <div className="history-graph-container">
                            <>
                                <HistoryGraph
                                    graphData={graphData}
                                    graphTimePeriod={graphTimePeriod}
                                    timePeriod={props.timePeriod}
                                />
                                <HistoryTable tableData={historyTableData} />
                            </>
                        </div>
                    </div>
                ) : (
                        <>
                            <div className="no-content-image">
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="sitemap"
                                    className="svg-inline--fa fa-sitemap fa-w-20 sc-esjQYD fUcZRy"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"
                                    />
                                </svg>
                                <div>
                                    Select level in the hierarchy for detailed view
                            </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};
HistoryTable.propTypes = {
    tableData: PropTypes.array
};
HistoryTable.defaultProps = {
    tableData: []
};
HistoryContainer.propTypes = {
    historyTableData: PropTypes.array,
    saveHistoryData: PropTypes.func
};
HistoryContainer.defaultProps = {
    historyTableData: [],
    saveHistoryData() { }
};
function mapStateToProps(state) {
    return {
        historyTableData: state.historyData.historyTableData,
        vehiclesData: state.fleetData.vehiclesData
            ? state.fleetData.vehiclesData.checked
            : [],
        timePeriod: state.fleetData.timeFilterData,
        vehicles: state.vehicleModel.vehicles,
        historyModelData: state.historyModel.data,
        filterAsset: state.fleetData.filterData.assetStatus.value
    };
}
function mapDispatchToProps(dispatch) {
    return {
        saveHistoryData: data => {
            dispatch(saveHistoryData(data));
        },
        fetchHistoryModel: data => {
            dispatch(fetchHistoryModel(data));
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer);
