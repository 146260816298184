import React, { useEffect, useState } from "react";
import "./styles.scss";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import Bogie from "../Bogie";
import computeRouteName from "../../../utils/fleet/routeName";
import { findOverlayClass } from "../../../utils/trainTopView/findOverlayClass";
import { bogieConstants, carriageConstants } from "../../../utils/constants";

const findBogieData = wheelSetsArray => {
    let firstBogieData = [];
    let secondBogieData = [];
    const groupedBogies = _.groupBy(wheelSetsArray, "BogieNumber");

    const bogieValues = Object.values(groupedBogies);
    [firstBogieData, secondBogieData] = bogieValues;
    return { firstBogieData, secondBogieData };
};

const Carriage = props => {
    const [carriageClassName, updateCarriageClassName] = useState("");
    const [bogiesData, updateNewBogieData] = useState({});
    const [routeName, updateRouteName] = useState("");
    const [carriageOverlay, updateCarriageOverlay] = useState("");

    useEffect(() => {
        let newCarriageClassName = "";
        if (props.carriagePosition === 1 && props.carriagePosition === props.carriageLength) {
            newCarriageClassName = `${carriageConstants.carriageMiddle}`;
        } else if (props.carriagePosition === 1) {
            newCarriageClassName = `${carriageConstants.carriageFirst}`;
        } else if (props.carriagePosition === props.carriageLength) {
            newCarriageClassName = `${carriageConstants.carriageLast}`;
        } else {
            newCarriageClassName = `${carriageConstants.carriageMiddle}`;
        }
        updateCarriageClassName(newCarriageClassName);

        let newBogieData = "";
        newBogieData = findBogieData(props.carriageData.wheelSets);
        updateNewBogieData(newBogieData);

        const newRouteName = computeRouteName(props.match);
        updateRouteName(newRouteName);

        if (props.match.params.vehicleId) {
            const newCarriageOverlay = findOverlayClass(
                props.match.params.vehicleId,
                newRouteName,
                props.carriageData.VehicleNumber
            );
            updateCarriageOverlay(newCarriageOverlay);
        }
    }, [props.carriageLength, props.match]);

    return (
        <div className="carriage-container">
            <div className={`${carriageClassName} ${carriageOverlay}`}>
                <Link
                    to={`/train/${props.match.params.id}/vehicle/${props.carriageData.VehicleNumber}`}
                >
                    <Bogie
                        bogiePosition={`${bogieConstants.positionFront}`}
                        bogieStatus={props.bogieFirstStatus}
                        bogieData={bogiesData.firstBogieData}
                        routeName={routeName}
                        match={props.match}
                        carriageReference={props.carriageData.VehicleNumber}
                    />
                </Link>
                <Link
                    to={`/train/${props.match.params.id}/vehicle/${props.carriageData.VehicleNumber}`}
                >
                    <div className="key-style">{props.carriagePosition}</div>
                </Link>
                <Link
                    to={`/train/${props.match.params.id}/vehicle/${props.carriageData.VehicleNumber}`}
                >
                    <Bogie
                        bogiePosition={`${bogieConstants.positionBack}`}
                        bogieStatus={props.bogieSecondStatus}
                        bogieData={bogiesData.secondBogieData}
                        routeName={routeName}
                        match={props.match}
                        carriageReference={props.carriageData.VehicleNumber}
                    />
                </Link>
            </div>
            <div className="vehicle-name-text">
                {props.textBelowCarriage}
                &nbsp;
                <p className="vehicle-name">
                    {props.carriageData.VehicleNumber}
                </p>
            </div>
        </div>
    );
};

Carriage.defaultProps = {
    textBelowCarriage: "Vehicle: "
};

export default withRouter(Carriage);
