import React, { Component } from "react";
import "./style.scss";

class ToggleButton extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUnmount() {
        if (this.props.toggleFlag === true) {
            this.props.onToggle(!this.props.toggleFlag);
        }
    }

    handleChange() {
        const toggleSwitch = !this.props.toggleFlag;
        this.props.onToggle(toggleSwitch);
    }

    render() {
        return (
            <div className="display">
                <label className="toggle">
                    <input
                      type="checkbox"
                      className="toggle input"
                      onChange={this.handleChange}
                      checked={this.props.toggleFlag}
                    />
                    <div className="toggle-control" />
                </label>
                <p className="filter-handle">{this.props.filterIndicatorText}</p>
            </div>
        );
    }
}

ToggleButton.defaultProps = {
    filterIndicatorText: "Show Filters"
};
export default ToggleButton;
