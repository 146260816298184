import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Spinner from "../Spinner/index";
import Temperature from "../../assets/images/temperature.png";
import Speed from "../../assets/images/Speed.svg";
import Vibration from "../../assets/images/vibration.png";
import MyLineGraph from "../HistoryContainer/chart.js/index";
import "./style.scss";

function getGraphData(graphData, path) {
    const res = {
        dataArr: [],
        label: [],
        totalReadingCount: 0
    };

    _.each(graphData, graphObj => {
        const sensorReadingsArr = _.get(graphObj, path, []);
        if (sensorReadingsArr.length) {
            res.dataArr.push(graphObj);

            if (sensorReadingsArr.length >= res.totalReadingCount) {
                res.label = sensorReadingsArr;
            }
        }
    });
    return res;
}

const HistoryGraph = props => {
    const [tempDataLabels, setTempDataLabels] = useState([]);
    const [velDataLabels, setVelDataLabels] = useState([]);
    const [speedDataLabels, setSpeedDataLabels] = useState([]);
    const [temperatureData, setTemperatureData] = useState([]);
    const [velocityData, setVelocityData] = useState([]);
    const [speedData, setSpeedData] = useState([]);
    const [startEndTime, setTime] = useState({
        startDate: moment(new Date().toISOString()).format(
            "YYYY-MM-DD HH:MM:SS "
        ),
        endDate: moment(new Date().toISOString()).format("YYYY-MM-DD HH:MM:SS ")
    });
    useEffect(() => {
        const tempData = getGraphData(props.graphData, "temperature");
        const velData = getGraphData(props.graphData, "velocity");
        const speedsData = getGraphData(props.graphData, "speed");
        setTemperatureData(tempData.dataArr);
        setTempDataLabels(tempData.label);

        setVelocityData(velData.dataArr);
        setVelDataLabels(velData.label);

        setSpeedData(speedsData.dataArr);
        setSpeedDataLabels(speedsData.label);

        if (
            props.timePeriod &&
            props.timePeriod.startDate &&
            props.timePeriod.endDate
        ) {

            let newStartDate = new Date(
                props.timePeriod.startDate
            ).toISOString();
            newStartDate = moment(newStartDate).format("YYYY-MM-DD HH:MM:SS");
            let newEndDate = new Date(props.timePeriod.endDate).toISOString();
            newEndDate = moment(newEndDate).format("YYYY-MM-DD HH:MM:SS");
            setTime({ startDate: newStartDate, endDate: newEndDate });
        }
    }, [props.graphData]);

    return (
        <div>
            {" "}
            {!props.sensorReadings.isLoading && tempDataLabels ? (
                <div className="graph-container">
                    {temperatureData.length !== 0 ? (
                        <div>
                            <MyLineGraph
                                labels={tempDataLabels}
                                data={temperatureData}
                                isLoading={props.sensorReadings.isLoading}
                                title="Temperature"
                                subText={
                                    <span>
                                        <sup> &deg;</sup>C
                                    </span>
                                }
                                image={Temperature}
                                startTime={startEndTime.startDate}
                                endTime={startEndTime.endDate}
                                val="temperature"
                            />
                        </div>
                    ) : (
                            <div className="no-data-div">No Data Here</div>
                        )}
                    {velocityData.length !== 0 ? (
                        <div>
                            <MyLineGraph
                                labels={velDataLabels}
                                data={velocityData}
                                isLoading={props.sensorReadings.isLoading}
                                title="Vibration"
                                subText="gE"
                                image={Vibration}
                                startTime={startEndTime.startDate}
                                endTime={startEndTime.endDate}
                                val="velocity"
                            />
                        </div>
                    ) : (
                            <div className="no-data-div">No Data Here</div>
                        )}
                    {speedData.length !== 0 ? (
                        <div>
                            <MyLineGraph
                                labels={speedDataLabels}
                                data={speedData}
                                isLoading={props.sensorReadings.isLoading}
                                title="Speed"
                                subText="km/h"
                                image={Speed}
                                startTime={startEndTime.startDate}
                                endTime={startEndTime.endDate}
                                val="speed"
                            />
                        </div>
                    ) : (
                            <div className="no-data-div">No Data Here</div>
                        )}
                </div>
            ) : (
                    <Spinner location="main-app" />
                )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        vehicles: state.vehicleModel.vehicles,
        sensorReadings: state.historyModel
    };
};

export default connect(mapStateToProps)(HistoryGraph);
