import actionTypes from "../constants";

const initialState = {
    currentPwd: "",
    password: "",
    confirmPwd: "",
    confirmPwdError: "",
    startSpinner: false
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_PASSWORD:
            return {
                ...state,
                password: payload
            };
        case actionTypes.SET_CONFIRM_PWD:
            return {
                ...state,
                confirmPwd: payload
            };
        case actionTypes.SET_CURRENT_PWD:
            return {
                ...state,
                currentPwd: payload
            };
        case actionTypes.CONFIRM_PWD_ERROR: {
            return {
                ...state,
                confirmPwdError: payload
            };
        }
        case actionTypes.REMOVE_CONFIRM_PWD_ERROR: {
            return {
                ...state,
                confirmPwdError: payload
            };
        }
        case actionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                startSpinner: true
            };
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                startSpinner: false
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
}
