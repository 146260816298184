import React, { useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import "./styles.scss";
import { connect } from "react-redux";
import { saveSelectedValue } from "../../store/actions/modal.action";
import { actionStatusDropdownProp } from "../../utils/constants";

const FeedbackDropdown = props => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    const handleItem = (item, dropdownType) => {
        const obj = {};
        obj[dropdownType] = item;

        props.saveSelectedValue(obj);
    };
    return (
        <div className="feedback-dropdown">
            <Dropdown
              className={`${
                    props.dropdownType === actionStatusDropdownProp.actionStatua
                        ? "action-status"
                        : "other-status"
                }`}
              isOpen={dropdownOpen}
              toggle={toggle}
            >
                <DropdownToggle caret>
                    {props.userResponseData[props.dropdownType] === ""
                        ? props.defaultValue
                        : props.userResponseData[props.dropdownType]}
                </DropdownToggle>
                <DropdownMenu>
                    {props.dropdownItems.map(item => (
                        <DropdownItem
                          onClick={() => handleItem(item, props.dropdownType)}
                        >
                            {item}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        userResponseData: state.feedbackModal.responseData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveSelectedValue: data => {
            dispatch(saveSelectedValue(data));
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackDropdown);
