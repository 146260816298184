import { cloneDeep } from "lodash";
import eventLevelconstant from "./utils/constants";
import { getOverallStatus } from "./utils/computeStatusCounts";
import { computeRedAmberEventCounts } from "./utils/computeWarningAndCounts";
import computeEvents, { getPointList } from "./utils/computeEventsAndPointList";

const modelGearBox = (gearBoxObject, events, pointsList) => {
    const clonedGearBoxObject = cloneDeep(gearBoxObject);
    if (gearBoxObject.LocationName) {
        // computing events for gearBox
        const gearBoxEvents = computeEvents(
            clonedGearBoxObject.LocationName,
            events,
            eventLevelconstant.assetId
        );
        clonedGearBoxObject.events = gearBoxEvents;

        // computing pointList for gearBox
        const gearBoxPoints = getPointList(
            clonedGearBoxObject.Reference,
            pointsList
        );
        clonedGearBoxObject.pointList = gearBoxPoints;

        // added amber and red counts for traction
        const countsObj = computeRedAmberEventCounts(gearBoxEvents);
        clonedGearBoxObject.redGearboxCount = countsObj.redGearboxCount;
        clonedGearBoxObject.amberGearboxCount = countsObj.amberGearboxCount;

        // updating gaerBoxes with overallStatus
        clonedGearBoxObject.overallStatus = getOverallStatus(gearBoxEvents);
    }
    return clonedGearBoxObject;
};
export default modelGearBox;
