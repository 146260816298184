import React, { PureComponent } from "react";

import propTypes from "prop-types";
import classNames from "classnames";

import "./style.scss";
import { createCoverageMap } from "istanbul-lib-coverage";

export default class NumberCount extends PureComponent {
    render() {
        const numberClass = classNames({
            "count-style-left": this.props.numberLeft,
            "count-style-right": !this.props.numberLeft,

            danger: this.props.danger,
            "default-component": this.props.defaultComponent,
            warning: this.props.warning,
            default: this.props.default || this.props.number === 0
        });

        return (
            <div className="number-count-container">
                {this.props.icon ? (
                    <div className="icon-style">
                        <img src={this.props.icon} alt="dangerIcon" />
                    </div>
                ) : (
                    <div />
                )}

                <div className={numberClass}>
                    {this.props.number}
                    {this.props.sup ? (
                        <sup className="super">{this.props.supText}</sup>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        );
    }
}

NumberCount.propTypes = {
    icon: propTypes.string,
    number: propTypes.number,
    numberLeft: propTypes.bool,
    default: propTypes.bool,
    danger: propTypes.bool,
    defaultComponent: propTypes.bool,
    warning: propTypes.bool
};

NumberCount.defaultProps = {
    icon: "",
    number: 0,
    default: false,
    numberLeft: false,
    danger: false,
    defaultComponent: false,
    warning: false
};
