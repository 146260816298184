import React from "react";
import rightArrow from "../../assets/images/right-arrow.png";

export function RightArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick}>
            <img
              className={`${className} slider-next-arrow`}
              style={{ ...style }}
              src={rightArrow}
              alt="right-arrow"
            />
        </div>
    );
}

export function LeftArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick}>
            <img
              className={`${className} slider-next-arrow left-arrow`}
              style={{ ...style }}
              src={rightArrow}
              alt="left-arrow"
            />
        </div>
    );
}
