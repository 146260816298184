import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

class DatePickerFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedDate: null };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            selectedDate: date
        });
        this.props.onDateChange(date);
    }

    render() {
        return (
            <div className="date-pickers-div">
                <DatePicker
                    selected={this.state.selectedDate}
                    onChange={this.handleChange}
                    className="date-picker"
                    minDate={this.props.minDate ? this.props.date : ""}
                    maxDate={new Date()}
                />
            </div>
        );
    }
}

export default DatePickerFilter;
