import * as moment from "moment";
import _ from "lodash";

const getDateFilteredItems = (reportEvents, startDate, endDate) => {
    if (startDate && endDate) {
        const startDateValue = moment(startDate).valueOf();
        const endDateValue = moment(endDate).valueOf();
        const finalReportEvents = reportEvents;
        const res = _.filter(finalReportEvents, event => {
            const createdDate = moment(event.created).valueOf();
            if (createdDate >= startDateValue && createdDate <= endDateValue) {
                return event;
            }
            return false;
        });
        const alarmEvnets = _.filter(reportEvents, event => {
            return event.type === "Alarm";
        });

        const result = [...res, ...alarmEvnets];

        return result;
    }
    return reportEvents;
};
export default getDateFilteredItems;
