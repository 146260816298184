import _ from "lodash";
import alarmStatusCode, { assetTypes } from "../../constants";

export const findStatus = val => {
    if (val >= 8 && val <= 10) {
        return alarmStatusCode.danger.color;
    }
    if (val >= 4 && val <= 7) {
        return alarmStatusCode.warning.color;
    }
    return alarmStatusCode.none.value;
};
export const formPathName = (vehicleName, carriageName, wheelsetName) => {
    return `${vehicleName}/${carriageName}/${wheelsetName}`;
};

export const findAssetTypeAndSide = str => {
    const splitString = str.split(" ");
    
    const [firstStr, secondStr] = splitString;

    if (str.includes(assetTypes.tractionMotor.shortValue)) {
        return {
            assetType: `${firstStr} ${secondStr}`,
            side: ""
        };
    }
    if (str.includes(assetTypes.gearbox.value)) {
        return {
            assetType: firstStr,
            side: ""
        };
    }
    if (str.includes(assetTypes.wheel.value)) {
        return {
            assetType: firstStr,
            side: secondStr
        };
    }
    return {
        assetType: secondStr,
        side: splitString[splitString.length - 1]
    };
};
export const sortByStatus = events => {
    let finalEvents = [];
    const actNowEvnets = _.filter(events, e => {
        return e.status === alarmStatusCode.dangerTemperature.color;
    });

    const actEvnets = _.filter(events, e => {
        return e.status === alarmStatusCode.danger.color;
    });

    const amberEvnets = _.filter(events, e => {
        return e.status === alarmStatusCode.warning.color;
    });

    finalEvents = [...actNowEvnets, ...actEvnets, ...amberEvnets];

    return finalEvents;
};
