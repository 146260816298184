import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import actionTypes from "../constants";
import { sortDashboardTable } from "../actions/dashboardTableData.action";
import { dashboardSortedTable } from "../../utils/constants";

export function* sortFunction(action) {
    try {
        const totalTrains = action.payload.totalTrainsArray;
        let sortedArray = [];
        if (
            action.payload.sortCriteria === dashboardSortedTable[0].sortCriteria
        ) {
            sortedArray = _.sortBy(totalTrains, [action.payload.sortCriteria]);
        } else {
            sortedArray = _.sortBy(totalTrains, [
                action.payload.sortCriteria
            ]).reverse();
        }
        yield put(sortDashboardTable(sortedArray));
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeLatest([actionTypes.SORTING_DASHBOARD_TABLE], sortFunction)
];
