import actionTypes from "../constants";

const initialState = {
    isProgress: false
};

export default function(state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.IS_PROGRESS:
            return {
                isProgress: !state.isProgress
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return {
                ...state
            };
    }
}
