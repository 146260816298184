import actionTypes from "../constants";

export const startLoginUser = data => {
    return {
        type: actionTypes.USER_REQUEST,
        payload: data
    };
};

export const requestUserSuccess = data => {
    return {
        type: actionTypes.USER_SUCCESS,
        payload: data
    };
};

export const requestUserError = () => {
    return { type: actionTypes.USER_FAILURE, payload: {} };
};

export const updateEmailErrorMsg = data => {
    return {
        type: actionTypes.ERROR_EMAIL,
        payload: data
    };
};
export const removeEmailErrorMsg = () => {
    return {
        type: actionTypes.REMOVE_ERROR_EMAIL,
        payload: ""
    };
};
export const updatePwdErrorMsg = data => {
    return {
        type: actionTypes.ERROR_PWD,
        payload: data
    };
};
export const removePwdErrorMsg = () => {
    return {
        type: actionTypes.REMOVE_ERROR_PWD,
        payload: ""
    };
};
export const stopLoading = () => {
    return {
        type: actionTypes.LOADING
    };
};
