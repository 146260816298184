import _ from "lodash";
import alarmStatusCode, { assetTypes, measurementTypes } from "../../constants";
// yet to be computed !!
const getPointSideAndTypes = point => {
    const side = "";
    const measurementType = alarmStatusCode.none.value;
    const assetType = alarmStatusCode.none.value;
    const strs = _.get(point, "Name", "").split(" ");

    const measurementLabel = strs[0].replace(/[^a-zA-Z]+/g, "");
    const res = measurementLabel.match(new RegExp(/T$/));

    if (res) {
        if (strs.includes(assetTypes.bearing.code)) {
            return {
                side: strs[1],
                measurementType: measurementTypes.temperature,
                assetType: assetTypes.bearing.value
            };
        }
        if (strs.includes(assetTypes.gearbox.code)) {
            return {
                side,
                measurementType: measurementTypes.temperature,
                assetType: assetTypes.gearbox.value
            };
        }
        if (strs.includes(assetTypes.tractionMotor.code)) {
            return {
                side,
                measurementType: measurementTypes.temperature,
                assetType: assetTypes.tractionMotor.value
            };
        }
    }
    if (_.get(point, "Status", []).includes(12)) {
        if (strs.includes(assetTypes.bearing.code)) {
            return {
                side: strs[1],
                measurementType: measurementTypes.sensor,
                assetType: measurementTypes.sensor
            };
        }
        if (strs.includes(assetTypes.gearbox.code)) {
            return {
                side,
                measurementType: measurementTypes.sensor,
                assetType: assetTypes.gearbox.value
            };
        }
        return {
            side,
            measurementType: measurementTypes.sensor,
            assetType: assetTypes.tractionMotor.value
        };
    }

    return {
        side,
        measurementType,
        assetType
    };
};

export default getPointSideAndTypes;
