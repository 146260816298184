import _ from "lodash";
import alarmStatusCode from "../constants";

function computeOverallCounts(counts = {}) {
    let overallRedCount = 0;
    let overallAmberCount = 0;
    Object.keys(counts).forEach(key => {
        if (key.includes(alarmStatusCode.danger.colorCase)) {
            overallRedCount += counts[key];
        } else if (
            key.includes(alarmStatusCode.warning.colorCase) ||
            key.includes(alarmStatusCode.sensor.colorCase)
        ) {
            overallAmberCount += counts[key];
        }
    });

    return { overallAmberCount, overallRedCount };
}

function computeRouteData(params) {
    const defaultCounts = {
        redBearingCount: 0,
        amberBearingCount: 0,
        redWheelCount: 0,
        amberWheelCount: 0,
        sensorCount: 0,
        overallAmberCount: 0,
        overallRedCount: 0,
        amberTractionMotorCount: 0,
        redTractionMotorCount: 0,
        amberGearboxCount: 0,
        redGearboxCount: 0
    };

    let result = {};
    if (params.assetId) {
        result = {
            id: params.assetId,
            routeName: "asset",
            computeStatus: (trainData = {}) => {
                let counts = defaultCounts;
                if (trainData.carriages) {
                    const carraige = trainData.carriages.find(
                        carriage => carriage.VehicleNumber === params.vehicleId
                    );
                    if (carraige) {
                        if (carraige.wheelSets) {
                            for (
                                let i = 0;
                                i < carraige.wheelSets.length;
                                i += 1
                            ) {
                                const actualData = carraige.wheelSets[
                                    i
                                ].axleBoxes.find(
                                    axleBox =>
                                        axleBox.LocationName === params.assetId
                                );
                                if (actualData) {
                                    counts = Object.assign(
                                        defaultCounts,
                                        _.pick(
                                            actualData,
                                            Object.keys(defaultCounts)
                                        )
                                    );
                                    counts.overallAmberCount =
                                        actualData.overallAmberCount;
                                    counts.overallRedCount =
                                        actualData.overallRedCount;

                                    // counts = Object.assign(counts, computeOverallCounts(counts));
                                    break;
                                }
                            }
                        }
                    }
                }
                return counts;
            },

            getEvents: (trainEvents = []) => {
                const events = trainEvents.filter(event => {
                    const { metaData } = event;
                    return (
                        metaData.assetId === params.assetId &&
                        metaData.carriageId === params.vehicleId &&
                        event.status !== alarmStatusCode.none.value
                    );
                });

                return events || [];
            }
        };
    } else if (params.gearBoxId) {
        result = {
            id: params.gearBoxId,
            routeName: "gearBox",
            computeStatus: (trainData = {}) => {
                let counts = defaultCounts;
                if (trainData.carriages) {
                    const carriage = trainData.carriages.find(
                        carriageItem =>
                            carriageItem.VehicleNumber === params.vehicleId
                    );

                    if (carriage) {
                        if (carriage.wheelSets) {
                            for (
                                let i = 0;
                                i < carriage.wheelSets.length;
                                i += 1
                            ) {
                                const actualData = carriage.wheelSets.find(
                                    wheelSet =>
                                        wheelSet.gearBox.LocationName &&
                                        wheelSet.gearBox.LocationName.split(
                                            " "
                                        ).join("") === params.gearBoxId
                                );

                                if (actualData) {
                                    counts = Object.assign(
                                        defaultCounts,
                                        _.pick(
                                            actualData.gearBox,
                                            Object.keys(defaultCounts)
                                        )
                                    );
                                    counts.overallAmberCount =
                                        actualData.amberGearboxCount;
                                    counts.overallRedCount =
                                        actualData.redGearboxCount;
                                }
                            }
                        }
                    }
                }
                return counts;
            },

            getEvents: (trainEvents = []) => {
                const events = trainEvents.filter(event => {
                    const { metaData } = event;
                    return (
                        metaData.assetId.split(" ").join("") ===
                            params.gearBoxId &&
                        metaData.carriageId === params.vehicleId &&
                        event.status !== alarmStatusCode.none.value
                    );
                });

                return events || [];
            }
        };
    } else if (params.tractionMotorId) {
        result = {
            id: params.tractionMotorId,
            routeName: "tractionMotor",
            computeStatus: (trainData = {}) => {
                let counts = defaultCounts;
                if (trainData.carriages) {
                    const carriage = trainData.carriages.find(
                        carriageItem =>
                            carriageItem.VehicleNumber === params.vehicleId
                    );

                    if (carriage) {
                        if (carriage.wheelSets) {
                            for (
                                let i = 0;
                                i < carriage.wheelSets.length;
                                i += 1
                            ) {
                                const actualData = carriage.wheelSets.find(
                                    wheelSet =>
                                        wheelSet.tractionMotor.LocationName &&
                                        wheelSet.tractionMotor.LocationName.split(
                                            " "
                                        ).join("") === params.tractionMotorId
                                );

                                if (actualData) {
                                    counts = Object.assign(
                                        defaultCounts,
                                        _.pick(
                                            actualData.tractionMotor,
                                            Object.keys(defaultCounts)
                                        )
                                    );
                                    counts.overallAmberCount =
                                        actualData.amberGearboxCount;
                                    counts.overallRedCount =
                                        actualData.redGearboxCount;
                                }
                            }
                        }
                    }
                }
                return counts;
            },

            getEvents: (trainEvents = []) => {
                const events = trainEvents.filter(event => {
                    const { metaData } = event;
                    return (
                        metaData.assetId.split(" ").join("") ===
                            params.tractionMotorId &&
                        metaData.carriageId === params.vehicleId &&
                        event.status !== alarmStatusCode.none.value
                    );
                });

                return events || [];
            }
        };
    } else if (params.vehicleId) {
        result = {
            id: params.vehicleId,
            routeName: "vehicle",
            computeStatus: (trainData = {}) => {
                let counts = defaultCounts;
                if (trainData.carriages) {
                    const actualData = _.pick(
                        trainData.carriages.find(
                            carriage =>
                                carriage.VehicleNumber === params.vehicleId
                        ),
                        Object.keys(defaultCounts)
                    );
                    counts = Object.assign(defaultCounts, actualData);

                    counts = Object.assign(
                        counts,
                        computeOverallCounts(counts)
                    );
                }

                return counts;
            },
            getEvents: (trainEvents = []) => {
                const events = trainEvents.filter(event => {
                    const { metaData } = event;
                    return metaData.carriageId === params.vehicleId;
                });
                return events || [];
            }
        };
    } else if (params.id) {
        result = {
            id: params.id,
            routeName: "train",
            computeStatus: (trainData = {}) => {
                const counts = Object.assign(
                    defaultCounts,
                    _.pick(trainData, Object.keys(defaultCounts))
                );
                let dangerCount = 0;
                let amberCount = 0;
                trainData.carriages.forEach(item => {
                    if (
                        item.overallStatus ===
                            alarmStatusCode.dangerTemperature.color ||
                        item.overallStatus === alarmStatusCode.danger.color
                    ) {
                        dangerCount += 1;
                    } else if (
                        item.overallStatus === alarmStatusCode.warning.color
                    ) {
                        amberCount += 1;
                    }
                });
                counts.overallRedCount = dangerCount;
                counts.overallAmberCount = amberCount;
                // counts = Object.assign(counts, computeOverallCounts(counts));
                return counts;
            },
            getEvents: trainEvents => {
                return trainEvents || [];
            }
        };
    }
    return result;
}

export default computeRouteData;
