import React from "react";

const ReportBreadCrumb = props => {
    const splittedPathName = props.pathName.split("/");
    return (
        <div className="report-breadcrumb-div">
            {`${splittedPathName[0]} > ${splittedPathName[1]} > ${
                splittedPathName[2]
            }`}
        </div>
    );
};

export default ReportBreadCrumb;
