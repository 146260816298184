import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    GoogleMap,
    HeatmapLayer,
    LoadScript,
    Marker
} from "@react-google-maps/api";
import redMapMarker from "../../assets/images/redDangerMapMarker.png";
import yellowMapMarker from "../../assets/images/yellowWarningMapMarker.png";
import greyMapMarker from "../../assets/images/greyMapMarker.png";
import alarmStatusCode, { gradient } from "../../utils/constants";

const HeatmapPropTypes = {
    styles: PropTypes.shape({
        container: PropTypes.object.isRequired
    }).isRequired
};

function getMarkerIconColour(trainStatus) {
    if (
        trainStatus === alarmStatusCode.dangerTemperature.color ||
        trainStatus === alarmStatusCode.danger.color
    ) {
        return redMapMarker;
    }

    if (trainStatus === alarmStatusCode.warning.color) {
        return yellowMapMarker;
    }
    return greyMapMarker;
}

const center = {
    lat: 0,
    lng: 0
};
// const gradient = ["rgba(80,227,194)", "rgba((15,88,214)", "rgba(5,53,137)", "rgba(75,0,130)"];
const HeatMap = ({ markers, lastPositionMarker }) => {
    const mapRef = useRef(null);
    const [mapData, setMapData] = useState([]);

    const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds();
        if (!_.isEmpty(markers)) {
            markers.forEach(place => {
                const obj = {};

                obj.lat = place.latitude;
                obj.lng = place.longitude;

                bounds.extend(obj);
            });
            map.fitBounds(bounds);
        } else if (!_.isEmpty(lastPositionMarker)) {
            lastPositionMarker.forEach(place => {
                const obj = {};
                obj.lat = _.get(place, "lat.Measurements[0].Level", "");
                obj.lng = _.get(place, "long.Measurements[0].Level", "");

                bounds.extend(obj);
            });
            map.fitBounds(bounds);
        }
    };

    const loadHandler = map => {
        fitBounds(map);
    };

    useEffect(() => {
        const data = markers.map(marker => {
            const heatMapData = new window.google.maps.LatLng(
                marker.latitude,
                marker.longitude
            );
            return heatMapData;
        });
        setMapData(data);
        if (mapRef.current.state.map !== null) {
            fitBounds(mapRef.current.state.map);
        }
    }, [markers]);
    return (
        <div className="map">
            <div className="map-container">
                <GoogleMap
                  onLoad={loadHandler}
                  ref={mapRef}
                  id="heatmap-example"
                  mapContainerStyle={{
                        height: "100%",
                        width: "100%"
                    }}
                  zoom={6}
                  center={center}
                >
                    {_.isEmpty(markers) ? null : (
                        <HeatmapLayer data={mapData} options={{ gradient }} />
                    )}
                    {_.isEmpty(lastPositionMarker)
                        ? null
                        : lastPositionMarker.map(item => {
                              return (
                                  <Marker
                                    position={{
                                          lat: _.get(
                                              item,
                                              "lat.Measurements[0].Level",
                                              ""
                                          ),
                                          lng: _.get(
                                              item,
                                              "long.Measurements[0].Level",
                                              ""
                                          )
                                      }}
                                    options={{
                                          icon: getMarkerIconColour(item.status)
                                      }}
                                  />
                              );
                          })}
                </GoogleMap>
            </div>
        </div>
    );
};
HeatMap.propTypes = HeatmapPropTypes;

const onLoad = () => console.info("script loaded");

const onError = err => console.error("onError: ", err);

const Loading = <div className="test-loading-component"> Loading... </div>;

const googleMapsLibraries = ["drawing", "visualization", "places"];

const MapWrapper = props => {
    return (
        <LoadScript
          id="google-map"
          googleMapsApiKey={props.googleMapsApiKey}
          region="EN"
          version="weekly"
          onLoad={onLoad}
          onError={onError}
          loadingElement={Loading}
          libraries={googleMapsLibraries}
        >
            <HeatMap
              markers={props.markers}
              lastPositionMarker={props.lastPositionMarker}
              markerColor={props.markerColor}
            />
        </LoadScript>
    );
};

export default MapWrapper;

MapWrapper.propTypes = {
    markers: PropTypes.array,
    lastPositionMarker: PropTypes.array,
    googleMapsApiKey: PropTypes.string
};

MapWrapper.defaultProps = {
    markers: [],
    lastPositionMarker: [],
    googleMapsApiKey: ""
};

HeatMap.propTypes = {
    markers: PropTypes.array,
    lastPositionMarker: PropTypes.array
};

HeatMap.defaultProps = {
    markers: [],
    lastPositionMarker: []
};
