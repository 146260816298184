import actionTypes from "../constants";

const initialState = {
    dropDownValue: "Most critical trains",
    dropdownOpen: false
};

export default function(state = initialState, action = {}) {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.DROP_DOWN_DASHBOARD_OPEN: {
            return {
                ...state,
                dropdownOpen: !state.dropdownOpen
            };
        }
        case actionTypes.DROP_DOWN_DASHBOARD:
            return {
                dropDownValue: payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
}
