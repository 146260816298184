import actionTypes from "../constants";

export const dashboardTableData = data => {
    return {
        type: actionTypes.DASHBOARD_TABLE_DATA,
        payload: data
    };
};

export const sortDashboardTable = sortedData => {
    return {
        type: actionTypes.SORTED_DASHBOARD_TABLE,
        payload: sortedData
    };
};
