import React, { Component, useEffect, useState } from "react";
import Chart from "chart.js";
import "./style.scss";
import _ from "lodash";
import moment from "moment";
import { isNilOrEmpty } from "../../../utils/helper";

function findMinMax(arr) {
    let min = new Date(arr[0].xaxis), max = new Date(arr[0].xaxis);

    for (let i = 1, len = arr.length; i < len; i++) {
        let v = new Date(arr[i].xaxis);
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    }

    return [min, max];
}

const computedValues = (values, props) => {
    if (isNilOrEmpty(values)) {
        return [];
    }
    const result = { labels: [], datasets: [], min: props.startTime, max: props.endTime };
    const resultMinMax = { min: props.startTime, max: props.endTime };

    //const finalDataSetValues = values.map((val, idx) => {
    values.map((val, idx) => {
        let tmpMinMax = findMinMax(_.get(val, `${props.val}`, []));
        resultMinMax.min = tmpMinMax[0] < resultMinMax.min ? tmpMinMax[0] : resultMinMax.min;
        resultMinMax.max = tmpMinMax[1] > resultMinMax.max ? tmpMinMax[1] : resultMinMax.max;

        let dataXY = [];
        const finalObj = {};
        const dataSet = _.get(val, `${props.val}`, []).map(temp => {
            if (`${props.val}` === "speed") {
                dataXY.push({ x: new Date(temp.xaxis), y: temp.yaxis * 918 * 0.0001885 });
            } else {
                dataXY.push({ x: new Date(temp.xaxis), y: temp.yaxis });
            }
            return temp.yaxis;
        });
        //console.debug("dataXY", dataXY);
        //Object.assign(finalObj, {
        result.datasets.push({
            //data: dataSet,
            data: dataXY,
            borderColor: "rgba(0, 119, 255, 1)",
            pointRadius: 0,
            pointHoverRadius: 5,
            lineTension: 0,
            borderWidth: 0.7,
            pointHoverBackgroundColor: "rgba(0, 0 , 139 , 1)",
            label: val.LocationName,
            tooltips: true,
            fill: false
        });

        //return finalObj;
    });
    // console.debug("finalDataSetValues", finalDataSetValues);
    //return finalDataSetValues;
    //console.debug("result", result);
    result.min = resultMinMax.min;
    result.max = resultMinMax.max;
    return result;
};
const computeLabels = labels => {
    if (isNilOrEmpty(labels)) {
        return [];
    }
    const newLabels = _.map(labels, label => {
        return moment(new Date(label.xaxis).toISOString()).format("YYYY-MM-DD HH:MM:SS");
    });
    return newLabels;
};

const MyLineGraph = props => {
    const [chartObj, updateChartObj] = useState(null);
    const chartElementRef = React.createRef();

    useEffect(() => {
        const myChartRef = chartElementRef.current.getContext("2d");
        //console.debug("props.data", props.data);
        const dataSetsValues = computedValues(props.data, props);
        const labelsArray = computeLabels(props.labels);
        const chartInstance = new Chart(
            myChartRef,
            {
                height: 500,
                responsive: true,
                type: "line",
                data: {
                    //labels: props.labels ? labelsArray : [],
                    //labels: dataSetsValues.labels,
                    datasets: dataSetsValues.datasets
                },
                options: {
                    maintainAspectRatio: false,
                    // Customize chart options
                    responsive: true,
                    title: {
                        display: false,
                        text: `${props.title}`
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false
                    },
                    plugins: {
                        crosshair: {
                            line: {
                                color: "#F66",
                                width: 1
                            },
                            sync: {
                                enabled: true,
                                group: 1,
                                suppressTooltips: false
                            },
                            zoom: {
                                enabled: true, // enable zooming
                                zoomboxBackgroundColor: "rgba(66,133,244,0.2)", // background color of zoom box
                                zoomboxBorderColor: "#48F", // border color of zoom box
                                zoomButtonText: "Reset Zoom", // reset zoom button text
                                zoomButtonClass: "reset-zoom" // reset zoom button class
                            },
                            callbacks: {
                                beforeZoom(start, end) {
                                    // called before zoom, return false to prevent zoom
                                    return true;
                                },
                                afterZoom(start, end) {
                                    // called after zoom
                                }
                            }
                        }
                    },
                    hover: {
                        mode: "nearest",
                        intersect: true
                    },
                    legend: {
                        display: false,
                        labels: {
                            fontSize: 0
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    autoSkip: true,
                                    //maxTicksLimit: 10,
                                    //source: "labels",
                                    //suggestedMin: props.startTime,
                                    //suggestedMax: props.endTime
                                    // ,
                                    // callback: val => {
                                    //     return moment(val.xaxis).format(
                                    //         "DD MMM YYYY"
                                    //     );
                                    // }
                                },
                                gridLines: {
                                    drawOnChartArea: false
                                },
                                type: "time",
                                time: {
                                    unit: "day",
                                    unitStepSize: 1,
                                    round: "minute",
                                    tooltipFormat: "MMM DD, YYYY H:mm:ss",
                                    displayFormats: {
                                        millisecond: "MMM DD",
                                        second: "MMM DD",
                                        minute: "MMM DD",
                                        hour: "MMM DD",
                                        day: "MMM DD",
                                        week: "MMM DD",
                                        month: "MMM DD",
                                        quarter: "MMM DD",
                                        year: "MMM DD"
                                    },
                                    min: dataSetsValues.min,
                                    max: dataSetsValues.max,
                                },
                                display: true
                                ,
                                distribution: "linear"
                                // ,
                                // scaleLabel: {
                                //     display: true
                                // }
                            }
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    drawOnChartArea: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    min: 0
                                },
                                display: true,
                                scaleLabel: {
                                    display: false
                                }
                            }
                        ]
                    }
                }
            },
            () => {
                if (chartObj) {
                    chartObj.destory();
                }
            }
        );
        updateChartObj(chartInstance);
    }, []);

    useEffect(() => {
        if (props.data && !props.isLoading && props.labels && chartObj) {
            const dataSetsValues = computedValues(props.data, props);
            chartObj.data.datasets = dataSetsValues.datasets;
            chartObj.options.scales.xAxes[0].time.min = dataSetsValues.min;
            chartObj.options.scales.xAxes[0].time.max = dataSetsValues.max;
            //const newLabels = computeLabels(props.labels);
            //chartObj.data.labels = newLabels;

            chartObj.update();
        }
    }, [props.data, props.labels]);
    return (
        <div className="canvas-container">
            <div className="temp-heading-graph">
                <div>
                    <div>
                        <img src={props.image} alt={props.title} />
                    </div>
                    <div className="sub-text">{props.subText}</div>
                </div>
                <div className="heading-text">{props.title}</div>
            </div>

            <canvas
                id="myChart"
                className="chartjs-width"
                ref={chartElementRef}
            />
        </div>
    );
};

export default MyLineGraph;
