import _ from "lodash";

const recurring = objec => {
    let objChild = {};
    let objChild2 = {};
    const vehicleTest = objec.children.map(item => {
        const carriageTest = item.carriages.map(carriage => {
            objChild2 = {
                label: carriage.VehicleNumber,
                value: `${item.Designation}/${carriage.VehicleNumber}`
            };
            return objChild2;
        });
        objChild = {
            label: item.Designation,
            value: `${item.Designation}`,
            children: carriageTest
        };
        return objChild;
    });
    return vehicleTest;
};
const createVehicleFilterData = vehicles => {
    const nodes = [];
    const tractionArray = _.groupBy(vehicles, "Traction");
    const tractionKeys = Object.keys(tractionArray);
    tractionKeys.forEach(traction => {
        const tractionObj = {};
        Object.assign(tractionObj, {
            label: traction,
            value: traction,
            children: tractionArray[traction]
        });
        tractionObj.children = recurring(tractionObj);
        nodes.push(tractionObj);
    });
    return nodes;
};
export default createVehicleFilterData;
