import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import download from "../../../assets/images/download.svg";
// import tableContent from "./fakeData";
import "./style.scss";
import { getFilteredCategoryList } from "../../../utils/fleet/compute";
import { downloadCategoryFile } from "../../../store/actions/categoryList.action";

const CategoryListTable = props => {
    const [categoryListTable, setTable] = useState([]);

    useEffect(() => {
        setTable(props.documents);
    }, [props.documents]);

    useEffect(() => {
        if (props.category === "Choose") {
            setTable(props.documents);
        } else {
            const tableContents = getFilteredCategoryList(
                props.category,
                props.documents
            );

            setTable(tableContents);
        }
    }, [props.category]);

    const handleDownloadClick = (trainId, file) => {
        const payload = {
            trainId,
            file
        };
        props.downloadCategoryFile(payload);
    };

    return (
        <div className="document-table">
            {categoryListTable.length === 0 ? (
                <div className="no-table-data">No data Avaliable</div>
            ) : (
                <Table>
                    <thead className="table-head-content">
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Created Date</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categoryListTable.map(item => {
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.category}</td>
                                    <td>{item.createdAt}</td>
                                    <td>
                                        <img
                                          src={download}
                                          alt="download"
                                          className="download-image"
                                          onClick={() => {
                                                handleDownloadClick(
                                                    item.trainId,
                                                    item.uploadFiles
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

function mapStateTopProps(state) {
    return {
        category: state.categoryList.category,
        documents: state.categoryList.documents
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadCategoryFile: data => dispatch(downloadCategoryFile(data))
    };
}

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(CategoryListTable);
