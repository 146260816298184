import { findIndex } from "lodash";

const wheelSetArrayForCarriages = (wheelSetReferences, wheelSets) => {
    const wheelSetUpdatedCarriage = wheelSetReferences.map(wheelsetRef => {
        let wheelSetObj = {};
        wheelSetObj =
            wheelSets[
                findIndex(wheelSets, wheel => {
                    return wheel.Reference === wheelsetRef;
                })
            ];
        return wheelSetObj;
    });
    return wheelSetUpdatedCarriage;
};
export default wheelSetArrayForCarriages;
