import _ from "lodash";

const getAssetMeasurementFilteredEvents = (filterData, events) => {
    if (
        filterData.assetStatus.value.length === 0 &&
        filterData.measurementType.value.length === 0
    ) {
        return events;
    }

    if (
        filterData.assetStatus.value[0] &&
        filterData.assetStatus.value[0].label === "All" &&
        filterData.measurementType.value[0] &&
        filterData.measurementType.value[0].label === "All"
    ) {
        return events;
    }

    if (filterData.assetStatus.value.length > 0 || filterData.measurementType.value.length > 0) {
        const actionFilterArray = filterData.assetStatus.value;
        const measurementFilterarray = filterData.measurementType.value;
        let resAction = _.filter(events, event => {
            const isMatched = _.find(actionFilterArray, ele => {
                if (ele.value === event.status) {
                    return true;
                }
                return false;
            });
            if (isMatched) {
                return event;
            }
            return false;
        });
        if (filterData.assetStatus.value[0] && filterData.assetStatus.value[0].label === "All") {
            resAction = events;
        } else if (filterData.assetStatus.value.length === 0) {
            resAction = events;
        }
        let resMeasurement = _.filter(events, event => {
            const isMatched = _.find(measurementFilterarray, meas => {
                if (meas.label === event.metaData.measurementType) {
                    return true;
                }
                return false;
            });
            if (isMatched) {
                return true;
            }
            return false;
        });
        if (
            filterData.measurementType.value[0] &&
            filterData.measurementType.value[0].label === "All"
        ) {
            resMeasurement = events;
        } else if (
            filterData.measurementType.value &&
            filterData.measurementType.value.length === 0
        ) {
            resMeasurement = events;
        }
        const res = resAction.filter(value => resMeasurement.includes(value));

        return res;
    }
    return events;
};
export default getAssetMeasurementFilteredEvents;
