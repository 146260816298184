import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Header";
import { startVehicle } from "../../store/actions/vehicle.action";
import { getMapData } from "../../store/actions/getMapData.action";
import Spinner from "../Spinner/index";
import "./style.scss";

const Layout = props => {
    useEffect(() => {
        props.startVehicle();
        // props.getMapData();

        return () => {};
    }, []);
    return (
        <div className="outer-most-div">
            <Header />
            {props.isLoading ? props.children : <Spinner location="main-app" />}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isLoading: state.vehicleData.isLoading,
        vehicleRefrences: state.vehicleData.vehicleRefrences
    };
};

const mapDispatchToProps = dispatch => ({
    startVehicle: () => dispatch(startVehicle()),
    getMapData: () => dispatch(getMapData())
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Layout)
);
