import { put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import actionTypes from "../constants";

export function* logout(action) {
    try {
        localStorage.clear();
        yield put(push("/login"));
    } catch (error) {
        console.log(error);
    }
}
export default [takeLatest(actionTypes.LOGOUT, logout)];
