import _ from "lodash";
import moment from "moment";
import getWheelsetReference from "./findReferences";
import getPointSideAndTypes from "./getPointListSide";
import { findPointProgress } from "../event/findProgress";
import { formPathName } from "../event/findSideAndStatus";
import findFunctionalLocation from "../wheelset/findFunctionalLocation";
import getPointsListStatus, { getAlarmSeverity } from "./pointsListStatus";
import alarmStatusCode from "../../constants";
import { getEventCaseIdForAlarms } from "../../reports/getEventCaseId";

const eventSchema = {
    metaData: {
        pathname: "",
        trainId: "",
        carriageId: "",
        wheelSetID: "",
        assetId: "",
        measurementType: "",
        side: "",
        assetType: ""
    },
    computedReport: {
        recommendedAction: "",
        symptoms: "",
        diagnosis: "",
        recommendation: ""
    },

    status: "",
    eventStatus: "",
    title: "",
    description: "",
    defectCategory: "",
    id: 12,
    caseNoPrefix: "",

    modified: "",
    created: ""
};

const getmodeledPoint = (point, vehiclesArray, wheelsets) => {
    const clonedEvent = _.cloneDeep(eventSchema);
    clonedEvent.title = "";
    clonedEvent.description = "";
    clonedEvent.defectCategory = "";
    clonedEvent.id = "";
    clonedEvent.caseNoPrefix = "";
    clonedEvent.modified = "";
    clonedEvent.created = null;
    clonedEvent.eventStatus = "";

    const references = getWheelsetReference(
        point.ParentID,
        vehiclesArray,
        wheelsets
    );

    const {
        wheelsetReference,
        carriageRef,
        vehicleRef,
        tractionRef
    } = references;

    const result = getPointSideAndTypes(point);
    const { side, measurementType, assetType } = result;
    const eventProgress = findPointProgress(point.Status);

    const metaData = {
        pathName: formPathName(vehicleRef, carriageRef, wheelsetReference),
        trainId: vehicleRef,
        carriageId: carriageRef,
        wheelsetId: point.ParentID,
        side,
        assetId: findFunctionalLocation(
            vehicleRef,
            vehiclesArray,
            side,
            assetType,
            point.ParentID
        ),
        measurementType,
        assetType,
        eventProgress
    };
    clonedEvent.metaData = metaData;
    clonedEvent.status = getPointsListStatus(point, metaData);
    clonedEvent.type = "Alarm";
    const computedReport = {
        recommendedAction: "",
        symptoms: "",
        diagnosis:
            clonedEvent.status === alarmStatusCode.dangerTemperature.color
                ? "Upper threshold configured was exceeded"
                : "Lower threshold configured was exceeded",
        classification:
            clonedEvent.status === alarmStatusCode.dangerTemperature.color
                ? 10
                : 7,
        recommendation: ""
    };
    clonedEvent.computedReport = computedReport;
    clonedEvent.traction = tractionRef;
    clonedEvent.feedBackSeverity = getAlarmSeverity(clonedEvent.Status);

    const obj = {
        date: moment().format("YYYYDDMM"),
        status: clonedEvent.status,
        measurementType,
        ID: point.ID
    };

    clonedEvent.eventCaseId = getEventCaseIdForAlarms(obj);
    return clonedEvent;
};

export default getmodeledPoint;
