export const normalisedVehicleDetails = vehicleDetails => {
    return {
        type: "STORE_VEHICLE_MODEL",
        payload: vehicleDetails
    };
};

export const eventDetails = events => {
    return {
        type: "STORE_EVENTS_MODEL",
        payload: events
    };
};

export const overallAssetStatus = eventStatus => {
    return {
        type: "STORE_OVERALL_ASSET_STATUS",
        payload: eventStatus
    };
};

export const overallTrainStatus = trainStatus => {
    return {
        type: "STORE_OVERALL_TRAIN_STATUS",
        payload: trainStatus
    };
};

export const overallVehicleCount = count => {
    return {
        type: "STORE_TOTAL_VEHICLE_COUNT",
        payload: count
    };
};
