import { cloneDeep } from "lodash";
import modelGearBox from "./gearBoxModel";
import modelAxleBoxes from "./axleBoxModel";
import eventLevelconstant from "./utils/constants";
import modelTractionMotor from "./tractionMotorModel";
import statusCountsForWheelSets, {
    getOverallStatus
} from "./utils/computeStatusCounts";
import computeEvents, { getPointList } from "./utils/computeEventsAndPointList";

const modelWheelSets = (wheelSets, events, pointsList) => {

    const modelledWheelSets = wheelSets.map(wheelSet => {
        let clonedWheelSet = cloneDeep(wheelSet);

        // adding events for wheelsets
        const wheelSetEvents = computeEvents(
            clonedWheelSet.Reference,
            events,
            eventLevelconstant.wheelsetId
        );


        clonedWheelSet.events = wheelSetEvents;

        // computing pointList for wheelSetLevel for axleBoxes
        const wheelSetPoints = getPointList(
            clonedWheelSet.Reference,
            pointsList
        );

        // modelling axleBoxes array
        const modelledAxleBoxes = modelAxleBoxes(
            clonedWheelSet.Axleboxes,
            wheelSetEvents,
            wheelSetPoints
        );
        delete clonedWheelSet.Axleboxes;
        clonedWheelSet.axleBoxes = modelledAxleBoxes;

        // modelling traction motor
        if (clonedWheelSet.TractionMotor) {
            const modelledTractionMotor = modelTractionMotor(
                clonedWheelSet.TractionMotor,
                wheelSetEvents,
                pointsList
            );
            delete clonedWheelSet.TractionMotor;
            clonedWheelSet.tractionMotor = modelledTractionMotor;
        }

        // modelling gearbox
        if (clonedWheelSet.GearBox) {
            const modelledGearBox = modelGearBox(
                clonedWheelSet.GearBox,
                wheelSetEvents,
                pointsList
            );
            delete clonedWheelSet.GearBox;
            clonedWheelSet.gearBox = modelledGearBox;
        }

        // updating the wheelset with status counts
        clonedWheelSet = statusCountsForWheelSets(clonedWheelSet);

        // updating wheelSet with overallStatus
        clonedWheelSet.overallStatus = getOverallStatus(wheelSetEvents);

        return clonedWheelSet;
    });

    return modelledWheelSets;
};
export default modelWheelSets;
