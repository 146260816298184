
import asyncAction from './asyncActionsTypes';
import syncAction from './syncActionsTypes';
import { createAsyncAction, createSyncAction } from '../../utils/helper'


const actionTypes = {
    ...createAsyncAction(asyncAction),
    ...createSyncAction(syncAction)
}


export default actionTypes;