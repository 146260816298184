import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import _ from "lodash";
import fileIcon from "../../../assets/images/fileIcon.png";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import documentIcon from "../../../assets/images/document.png";
import {
    saveComments,
    showCommentError
} from "../../../store/actions/modal.action";
import FeedbackDropdown from "../../FeedbackDropdown";
import { severityDropdown, errorTypes } from "../../../utils/constants";
import "./style.scss";
import ImageUpload from "../../ImageUpload";
import { getImage } from "../../../utils/reports/getEventCaseId";

const CommentsSection = props => {
    const [defectDropDownList, setDefectDropDown] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    useEffect(() => {
        const dropdownList = _.get(props, "defectDropDown", []).map(
            item => item.Category
        );
        setDefectDropDown(dropdownList);
    }, [props.defectDropDown]);

    useEffect(() => {
        const images = _.get(props, "imagesArr", []).map(item => {
            const previewObj = {};
            Object.assign(previewObj, {
                name: item.name,
                src: getImage(_.get(item, "type", ""), item)
            });
            return previewObj;
        });
        setPreviewImages(images);
    }, [props.imagesArr]);
    const handleChange = e => {
        e.preventDefault();
        props.saveComments(e.target.value);
    };

    return (
        <div className="lower-section">
            <div className="defect-dropdown-row">
                <div>
                    <div className="heading-div">Defect</div>
                    <div className="margin-heading">
                        <FeedbackDropdown
                            dropdownType="defect"
                            dropdownItems={defectDropDownList}
                            defaultValue={defectDropDownList[0]}
                        />
                    </div>
                </div>
                <div className="space">
                    <div className="heading-div">Position</div>
                    <div className="margin-heading">
                        {props.modalData.position}
                    </div>
                </div>

                <div className="space">
                    <div className="heading-div">Severity</div>
                    <div className="margin-heading">
                        <FeedbackDropdown
                            dropdownType="severity"
                            dropdownItems={severityDropdown}
                            defaultValue={_.get(
                                props,
                                "modalData.severity",
                                severityDropdown[0]
                            )}
                        />
                    </div>
                </div>
                <div className="space margin-upload-button">
                    <ImageUpload />
                </div>
            </div>

            <div className="add-comment-div">
                <div className="heading-div">Add comment</div>
                <div>
                    <Input
                        name="comment"
                        value={props.addCommentValue}
                        placeholder="Write your comment "
                        min={0}
                        max={100}
                        onChange={e => {
                            props.showCommentError(false);
                            handleChange(e);
                        }}
                    />
                </div>
                {props.commentError ? (
                    <h6 className="comment-error">
                        {errorTypes.COMMENT_ERROR}
                    </h6>
                ) : (
                        <div />
                    )}
            </div>

            <div className="display-flex">
                {previewImages.length > 0 && (
                    <p className="preview-heading"> Files to Upload</p>
                )}
                <div className="preview-images">
                    {previewImages.map(item => {
                        return (
                            <div className="preview">
                                <img
                                    className="preview-image"
                                    src={item.src}
                                    alt="img preview"
                                />
                                <p className="preview-image-name">
                                    {item.name}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

function mapStateTopProps(state) {
    return {
        modalData: state.feedbackModal.modalData,
        userData: state.user.userData,
        responseData: state.feedbackModal.responseData,
        imagesArr: state.feedbackModal.images,
        defectDropDown: state.vehicleModel.defectDropDown,
        showPreviousFeedback: state.feedbackModal.showPreviousFeedbacks,
        commentError: state.feedbackModal.showCommentError,
        addCommentValue: state.feedbackModal.responseData.addCommentValue
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveComments: value => dispatch(saveComments(value)),
        showCommentError: data => dispatch(showCommentError(data))
    };
}

export default connect(mapStateTopProps, mapDispatchToProps)(CommentsSection);
