import { fleetRoutes, opactityClasses } from "../constants";

const computeClass = (linkId, route, id) => {
    let className = "";
    if (route === fleetRoutes.trainRoute) {
        className = opactityClasses.noClass;
    } else if (
        route === fleetRoutes.vehicleRoute ||
        route === fleetRoutes.assetRoute ||
        route === fleetRoutes.tractionMotorRoute ||
        route === fleetRoutes.gearBoxRoute
    ) {
        const manipulatedId =
            id &&
            id
                .split(" ")
                .join("")
                .replace("/", "-");
        if (linkId === manipulatedId) {
            className = opactityClasses.noClass;
        } else {
            className = opactityClasses.disableClass;
        }
    }

    return className;
};

export default computeClass;
