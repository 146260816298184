import _ from "lodash";
import computeRouteName from "./routeName";
import { fleetRoutes } from "../constants";

const computeAssetCardData = (match, currentTrain) => {
    const assetObject = {};
    const { vehicleId, assetId, gearBoxId, tractionMotorId } = match.params;
    const route = computeRouteName(match);
    assetObject.axleId = assetId;
    const { carriages } = currentTrain;
    const carriage = _.findIndex(carriages, { VehicleNumber: vehicleId });

    if (carriage >= 0) {
        const wheelsetsArray = carriages[carriage].wheelSets;
        let axleBoxSpecific = {};

        wheelsetsArray.forEach(wheelset => {
            const axlebox = wheelset.axleBoxes;
            if (route === fleetRoutes.assetRoute) {
                const axle = _.findIndex(axlebox, { LocationName: assetId });
                if (axle !== -1) {
                    axleBoxSpecific = wheelset.axleBoxes[axle];
                    const axleBearing =
                        axleBoxSpecific && axleBoxSpecific.Bearings;
                    assetObject.brand = axleBearing[0].OEM;
                    assetObject.serialNumber = axleBearing[0].SerialNumber;
                    assetObject.designation = axleBearing[0].Designation;
                    assetObject.wheelDiameter = wheelset.WheelDiameter;
                    assetObject.wheelset = wheelset.Name;
                    assetObject.bogie = `Boogie Number ${wheelset.BogieNumber}`;
                }
            } else if (
                route === fleetRoutes.gearBoxRoute ||
                fleetRoutes.tractionMotorRoute
            ) {
                if (
                    (wheelset.gearBox &&
                        wheelset.gearBox.LocationName &&
                        wheelset.gearBox.LocationName.split(" ").join("") ===
                            gearBoxId) ||
                    (wheelset.tractionMotor &&
                        wheelset.tractionMotor.LocationName &&
                        wheelset.tractionMotor.LocationName.split(" ").join(
                            ""
                        ) === tractionMotorId)
                ) {
                    assetObject.wheelDiameter = wheelset.WheelDiameter;
                    assetObject.wheelset = wheelset.Name;
                    assetObject.bogie = `Boogie Number ${wheelset.BogieNumber}`;
                }
            }
        });
    }
    return assetObject;
};
export default computeAssetCardData;
