import React, { PureComponent } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import "./style.scss";

function recomputeDisableOfParent(data) {
    return data;
}

class DropdownTree extends PureComponent {
    onChange = (currentNode, selectedNodes) => {
        this.props.handleDataSelection(selectedNodes);
    };

    render() {
        return (
            <div className="dropdown-filter-container">
                <p className="filter-name">Fleet, Train & Vehicles</p>
                <DropdownTreeSelect data={this.props.data} onChange={this.onChange} />
            </div>
        );
    }
}

export default DropdownTree;
