import _ from "lodash";
import moment from "moment";

const categoryModel = {
    category: "",
    createdAt: "",
    value: "",
    trainId: "",
    uploadFiles: [],
    name: ""
};
const modelDocuments = (documentsArray = []) => {
    const modeledArray = _.map(documentsArray, item => {
        const categoryModelObj = _.cloneDeep(categoryModel);
        categoryModelObj.createdAt = moment(item.CreatedDate).format(
            "DD/MM/YYYY"
        );
        categoryModelObj.name = item.Name;
        categoryModelObj.category = item.Category;
        categoryModelObj.uploadFiles = item.UploadFiles;
        categoryModelObj.trainId = item.TrainId;
        categoryModelObj.value =
            item.Category === "Analysis" ? "Detailed Events" : item.Category;

        return categoryModelObj;
    });
    return modeledArray;
};

export default modelDocuments;
