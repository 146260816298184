import { get, cloneDeep, filter, min, max } from "lodash";
import getUIC from "../../utils/getUIC";
import getModelledCarriages from "./carriageModel";
import eventLevelconstant from "./utils/constants";
import { statusCounts, getOverallStatus } from "./utils/computeStatusCounts";
import computeEvents from "./utils/computeEventsAndPointList";
import computeWarnings from "./utils/computeWarningAndCounts";

// vehicle model
const modelVehicles = (vehicles, wheelsets, pointsList, finalModledEvents) => {
    const vehicleDetails = Object.values(get(vehicles, "entities.vehicle", {}));
    const wheelSets = Object.values(get(wheelsets, "entities.wheelsets", {}));
    const modelledVehicles = vehicleDetails.map(vehicle => {
        let clonedVehicle = cloneDeep(vehicle);
        delete clonedVehicle.Wheelsets;

        // computing UIC array from UIC code
        const UIC = getUIC(clonedVehicle.SetUIC);

        // computed events for each vehicle
        const events = computeEvents(
            clonedVehicle.Designation,
            finalModledEvents,
            eventLevelconstant.trainId
        );
        clonedVehicle.events = events;

        // computing the most recent and oldest event
        const eventsCreationDates = events.map(event => {
            const num =
                event.created !== null ? new Date(event.created).getTime() : 0;
            return num;
        });
        // to find the most recent event
        const mostRecentEvent = max(eventsCreationDates);

        // to find the most oldest event
        const mostOldestEvents = filter(eventsCreationDates, event => {
            return event !== 0;
        });
        const mostOldestEvent = min(mostOldestEvents);

        clonedVehicle.mostRecentEvent = mostRecentEvent;
        clonedVehicle.mostOldestEvent = mostOldestEvent;

        // modelled carriages for each vehicle
        const carriages = getModelledCarriages(
            clonedVehicle.Carriages,
            wheelSets,
            events,
            pointsList,
            UIC
        );
        delete clonedVehicle.Carriages;
        clonedVehicle.carriages = carriages;

        // adding warnings to vehicles
        clonedVehicle = computeWarnings(events, clonedVehicle);

        // updating the train with status counts
        clonedVehicle = statusCounts(clonedVehicle);

        // updating train with overallStatus
        clonedVehicle.overallStatus = getOverallStatus(events);

        return clonedVehicle;
    });
    return modelledVehicles;
};
export default modelVehicles;
