import React, { useEffect, useState } from "react";
import _ from "lodash";
import propTypes from "prop-types";
import { connect } from "react-redux";
import fleetFilters from "./fleetData";
import FilterItems from "./FilterItems";
import DatePickerFilter from "./DatePickerFilter";
import ToggleButton from "./ToggleButton";
import DropDownTree from "./DropdownTree";
import {
    saveFilterData,
    setToggleFlag,
    saveTimeFilterData,
    saveCheckedData,
    resetFilterData as resetFilterDataAction
} from "../../store/actions/filterData.action";
import "./style.scss";

function disableFunction(obj, currentDepth, maxDepth) {
    if (obj && currentDepth <= maxDepth) {
        obj.disabled = true;
    }
    if (obj && obj.children && obj.children.length) {
        obj.children.forEach(childObj => {
            disableFunction(childObj, currentDepth + 1, maxDepth);
        });
    } else {
        obj.disabled = false;
    }
    return obj;
}

function recomputeDisableOfParent(data) {
    const clonedData = _.cloneDeep(data);
    const resultArray = clonedData.map(dataObj => {
        return disableFunction(dataObj, 1, 2);
    });
    return resultArray;
}

const FleetFilter = props => {
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        if (props.showFilterBtn) {
            props.handleToggle(true);
        }
        return () => {
            props.handleResetFilterValueChange();
            // props.fleetFilterList.forEach(item => {
            //     const filterData = {
            //         key: item.filterName,
            //         value: null
            //     };
            //     props.handleFilterValueChange(filterData);
            // });
            // props.handleCheckData([]);
            // const startingDate = { startDate: "" };
            // props.handleDateChange(startingDate);
            // const endDate = { endDate: "" };
            // props.handleDateChange(endDate);
        };
    }, []);

    useEffect(() => {
        if (props.showTrainName) {
            const resultArray = recomputeDisableOfParent(props.data);
            setTreeData(resultArray);
        }
    }, [props.showTrainName]);
    const startDateChange = date => {
        const timeFilterData = { startDate: date };
        props.handleDateChange(timeFilterData);
    };

    const endDateChange = date => {
        const timeFilterData = { endDate: date };
        props.handleDateChange(timeFilterData);
    };
    return (
        <div className="filter-contain">
            {props.toggleFlag ? (
                <div>
                    <div className="filterList">
                        <DropDownTree
                            data={
                                props.showTrainName === true
                                    ? treeData
                                    : props.data
                            }
                            handleDataSelection={props.handleCheckData}
                            disableParent
                        />

                        {props.fleetFilterList.map(item => (
                            <div key={item.key} className="filter">
                                <FilterItems
                                    item={item}
                                    filterData={props.filterData}
                                    onDropdownValueChange={
                                        props.handleFilterValueChange
                                    }
                                />
                            </div>
                        ))}

                        {props.showDatePicker ? (
                            <div className="time-filter">
                                <p className="filter-name">Time Period</p>
                                <div className="lower-div">
                                    <DatePickerFilter
                                        date={new Date()}
                                        onDateChange={startDateChange}
                                    />
                                    <p className="middle-area">To</p>
                                    <DatePickerFilter
                                        showDatePicker={props.showDatePicker}
                                        date={
                                            props.timeFilterData.startDate
                                                ? props.timeFilterData.startDate
                                                : new Date()
                                        }
                                        onDateChange={endDateChange}
                                        minDate
                                    />
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            ) : null}
            <ToggleButton
                onToggle={props.handleToggle}
                toggleFlag={props.toggleFlag}
            />
        </div>
    );
};

FleetFilter.defaultProps = {
    fleetFilterList: fleetFilters,
    toggleFlag: false
};
FleetFilter.propTypes = {
    fleetFilterList: propTypes.array,
    toggleFlag: propTypes.bool
};

function mapStateToProps(state) {
    return {
        toggleFlag: state.fleetData.toggleFlag,
        filterData: state.fleetData.filterData,
        timeFilterData: state.fleetData.timeFilterData,
        data: state.historyData.historyVehicleFilterTree
    };
}

function mapDispatchProps(dispatch) {
    return {
        handleFilterValueChange: filterObj => {
            dispatch(saveFilterData(filterObj));
        },
        handleResetFilterValueChange: () => {
            dispatch(resetFilterDataAction());
        },
        handleToggle: toggleSwitch => {
            dispatch(setToggleFlag(toggleSwitch));
        },
        handleDateChange: dateObj => {
            dispatch(saveTimeFilterData(dateObj));
        },
        handleCheckData: checkArray => {
            dispatch(saveCheckedData(checkArray));
        }
    };
}

export default connect(mapStateToProps, mapDispatchProps)(FleetFilter);
