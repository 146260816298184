import { cloneDeep, filter, includes } from "lodash";
import { measurementTypes, assetTypes } from "./constants";

// function to compute status counts from classification
const getColorCountsFromClassification = (
    classification,
    amberIterator,
    redIterator
) => {
    let amberCount = amberIterator;
    let redCount = redIterator;
    if (classification >= 8 && classification <= 10) {
        redCount += 1;
    } else if (classification >= 4 && classification <= 7) {
        amberCount += 1;
    }
    return { amberCount, redCount };
};

// function to equalise overall status for one asset
const equalizeStatus = (amberCount, redCount) => {
    let amberStatus = amberCount;
    let redStatus = redCount;
    if (amberStatus !== 0 && redStatus !== 0) {
        amberStatus = 0;
        redStatus = 1;
    }
    if (amberStatus > 1) {
        amberStatus = 1;
    }
    if (redStatus > 1) {
        redStatus = 1;
    }

    return { amberStatus, redStatus };
};

// function to compute status counts for wheel, bearing, tractionMotor and gearBox
export const computeRedAmberEventCounts = events => {
    let amberTractionMotorCount = 0;
    let redTractionMotorCount = 0;
    let amberGearboxCount = 0;
    let redGearboxCount = 0;
    let amberWheelCount = 0;
    let redWheelCount = 0;
    let redBearingCount = 0;
    let amberBearingCount = 0;
    events.forEach(event => {
        const { assetType } = event.metaData;
        const { classification } =
            event.reports
                ? event.reports[0].assesments[0]
                : event.computedReport;

        switch (assetType) {
            case assetTypes.bearing:
                ({
                    amberCount: amberBearingCount,
                    redCount: redBearingCount
                } = getColorCountsFromClassification(
                    classification,
                    amberBearingCount,
                    redBearingCount
                ));

                break;
            case assetTypes.wheel:
                ({
                    amberCount: amberWheelCount,
                    redCount: redWheelCount
                } = getColorCountsFromClassification(
                    classification,
                    amberWheelCount,
                    redWheelCount
                ));
                break;
            case assetTypes.tractionMotor:
                ({
                    amberCount: amberTractionMotorCount,
                    redCount: redTractionMotorCount
                } = getColorCountsFromClassification(
                    classification,
                    amberTractionMotorCount,
                    redTractionMotorCount
                ));
                break;
            case assetTypes.gearBox:
                ({
                    amberCount: amberGearboxCount,
                    redCount: redGearboxCount
                } = getColorCountsFromClassification(
                    classification,
                    amberGearboxCount,
                    redGearboxCount
                ));
                break;
            default:
        }
    });
    ({
        amberStatus: amberWheelCount,
        redStatus: redWheelCount
    } = equalizeStatus(amberWheelCount, redWheelCount));
    ({
        amberStatus: amberBearingCount,
        redStatus: redBearingCount
    } = equalizeStatus(amberBearingCount, redBearingCount));
    ({
        amberStatus: amberTractionMotorCount,
        redStatus: redTractionMotorCount
    } = equalizeStatus(amberTractionMotorCount, redTractionMotorCount));
    ({
        amberStatus: amberGearboxCount,
        redStatus: redGearboxCount
    } = equalizeStatus(amberGearboxCount, redGearboxCount));
    return {
        amberTractionMotorCount,
        redTractionMotorCount,
        amberGearboxCount,
        redGearboxCount,
        amberWheelCount,
        redWheelCount,
        redBearingCount,
        amberBearingCount
    };
};

export const computeCountsAxleBoxes = (events, pointsList, axleBox) => {
    let sensorCount = 0;
    let amberWheelCount = 0;
    let redWheelCount = 0;
    let redBearingCount = 0;
    let amberBearingCount = 0;
    let overallRedCount = 0;
    let overallAmberCount = 0;

    // computing amber sensor counts from pointList
    pointsList.forEach(point => {
        const status = point.Status;
        if (includes(status, 12)) {
            sensorCount = 1;
        }
    });

    // computing wheel and bearing counts from events
    const countsObj = computeRedAmberEventCounts(events);
    ({
        amberWheelCount,
        redWheelCount,
        amberBearingCount,
        redBearingCount
    } = countsObj);

    // cloned pointList
    const clonedPointsList = cloneDeep(pointsList);

    // computing temperature point
    const temperaturePointList = filter(clonedPointsList, point => {
        return point.Name.split(" ")[0].substr(2, 3) === "VT";
    });

    // computing bearing counts from temperature point
    temperaturePointList.forEach(point => {
        const status = point.Status;
        if (includes(status, 9) || includes(status, 10)) {
            redBearingCount += 1;
        } else if (includes(status, 7) || includes(status, 8)) {
            amberBearingCount += 1;
        }
    });

    // equalizing the events after addition of pointsList
    ({
        amberStatus: amberBearingCount,
        redStatus: redBearingCount
    } = equalizeStatus(amberBearingCount, redBearingCount));

    // computing overall counts
    overallRedCount = redBearingCount + redWheelCount;
    overallAmberCount = amberBearingCount + amberWheelCount + sensorCount;


    // adding counts to axleBoxes
    let clonedAxleBox = cloneDeep(axleBox);
    clonedAxleBox = {
        ...clonedAxleBox,
        sensorCount,
        amberWheelCount,
        redWheelCount,
        redBearingCount,
        amberBearingCount,
        overallRedCount,
        overallAmberCount
    };

    console.debug("clonedAxleBox", clonedAxleBox);
    return clonedAxleBox;
};

const computeWarnings = (events, unit) => {
    const clonedUnit = cloneDeep(unit);
    let sensorWarning = false;
    let temperatureWarning = false;
    let vibrationWarning = false;
    events.forEach(event => {
        if (event.metaData.measurementType === measurementTypes.vibration) {
            vibrationWarning = true;
        }
        if (event.metaData.measurementType === measurementTypes.temperature) {
            temperatureWarning = true;
        }
        if (event.metaData.measurementType === measurementTypes.sensor) {
            sensorWarning = true;
        }
    });
    clonedUnit.vibrationWarning = vibrationWarning;
    clonedUnit.temperatureWarning = temperatureWarning;
    clonedUnit.sensorWarning = sensorWarning;
    return clonedUnit;
};
export default computeWarnings;
